<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "bait_select" | i18n }}</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item @click="select(item)" :item="item" v-for="item in items" :key="item" v-tappable modifier="chevron">
                <ListItemIcon icon="link"></ListItemIcon>
                <div class="center">{{ item }}</div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
export default {
    props: ["select"],
    data() {
        const existing = this.$store.state.catch.entities
            .map(catcht => catcht.baits)
            .filter(baits => baits && baits.length)
            .flat()
            .map(bait => bait.name)
            .uniq();
        return {
            items: [existing, this.$store.state.lib.baits]
                .flat()
                .uniq()
                .filter(Boolean)
                .sort()
        };
    }
};
</script>