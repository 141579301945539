<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "spot_select" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="add"><v-ons-icon icon="plus"></v-ons-icon></v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <v-ons-list>
            <LargeList :items="entities" v-slot="chunk" item-height="62">
                <SpotItem @click="select(entity)" :entity="entity" v-tappable v-for="entity in chunk.items" :key="entity.id"></SpotItem>
            </LargeList>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
import spotManager from "../bl/spot";
export default {
    props: ["select"],
    computed: {
        entities() {
            return this.$store.getters["spot/nearest"](this.location, false).filter(spot => spot.spottype == "default");
        },
        location() {
            return this.$store.state.center;
        }
    },
    methods: {
        add() {
            spotManager.create("default", this.location).then(spot => this.$nav.push("Spot", { spot, center: spot, next: this.select }));
        }
    }
};
</script>