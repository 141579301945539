<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">Logs</div>
        </v-ons-toolbar>
        <div class="content">
            <v-ons-list>
                <v-ons-list-item v-for="(entry, index) in entries" :key="index">
                    <div class="center">
                        <h3>{{ entry.event }}</h3>
                        <div>{{ entry }}</div>
                    </div>
                </v-ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    props: ["entries"]
};
</script>