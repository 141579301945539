<template>
    <div>
        <div v-if="!useable" class="premium">
            <div class="container-xs">
                <v-ons-card>
                    <div class="title mb-3">
                        <v-ons-icon icon="star" class="text-primary mr-1"></v-ons-icon>
                        {{ "premium_version" | i18n }}
                    </div>
                    <div class="content">
                        <div>{{ ("premium_text_" + (feature || "")) | i18n }}</div>
                        <div class="text-center mt-3">
                            <v-ons-button @click="premium">{{ "premium_infos" | i18n }}</v-ons-button>
                        </div>
                    </div>
                </v-ons-card>
            </div>
        </div>
    </div>
</template>

<script>
import premiumManager from "../bl/premium";
export default {
    props: ["feature", "model"],
    data() {
        return { useable: true };
    },
    mounted() {
        this.$event.on("premium", this.reload);
        this.reload();
    },
    beforeDestroy() {
        this.$event.off("premium", this.reload);
        if (!this.useable) {
            this.$el.closest(".content").style.overflow = "";
        }
    },
    methods: {
        reload() {
            return premiumManager.useable(this.feature, this.model).then(
                useable => this.setUseable(useable),
                err => this.setUseable(false)
            );
        },
        setUseable(useable) {
            this.useable = useable;
            this.$el.closest(".content").style.overflow = useable ? "" : "hidden";
        },
        premium() {
            this.$nav.push("Premium", { feature: this.feature });
        }
    }
};
</script>
