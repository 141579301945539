<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "photo_show" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="toggleActions"><v-ons-icon icon="ellipsis-h"></v-ons-icon></v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <div ref="content" class="content">
            <div ref="area" :style="{ zoom }" class="layout d-flex align-items-center justify-content-center image-area">
                <img ref="image" />
            </div>
        </div>
        <v-ons-action-sheet :visible.sync="actions" cancelable="true">
            <!--
            <v-ons-action-sheet-button @click="share">{{ "share" | i18n }}</v-ons-action-sheet-button>
            <v-ons-action-sheet-button @click="download">{{ "download" | i18n }}</v-ons-action-sheet-button> -->
            <v-ons-action-sheet-button @click="remove" modifier="destructive">{{ "delete" | i18n }}</v-ons-action-sheet-button>
            <v-ons-action-sheet-button @click="toggleActions">{{ "cancel" | i18n }}</v-ons-action-sheet-button>
        </v-ons-action-sheet>
    </v-ons-page>
</template>

<script>
import photo from "../bl/photo";
export default {
    props: ["image", "remove"],
    data() {
        return { zoom: 1.0, actions: false, sharing: false };
    },
    mounted() {
        // Create gesture detector
        this.gesture = this.$ons.GestureDetector(this.$refs.content);
        this.gesture.on("pinchin pinchout", e => {
            this.zoom = this.baseZoom * e.gesture.scale;
        });

        // Init zoom
        this.$refs.image.onload = () => {
            this.zoom = Math.min(this.$refs.area.clientWidth / this.$refs.image.width, this.$refs.area.clientHeight / this.$refs.image.height);
            this.baseZoom = this.zoom;
        };

        // Load image
        this.$refs.image.src = photo.url(this.image);
    },
    beforeDestroy() {
        this.gesture.dispose();
    },
    methods: {
        toggleActions() {
            this.actions = !this.actions;
        },
        share(platform) {
            this.toggleActions();
            if (window.plugins && window.plugins.socialsharing && window.plugins.socialsharing.shareWithOptions) {
                window.plugins.socialsharing.shareWithOptions({ files: [photo.url(this.image)] }, result => {
                    if (result && result.completed) {
                        this.$ons.notification.toast(this.$i18n.get("successfully_shared"), { timeout: 1000 });
                    }
                });
            }
        },
        download() {
            this.toggleActions();
            if (window.imageSaver && window.imageSaver.saveBase64Image) {
                const data = imgToBase64(this.$refs.image);
                const params = { data, prefix: "fishy_", format: "JPG", quality: 80, mediaScanner: true };
                window.imageSaver.saveBase64Image(params, path => this.$ons.notification.toast(this.$i18n.get("successfully_saved"), { timeout: 1000 }));
            } else {
                window.open(this.image, "_blank");
            }
        }
    }
};

function imgToBase64(img) {
    const canvas = document.createElement("canvas");
    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL("image/png").replace(/^data:image.+;base64,/, "");
}
</script>