<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><MenuButton></MenuButton></div>
            <div class="center">{{ "charts" | i18n }}</div>
        </v-ons-toolbar>
        <div class="content">
            <v-ons-card @click="select(report)" v-for="report in reports" :key="report.name" class="d-flex align-items-center">
                <div class="flex-item pr-3">
                    <!--
                    <v-ons-icon fixed-width :icon="report.premium ? 'star' : (report.icon || 'chart-bar')" :class="report.premium ? 'text-primary': 'text-muted'" class="list-item__icon"></v-ons-icon>-->
                    <v-ons-icon fixed-width icon="chart-bar" class="text-muted list-item__icon"></v-ons-icon>
                </div>
                <div class="flex-body">
                    <div class="card__title">{{ report.name | i18n }}</div>
                    <div class="card__content text-muted">{{ report.description | i18n }}</div>
                </div>
            </v-ons-card>
        </div>
    </v-ons-page>
</template>

<script>
import { clone } from "../util";
import reports from "../reports/*.vue";
export default {
    data() {
        return {
            reports: Object.keys(reports)
                .map(component => {
                    return {
                        component: "Reports" + component,
                        ...reports[component].default.report
                    };
                })
                .filter(report => report.name)
                .sort((a, b) => (a.index > b.index ? 1 : -1))
        };
    },
    methods: {
        select(report) {
            this.$nav.push("Chart", { report });
        }
    }
};
</script>