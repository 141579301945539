<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "settings" | i18n }}</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item v-if="loggedin" @click="profile" v-tappable modifier="chevron">{{ "profile" | i18n }}</v-ons-list-item>
            <v-ons-list-item v-tappable @click="language">
                <div class="center">{{ "language" | i18n }}</div>
                <div class="right">
                    <LocaleFlag :locale="$store.state.ui.locale" width="16"></LocaleFlag>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <!-- THEME
        <v-ons-list class="mt-3">
            <v-ons-list-header>{{ "theme" | i18n }}</v-ons-list-header>
            <v-ons-list-item v-tappable>
                <label class="left"><v-ons-radio input-id="theme-dark" value="dark" v-model="theme"></v-ons-radio></label>
                <label for="theme-dark" class="center">{{ "mode_dark" | i18n }}</label>
            </v-ons-list-item>
            <v-ons-list-item v-tappable>
                <label class="left"><v-ons-radio input-id="theme-light" value="light" v-model="theme"></v-ons-radio></label>
                <label for="theme-light" class="center">{{ "mode_light" | i18n }}</label>
            </v-ons-list-item>
        </v-ons-list>-->

        <v-ons-list class="mt-3">
            <v-ons-list-header>{{ "mapmode" | i18n }}</v-ons-list-header>
            <v-ons-list-item v-tappable>
                <label class="left"><v-ons-radio input-id="mapmode-dark" value="dark" v-model="mapmode"></v-ons-radio></label>
                <label for="mapmode-dark" class="center">{{ "mode_dark" | i18n }}</label>
            </v-ons-list-item>
            <v-ons-list-item v-tappable>
                <label class="left"><v-ons-radio input-id="mapmode-light" value="light" v-model="mapmode"></v-ons-radio></label>
                <label for="mapmode-light" class="center">{{ "mode_light" | i18n }}</label>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list class="mt-3">
            <v-ons-list-header>{{ "som" | i18n }}</v-ons-list-header>
            <v-ons-list-item v-tappable>
                <label class="left"><v-ons-radio input-id="som-metric" value="metric" v-model="som"></v-ons-radio></label>
                <label for="som-metric" class="center">{{ "som_metric" | i18n }}</label>
            </v-ons-list-item>
            <v-ons-list-item v-tappable>
                <label class="left"><v-ons-radio input-id="som-imperial" value="imperial" v-model="som"></v-ons-radio></label>
                <label for="som-imperial" class="center">{{ "som_imperial" | i18n }}</label>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-if="loggedin" class="mt-3">
            <v-ons-list-item v-tappable @click="logout">{{ "logout" | i18n }}</v-ons-list-item>
        </v-ons-list>

        <Version class="py-3 text-muted text-center"></Version>
    </v-ons-page>
</template>

<script>
import userManager from "../bl/user";
export default {
    data() {
        return {
            som: "" + (this.$store.state.ui.som || "metric"),
            theme: "" + (this.$store.state.ui.theme || "dark"),
            mapmode: "" + (this.$store.state.ui.mapmode || "dark")
        };
    },
    computed: {
        loggedin() {
            return userManager.isLoggedIn();
        }
    },
    methods: {
        profile() {
            this.$nav.push("Profile");
        },
        logout() {
            userManager.logout();
            this.$nav.replace("Login");
        },
        language() {
            this.$nav.push("Language");
        }
    },
    watch: {
        theme(mode) {
            this.$store.dispatch("ui/theme", mode);
            document.querySelector("#theme").setAttribute("href", `theme-${mode}.css`);
        },
        mapmode(mode) {
            this.$store.dispatch("ui/mapmode", mode);
        },
        som(value) {
            this.$store.dispatch("ui/som", value);
        }
    }
};
</script>