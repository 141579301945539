// @ts-check
import config from "../../config/system";

// Debug
export const debug = window.location.hostname == "localhost";

// API base url
let baseUrl = "";
const el = document.querySelector("#appjs");
if (el && el.getAttribute("src")) {
    baseUrl = new URL(el.getAttribute("src")).origin;
}

export const appBaseUrl = baseUrl;

// Fishy map tiles
export const mapmodeUrls = { dark: config.mapUrl };
export const maxZoom = 20;

// Date
export const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

// Google storage base
export const storageUrl = config.storageUrl;
