<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{'compass' | i18n}}</div>
        </v-ons-toolbar>
        <div class="content d-flex align-items-center justify-content-center">
            <div class="compass-container">
                <i class="fa fa-fw fa-caret-up text-danger compass-north"></i>
                <div class="compass-anchor">
                    <Picture ref="compass" src="img/compass.svg" class="compass"></Picture>
                    <div class="compass-spots text-primary">
                        <div :style="spotStyle(spot)" v-for="spot in compassSpots" :key="spot.id" class="compass-spot"></div>
                    </div>
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
// Constants
const MAX_LEN = 130.0;
const SCALE_FACTOR = 200000.0;

// Helper
function orientation(e) {
    rotateCompass(e.alpha);
}

// Helper
function rotateCompass(angle) {
    const anchor = document.querySelector(".compass-anchor");
    if (anchor) {
        anchor.style.transform = `rotate(${angle}deg)`;
    }
}

export default {
    data() {
        return { angle: 0 };
    },
    mounted() {
        let compass;
        if (window.cordova && window.cordova.plugins.magnetometer) {
            const list = Array(20);
            this.watchId = cordova.plugins.magnetometer.watchReadings(reading => {
                // Init
                const v = { x: parseFloat(reading.x), y: parseFloat(reading.y), z: parseFloat(reading.z) };

                // Calculate angle
                this.angle = -(Math.round((Math.atan2(v.y, v.x) * 180.0) / Math.PI) - 90.0);

                // Calc cumulate angle
                list.shift();
                list.push(this.angle);
                const angle = list.reduce((result, v) => result + v, 0) / list.length;

                // Update compass
                rotateCompass(angle);
            });
        } else {
            window.addEventListener("deviceorientation", orientation, true);
        }
    },
    beforeDestroy() {
        if (this.watchId) {
            cordova.plugins.magnetometer.stop([this.watchId]);
        } else {
            window.removeEventListener("deviceorientation", orientation);
        }
    },
    computed: {
        compassSpots() {
            const location = this.$store.state.location;
            return this.$store.getters["spot/nearest"](location).map(spot => {
                const vector = {
                    x: SCALE_FACTOR * (spot.lng - location.lng),
                    y: -SCALE_FACTOR * (spot.lat - location.lat)
                };

                const len = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
                if (len > MAX_LEN) {
                    vector.x *= MAX_LEN / len;
                    vector.y *= MAX_LEN / len;
                }

                return {
                    vector,
                    id: spot.id,
                    name: spot.name,
                    type: spot.type,
                    dist: spot.metadata.dist
                };
            });
        }
    },
    methods: {
        spotStyle(spot) {
            return {
                transform: `translate(${spot.vector.x}px, ${spot.vector.y}px)`
            };
        }
    }
};
</script>

<style>
.compass-container {
    overflow: hidden;
    position: relative;
    width: 320px;
    height: 320px;
}
.compass-anchor {
    height: 100%;
}
.compass {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 1000px;
    box-sizing: border-box;
    width: 320px;
    height: 320px;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -50%;
    margin-top: -50%;
}
.compass-spots {
    top: 50%;
    left: 50%;
    position: absolute;
}
.compass-spot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-top: -5px;
    margin-left: -5px;
    background: currentColor;
}
.compass-north {
    z-index: 1;
    display: block;
    position: absolute;
    left: 50%;
    font-size: 32px;
    margin-left: -20px;
}
</style>
