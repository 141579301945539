function dataUriToBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

export default function() {
    return new Promise((resolve, reject) => {
        const container = document.createElement("div");
        container.classList.add("browser-photo");
        document.body.append(container);

        const video = document.createElement("video");
        container.append(video);

        const containerButtons = document.createElement("div");
        containerButtons.classList.add("browser-photo-buttons");
        container.append(containerButtons);

        const buttonSnap = document.createElement("button");
        buttonSnap.classList.add("browser-photo-snap");
        containerButtons.append(buttonSnap);

        const buttonCancel = document.createElement("button");
        buttonCancel.innerText = "×";
        buttonCancel.classList.add("browser-photo-cancel");
        containerButtons.append(buttonCancel);

        buttonSnap.addEventListener("click", e => {
            const canvas = document.createElement("canvas");
            canvas.width = video.offsetWidth;
            canvas.height = video.offsetHeight;

            canvas.getContext("2d").drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);

            const dataUrl = canvas.toDataURL();

            container.remove();

            resolve(dataUriToBlob(dataUrl));
        });

        buttonCancel.addEventListener("click", () => {
            container.remove();
            reject();
        });

        window.navigator.getUserMedia(
            { video: true },
            stream => {
                video.srcObject = stream;
                video.play();
            },
            err => {
                container.remove();
                reject(err);
            }
        );
    });
}
