<template>
    <Map ref="map" v-if="ready" :center="center" :zoom="zoom" :bounds.sync="bounds" @update:zoom="zoomUpdated" @update:center="centerUpdated" @click="mapClick" :class="mapmode">
        <MapDistances :points="points"></MapDistances>
        <MapMarker @click="click(marker)" :coords="marker" v-for="marker in markers" :key="marker.id">
            <i :class="spotIcon(marker)" class="fa fa-fw fa-2x marker-icon"></i>
            <div v-if="namesVisible" class="caption">
                <span>{{ marker.name }}</span>
            </div>
            <span class="badge">{{ catchesBySpot(marker) }}</span>
        </MapMarker>
        <MapMarker :coords="position" anchor="center">
            <i :style="centerStyle" :class="centerClass" class="fa fa-fw fa-2x text-secondary"></i>
        </MapMarker>
        <MapMarker v-if="spot" :coords.sync="spot" draggable style="z-index:1;">
            <i :class="spotIcon(spot)" class="fa fa-fw fa-2x marker-icon marker-icon-primary"></i>
            <div v-if="namesVisible" class="caption">
                <span>{{ spot.name }}</span>
            </div>
            <span class="badge">{{ catchesBySpot(spot) }}</span>
        </MapMarker>
        <svg class="crosshair" version="1.1" width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <line x1="10" y1="0" x2="10" y2="20" stroke="#000" stroke-width="3px"></line>
            <line x1="0" y1="10" x2="20" y2="10" stroke="#000" stroke-width="3px"></line>
            <line x1="10" y1="1" x2="10" y2="19" stroke="#fff"></line>
            <line x1="1" y1="10" x2="19" y2="10" stroke="#fff"></line>
        </svg>
    </Map>
</template>

<script>
import { dist } from "../util";
import spotManager from "../bl/spot";
export default {
    props: ["spot", "zoom", "angle"],
    data() {
        return { bounds: {}, center: {} };
    },
    mounted() {
        if (this.spot) {
            this.center = { lng: this.spot.lng, lat: this.spot.lat };
        } else {
            this.center = { ...this.$store.state.location };
        }
    },
    methods: {
        spotIcon(spot) {
            const baseclass = spotManager.icon(spot);
            return [baseclass, !this.spot || this.spot.id == spot.id ? "marker-icon-primary" : "marker-icon-muted"];
        },
        click(spot) {
            this.$emit("spot:click", spot);
        },
        mapClick(e) {
            if (this.spot) {
                this.spot.lng = e.latlng.lng;
                this.spot.lat = e.latlng.lat;
                this.$emit("spot:update", this.spot);
            }
        },
        zoomUpdated(value) {
            this.$emit("update:zoom", value);
        },
        centerUpdated(value) {
            this.$emit("update:center", value);
        },
        home() {
            this.go(this.$store.state.location);
        },
        go(location, zoom = null, options = null) {
            this.$refs.map.goTo(location, zoom, options);
        },
        catchesBySpot(spot) {
            return this.$store.getters["catch/byspot"](spot).length || "";
        }
    },
    computed: {
        markers() {
            return this.$store.getters["spot/contained"](this.bounds)
                .filter(spot => !this.spot || this.spot.id != spot.id)
                .filter(spot => (this.zoom < 13 ? spot.spottype == "base" : true));
        },
        points() {
            if (this.zoom < 14) return [];
            const all = [this.markers, this.spot].filter(Boolean).flat();
            const bases = all.filter(spot => spot.spottype == "base");
            const spots = all.filter(spot => spot.spottype == "default");
            return bases.map(base => spots.map(spot => dist(spot, base) < 600.0 && [base, spot]).filter(Boolean)).flat();
        },
        centerClass() {
            return this.angle !== undefined ? "fa-location-arrow" : "fa-crosshairs";
        },
        centerStyle() {
            if (this.angle !== undefined) {
                return { transform: `rotate(${-(45 + this.angle)}deg)`, transformOrigin: "center" };
            }
        },
        ready() {
            return Number.isFinite(this.center && this.center.lng) && Number.isFinite(this.position && this.position.lng);
        },
        position() {
            return this.$store.state.location;
        },
        namesVisible() {
            return this.zoom > 16;
        },
        mapmode() {
            return "map-" + (this.$store.state.ui.mapmode || "dark");
        }
    }
};
</script>

<style scoped>
svg.crosshair {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    z-index: 1000;
}
</style>