export default {
    namespaced: true,
    state: {
        lng: null,
        lat: null
    },
    actions: {
        update: ({ commit }, position) => {
            commit("CENTER", position);
        }
    },
    mutations: {
        CENTER(state, position) {
            state.lng = position.lng;
            state.lat = position.lat;
        }
    }
};
