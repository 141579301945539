module.exports = {
    baseUrl: "https://fishyapp-prod.firebaseapp.com",
    mapUrl: "https://map-1632c.kxcdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png",
    storageUrl: "https://storage.googleapis.com",
    firebaseConfig: {
        apiKey: "AIzaSyBtNTzkV_SoWeRAWyNxGGTteatb4Bjnf1Q",
        authDomain: "fishyapp-prod.firebaseapp.com",
        databaseURL: "https://fishyapp-prod.firebaseio.com",
        projectId: "fishyapp-prod",
        storageBucket: "fishyapp-prod.appspot.com",
        messagingSenderId: "793288470060",
        appId: "1:793288470060:web:c5338f6bfd4f5fc01cab89",
        measurementId: "G-YGBSH5FR6K"
    }
};
