import i18n from "../bl/i18n";

export default {
    install(Vue) {
        // I18n filter
        Vue.filter("i18n", function (key, ...args) {
            return i18n.get(key, args);
        });

        // Inject service
        Vue.prototype.$i18n = i18n;
    },
};
