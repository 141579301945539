<template>
    <div>
        <v-ons-speed-dial position="bottom right" direction="up" modifier="titled">
            <v-ons-speed-dial-item @click="item.action()" v-for="item in actions" :key="item.name">
                <v-ons-icon :icon="item.icon"></v-ons-icon>
                <div class="title">{{ item.name | i18n }}</div>
            </v-ons-speed-dial-item>

            <v-ons-fab><v-ons-icon icon="plus"></v-ons-icon></v-ons-fab>
        </v-ons-speed-dial>

        <v-ons-modal :visible="locating" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
            <p>{{ "locating" | i18n }}</p>
        </v-ons-modal>
    </div>
</template>

<script>
import spotManager from "../bl/spot";
import catchManager from "../bl/catch";
export default {
    props: ["zoom"],
    data() {
        return {
            locating: false,
            actions: [
                { name: "catch", action: this.addCatch, icon: "fish" },
                { name: "spot", action: this.addSpot, icon: "map-marker" },
                { name: "base", action: this.addSpotBase, icon: "fire-alt" }
            ]
        };
    },
    methods: {
        addSpotBase() {
            this.addSpot("base");
        },
        addSpot(spottype) {
            this.locating = true;
            spotManager.create(spottype, this.$store.state.center).then(spot => {
                this.locating = false;
                this.$nav.push("Spot", { spot, zoom: this.zoom });
            });
        },
        addCatch() {
            this.locating = true;
            catchManager.create(null, this.$store.state.center).then(catchT => {
                this.locating = false;
                this.$nav.push("Catch", { catch: catchT });
            });
        }
    }
};
</script>

<style>
</style>