<template>
    <div>
        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <div class="my-5"><canvas ref="canvas"></canvas></div>

        <div class="text-center overflow-x-auto">
            <v-ons-segment :index.sync="tabMonth" class="pb-2" modifier="large">
                <button><v-ons-icon icon="asterisk"></v-ons-icon></button>
                <button v-for="month in months" :key="month">{{ ("month_short_" + month) | i18n }}</button>
            </v-ons-segment>
        </div>

        <v-ons-list class="mt-3" modifier="rounded">
            <v-ons-list-header>{{ "report_summary" | i18n }}</v-ons-list-header>
            <v-ons-list-item>
                <div class="center">{{ "report_max_weight" | i18n }}</div>
                <div class="right">{{ (summary.maxWeight || 0) | unit("kg") }}</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">{{ "report_med_weight" | i18n }}</div>
                <div class="right">{{ (summary.medWeight || 0) | unit("kg") }}</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">{{ "report_blanks" | i18n }}</div>
                <div class="right">{{ summary.blanks || 0 }}</div>
            </v-ons-list-item>
            <v-ons-list-header>{{ "report_fishies" | i18n }}</v-ons-list-header>
            <v-ons-list-item v-for="(key, val) in summary.fishies" :key="val">
                <div class="center">{{ val }}</div>
                <div class="right">{{ key }}</div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item @click="showCatches" v-tappable modifier="chevron">{{ periodTitle }}</v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import moment from "moment";
import Base from "./Base";
import { months } from "../constants";
import { daysInMonth, daytime } from "./util";
import { toNumber } from "../util";

const CURRENT_YEAR = new Date().getFullYear();

export function createDataSet(label, data, borderColor, backgroundColor) {
    return {
        data,
        label,
        borderColor,
        backgroundColor
    };
}

export default {
    report: {
        index: 100,
        name: "report_catches_by_time",
        description: "report_catches_by_time_desc",
        premium: true
    },
    extends: Base,
    data() {
        return { tabYear: 0, tabMonth: 0, months, summary: {}, params: { year: CURRENT_YEAR, month: null, ...this.createParams() } };
    },
    mounted() {
        this.tabYear = this.years.length - 1;
        this.params.year = this.years.length > 0 ? this.years[this.years.length - 1] : CURRENT_YEAR;
    },
    methods: {
        createParams() {
            return {};
        },
        createData() {
            return {
                options: {
                    // legend: { display: false }
                },
                type: "line",
                data: { labels: [], datasets: [] }
            };
        },
        updateData(chart) {
            const summary = {};
            if (Number.isFinite(this.params.month)) {
                const days = Array.from(Array(daysInMonth(this.params.year, this.params.month)).keys());
                chart.config.data.labels = days.map(day => day + 1);
                chart.data.datasets = [
                    createDataSet(
                        this.$i18n.get("catches"),
                        days.map(day => this.dataSet(this.params.year, this.params.month, day, null, summary)),
                        this.getColor("text-primary"),
                        this.getColor("text-primary", 0.25)
                    ),
                    createDataSet(
                        this.$i18n.get("blanks"),
                        days.map(day => this.dataSet(this.params.year, this.params.month, day, { blank: true }, summary)),
                        this.getColor("text-danger"),
                        this.getColor("text-danger", 0.25)
                    )
                ];
            } else {
                chart.config.data.labels = months.map(name => this.$i18n.get("month_" + name));
                chart.data.datasets = [
                    createDataSet(
                        this.$i18n.get("catches"),
                        Array.from(Array(12).keys()).map(month => this.dataSet(this.params.year, month, undefined, null, summary)),
                        this.getColor("text-primary"),
                        this.getColor("text-primary", 0.25)
                    ),
                    createDataSet(
                        this.$i18n.get("blanks"),
                        Array.from(Array(12).keys()).map(month => this.dataSet(this.params.year, month, undefined, { blank: true }, summary)),
                        this.getColor("text-danger"),
                        this.getColor("text-danger", 0.25)
                    )
                ];
            }

            this.summary = summary;
        },
        dataSet(year, month, day, options, summary) {
            const periodStart = daytime(year, month, day);
            const periodEnd = daytime(year, month, day, true);
            return this.$store.getters["catch/all"].filter(item => item.date >= periodStart && item.date <= periodEnd && this.catchFilter(item, options) && this.summarize(item, options, summary)).length;
        },
        selectMonth(month) {
            this.params.month = month;
        },
        showCatches() {
            const periodStart = daytime(this.params.year, this.params.month, null);
            const periodEnd = daytime(this.params.year, this.params.month, null, true);
            const filter = item => item.date >= periodStart && item.date <= periodEnd && this.catchFilter(item);
            this.$nav.push("ShowCatches", { sort: { field: "date", dir: 1 }, filter, title: this.periodTitle });
        },
        catchFilter(item, options) {
            const blank = options && options.blank !== undefined ? options.blank : false;
            return item.blank === blank;
        },
        summarize(item, options, summary) {
            summary.blanks = summary.blanks || 0;
            summary.weights = summary.weights || [];
            summary.fishies = summary.fishies || {};
            summary.maxWeight = summary.maxWeight || 0;

            if (options && options.blank && item.blank) {
                summary.blanks++;
            }

            if (!item.blank && item.fish) {
                const fish = item.fish.trim();
                summary.fishies[fish] = summary.fishies[fish] || 0;
                summary.fishies[fish]++;

                const weight = toNumber(item.weight);
                summary.weights.push(weight);
                summary.medWeight = summary.weights.reduce((sum, curr) => sum + curr, 0) / summary.weights.length;

                if (weight > summary.maxWeight) {
                    summary.maxWeight = weight;
                }
            }

            return true;
        }
    },
    computed: {
        years() {
            return this.$store.getters["catch/all"]
                .map(item => moment(item.date).year())
                .uniq()
                .sort();
        },
        periodTitle() {
            const start = daytime(this.params.year, this.params.month, null);
            const end = daytime(this.params.year, this.params.month, null, true);
            return this.$i18n.get("catches") + ": " + [start, end].map(t => moment(t).format("MM.YYYY")).join(" - ");
        }
    },
    watch: {
        tabYear(index) {
            this.params.year = this.years[index];
        },
        tabMonth(index) {
            this.params.month = index === 0 ? null : index - 1;
        }
    }
};
</script>