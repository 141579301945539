import Vue from "vue";

const events = ["dragstart", "touchstart", "touchmove", "mousemove"];

function handle(e) {
    e.stopPropagation();
}

Vue.directive("unswipeable", {
    bind(el) {
        events.forEach(event => el.addEventListener(event, handle));
    },
    unbind(el) {
        events.forEach(event => el.removeEventListener(event, handle));
    }
});
