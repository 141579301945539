export default {
    namespaced: true,
    state: {
        id: null,
        email: "",
        photoUrl: "",
        displayName: ""
    },
    actions: {
        set: ({ commit }, user) => {
            commit("SET", user);
        },
        unset: ({ commit }) => {
            commit("UNSET");
        }
    },
    mutations: {
        SET(state, user) {
            Object.assign(state, user);
        },
        UNSET(state) {
            Object.keys(state).forEach(key => (state[key] = null));
        }
    }
};
