<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: ["observer"],
    mounted() {
        setTimeout(() => this.observer.observe(this.$el), 64);
    },
    beforeDestroy() {
        this.observer.unobserve(this.$el);
    }
};
</script>