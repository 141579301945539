// @ts-check
import em from "./event";
import um from "./user";
import { appBaseUrl } from "../constants";

export const PRODUCT_MONTHLY = "fishy_premium_subscription";
export const PRODUCT_YEARLY = "fishy_premium_subscription_yearly";
export const PRODUCTS = [PRODUCT_MONTHLY, PRODUCT_YEARLY];

const DEBUG = false;
const MAX_NUM_CATCHES = 15;

class PremiumManager {
    async start() {
        // Already started?
        if (this.storeCronIval) {
            // Already started
        } else {
            // Init
            this.dirty = false;
            this.storeCronIval = setInterval(() => this.storeCron(), 2000);

            // Init
            this.store = window.store;
            if (!this.store) {
                console.log("No purchase plugin found");
                return;
            }

            // Prepare
            this.store.verbosity = 4;
            this.store.validator = `${appBaseUrl}/premium/validate`;
            this.store.error = e => this.errorHandler(e);
            this.store.applicationUsername = () => this.$store.state.user.id;

            // Register products
            PRODUCTS.forEach(id => this.store.register({ id, type: this.store.PAID_SUBSCRIPTION }));

            // Init store
            PRODUCTS.forEach(id => {
                this.store.when(id).loaded(product => this.loaded(product));
                this.store.when(id).updated(product => this.updated(product));
                this.store.when(id).approved(product => this.approved(product));
                this.store.when(id).verified(product => this.verified(product));
                this.store.when(id).owned(product => this.owned(product));
            });
        }

        // Refresh store
        this.refresh();
    }

    async stop() {
        this.dirty = false;
        if (this.storeCronIval) {
            clearInterval(this.storeCronIval);
            this.storeCronIval = null;
        }
    }

    loaded(product) {
        this.updateStore(product, "loaded");
    }

    updated(product) {
        this.updateStore(product, "updated");
    }

    approved(product) {
        product.verify();
        this.updateStore(product, "approved");
    }

    verified(product) {
        product.finish();
        this.updateStore(product, "verified");
    }

    owned(product) {
        this.updateStore(product, "owned");
    }

    useable(feature, model) {
        return this.subscribed().then(subscribed => {
            // Feature check: Create max number of catches
            if (!subscribed && feature == "catch" && model && (model.id || this.$store.state.catch.entities.length < MAX_NUM_CATCHES)) {
                return true;
            }

            // Feature check: Show all premium charts
            if (!subscribed && feature == "charts" && model && !model.premium) {
                return true;
            }

            // Fallback
            return subscribed;
        });
    }

    subscribed() {
        return Promise.resolve(DEBUG ? window.sessionStorage.getItem("subscribed") == "true" : this.$store.getters["premium/subscribed"]);
    }

    subscribe(productId) {
        if (DEBUG) {
            window.sessionStorage.setItem("subscribed", "true");
            this.dirty = true;
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const product = this.$store.getters["premium/byid"](productId);
            if (!product || !product.id) {
                return reject("Product not found: " + productId);
            } else if (!product.valid) {
                return reject("Product invalid: " + productId);
            } else if (product.owned) {
                return reject("Product already owned: " + productId);
            } else if (!product.canPurchase) {
                return reject("Product can not be purchased: " + productId);
            }

            try {
                this.store.order(productId);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    manageSubscriptions() {
        this.store.manageSubscriptions();
    }

    refresh() {
        if (this.store) {
            this.store.refresh();
        }
    }

    errorHandler(e) {
        console.log(e);
    }

    updateStore(product, event) {
        if (product && product.id) {
            this.dirty = true;
            const state = {
                id: product.id,
                type: product.type,
                title: product.title,
                state: product.state,
                price: product.price,
                owned: product.owned,
                valid: product.valid,
                canPurchase: product.canPurchase,
                description: product.description
            };

            if (state.valid === true) {
                this.$store.dispatch("premium/log", { ...state, event });
                this.$store.dispatch("premium/update", state);
            } else {
                this.$store.dispatch("premium/log", { ...state, event: event + ":invalid" });
            }
        } else {
            console.log("Invalid product", product);
        }
    }

    storeCron() {
        if (this.dirty && um.isLoggedIn()) {
            this.dirty = false;
            em.emit("premium");
        }
    }
}

export default new PremiumManager();
