<template>
    <v-ons-list-item @click="$emit('click')" class="fixed">
        <ListItemIcon :icon="icon"></ListItemIcon>
        <div class="center">
            <div class="list-item__title">{{ entity.name }}</div>
            <div class="list-item__subtitle">
                <span v-if="entity.watersName">{{ entity.watersName }}</span>
            </div>
        </div>
        <div v-if="distance" class="right">
            <div class="list-item__label nowrap">{{ distance | unit("m") }}</div>
        </div>
    </v-ons-list-item>
</template>

<script>
import spotManager from "../bl/spot";
export default {
    props: ["entity"],
    computed: {
        icon() {
            return spotManager.icon(this.entity);
        },
        distance() {
            return this.entity.metadata && this.entity.metadata.dist;
        }
    }
};
</script>