<template>
    <div>
        <Observable :observer="observer" v-for="chunk in chunks" :key="chunk.id" :data-chunk="chunk.id">
            <slot v-if="chunkVisible(chunk)" v-bind="chunk"></slot>
            <div v-if="!chunkVisible(chunk)" :style="invisibleChunkStyle(chunk)"></div>
        </Observable>
    </div>
</template>

<script>
const CHUCK_SIZE = 25;

export default {
    props: ["items", "itemHeight"],
    data() {
        return { visibleChunks: [], observer: null };
    },
    mounted() {
        this.observer = new IntersectionObserver(
            entries => {
                entries.map(({ target, isIntersecting }) => this.setChunkVisible(Number(target.getAttribute("data-chunk")), isIntersecting));
            },
            { root: this.$el.closest(".content") }
        );
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    methods: {
        setChunkVisible(chunk, visible) {
            this.visibleChunks.toggle(chunk.id || chunk, visible);
        },
        chunkVisible(chunk) {
            return this.visibleChunks.includes(chunk.id);
        },
        invisibleChunkStyle(chunk) {
            return { height: `${chunk.items.length * (this.itemHeight || 62)}px` };
        }
    },
    computed: {
        chunks() {
            return this.items.chunk(CHUCK_SIZE).map((items, id) => ({ id, items }));
        }
    }
};
</script>