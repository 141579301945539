<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center text-truncate">{{ title | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save">{{ "save" | i18n }}</v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <v-ons-list>
            <v-ons-list-item>
                <ListItemIcon icon="link"></ListItemIcon>
                <v-ons-input v-model="model.name" type="text" :placeholder="'bait' | i18n"></v-ons-input>
                <div class="right">
                    <v-ons-button @click="chooseBait" modifier="quiet"><v-ons-icon icon="ellipsis-h"></v-ons-icon></v-ons-button>
                </div>
            </v-ons-list-item>
            <v-ons-list-item>
                <ListItemIcon icon="clone"></ListItemIcon>
                <v-ons-input v-model="model.description" type="text" :placeholder="'description' | i18n"></v-ons-input>
            </v-ons-list-item>

            <v-ons-list-header>{{ "food" | i18n }}</v-ons-list-header>
            <v-ons-list-item modifier="align-top">
                <ListItemIcon icon="drumstick-bite"></ListItemIcon>
                <textarea v-model="model.food" :placeholder="'food' | i18n" class="text-input" rows="3"></textarea>

                <div class="right">
                    <v-ons-button @click="chooseFood" modifier="quiet"><v-ons-icon icon="ellipsis-h"></v-ons-icon></v-ons-button>
                </div>
            </v-ons-list-item>
            <v-ons-list-item>
                <ListItemIcon icon="weight"></ListItemIcon>
                <UnitInput v-model="model.weight" placeholder="weight" unit="kg"></UnitInput>
                <div class="right">
                    <div class="list-item__label">{{ "kg" | unit }}</div>
                </div>
            </v-ons-list-item>
            <v-ons-list-item>
                <ListItemIcon icon="crop-alt"></ListItemIcon>
                <v-ons-input v-model="model.size" type="text" :placeholder="'size' | i18n"></v-ons-input>
            </v-ons-list-item>

            <!--
            <v-ons-list-header>{{ "date" | i18n }}</v-ons-list-header>
            <v-ons-list-item>
                <ListItemIcon icon="calendar-day"></ListItemIcon>
                <v-ons-input v-model="date" type="date"></v-ons-input>
            </v-ons-list-item>
            <v-ons-list-item>
                <ListItemIcon icon="clock"></ListItemIcon>
                <v-ons-input v-model="time" type="time"></v-ons-input>
            </v-ons-list-item> -->
        </v-ons-list>

        <v-ons-list v-if="model.id" class="my-5">
            <v-ons-list-item v-tappable @click="remove" class="text-danger">{{ "delete" | i18n }}</v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
import moment from "moment";
import { clone } from "../util";
export default {
    props: ["bait", "next"],
    data() {
        return {
            model: clone(this.bait),
            date: moment(this.bait.date).format("YYYY-MM-DD"),
            time: moment(this.bait.date).format("HH:mm")
        };
    },
    computed: {
        title() {
            return ["bait", this.model.id ? "edit" : "add"].join("_");
        }
    },
    methods: {
        save() {
            this.model.date = moment([this.date, this.time].join(" ")).valueOf();
            this.$nav.pop();
            if (this.next) {
                this.next(this.model);
            }
        },
        remove() {
            this.$nav.pop();
            if (this.next) {
                this.next();
            }
        },
        chooseBait() {
            this.$nav.push("ChooseBait", {
                select: bait => {
                    this.model.name = bait;
                    this.$nav.pop();
                }
            });
        },
        chooseFood() {
            this.$nav.push("ChooseFood", {
                select: food => {
                    this.model.food = food;
                    this.$nav.pop();
                }
            });
        }
    }
};
</script>