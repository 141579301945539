import moment from "moment";
import { unit } from "../util";

const DATE_FORMATS = {
    sm: "DD.MM.YYYY"
};

export default {
    install(Vue) {
        // Number
        Vue.filter("number", value => Math.floor(value * 10) / 10.0);

        // Date filter
        Vue.filter("date", function(value, format) {
            return moment(value).format(DATE_FORMATS[format] || "DD.MM.YYYY HH:mm");
        });

        // Dist filter
        Vue.filter("dist", function(value) {
            if (value >= 1000) {
                return Math.floor((value / 1000.0) * 10.0) / 10.0 + " km";
            }

            return Math.floor(value * 10.0) / 10.0 + " m";
        });

        // Unit filter
        Vue.filter("unit", function(value, unitT, som) {
            return unit(value, unitT, som);
        });
    }
};
