// @ts-check
import { collections } from "../../firebase";

class TaskManager {
    async create(id, timeout = 1000 * 15) {
        // Create task
        const doc = collections.task.doc(id);
        await doc.set({});

        // Init state
        let done = false;

        // Listen for task deletion
        const unsub = doc.onSnapshot(snapshot => (done = !snapshot.exists));

        // Start timer
        const start = new Date().getTime();
        return new Promise((resolve, reject) => {
            (function check() {
                if (done || new Date().getTime() - start > timeout) {
                    unsub();
                    return done ? resolve() : reject("Timeout");
                } else {
                    setTimeout(() => check(), 250);
                }
            })();
        });
    }
}

export default new TaskManager();
