<template>
    <div>
        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <div class="my-5"><canvas ref="canvas"></canvas></div>

        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item @click="showCatches" v-tappable modifier="chevron">{{ periodTitle }}</v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import CatchesByTime, { createDataSet } from "./CatchesByTime";
import { daytime } from "./util";
import { unit, toNumber } from "../util";

const STEPS = 10;

export default {
    report: {
        index: 400,
        name: "report_catches_by_weight",
        description: "report_catches_by_weight_desc",
        premium: true
    },
    extends: CatchesByTime,
    methods: {
        updateData(chart) {
            // Init
            let max = this.calcMaxWeight(this.params.year);
            max = max + (max || 1) / 10.0;
            const step = max / STEPS;
            const chunks = Array.from(Array(STEPS)).map((i, index) => step * index);

            // Create chart
            chart.config.data.labels = chunks.map(start => `${unit(start, "kg", { ext: false, digits: 0 }) || "0"}-${unit(start + step, "kg", { digits: 0 })}`);
            chart.data.datasets = [
                createDataSet(
                    this.$i18n.get("catches"),
                    chunks.map(weight => this.dataSet(this.params.year, weight, { step })),
                    this.getColor("text-primary"),
                    this.getColor("text-primary", 0.25)
                )
            ];
        },
        dataSet(year, weight, options) {
            const periodStart = daytime(year, null, null);
            const periodEnd = daytime(year, null, null, true);
            return this.$store.getters["catch/all"].filter(item => item.date >= periodStart && item.date <= periodEnd && this.catchFilter(item, options) && toNumber(item.weight) >= weight && toNumber(item.weight) < weight + options.step).length;
        },
        calcMaxWeight(year) {
            const periodStart = daytime(year, null, null);
            const periodEnd = daytime(year, null, null, true);
            return this.$store.getters["catch/all"].filter(item => item.date >= periodStart && item.date <= periodEnd && toNumber(item.weight) > 0).reduce((max, cur) => (toNumber(cur.weight) > max ? toNumber(cur.weight) : max), 0);
        }
    }
};
</script>