<template>
    <v-ons-page v-unswipeable>
        <v-ons-toolbar v-if="$nav.stack.length > 1">
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
        </v-ons-toolbar>
        <div class="content">
            <div class="text-right mt-3 mx-2">
                <v-ons-button @click="language" modifier="quiet">
                    <LocaleFlag :locale="$store.state.ui.locale" width="24"></LocaleFlag>
                </v-ons-button>
            </div>

            <div class="container-xs">
                <section class="text-center logo">
                    <Logo width="140" class="text-primary"></Logo>
                </section>

                <div v-if="error" class="alert mb-3">
                    {{ error.message || error }}
                </div>

                <v-ons-list modifier="noborder rounded">
                    <v-ons-list-item>
                        <v-ons-input v-model="model.email" :placeholder="'email' | i18n"></v-ons-input>
                    </v-ons-list-item>
                    <v-ons-list-item>
                        <v-ons-input v-model="model.password" type="password" :placeholder="'password' | i18n"></v-ons-input>
                    </v-ons-list-item>
                </v-ons-list>

                <section class="mt-5">
                    <v-ons-button @click="login" :disabled="!valid" modifier="large">{{ "login" | i18n }}</v-ons-button>
                </section>

                <section class="mt-3">
                    <v-ons-button @click="register" modifier="quiet large">{{ "create_account" | i18n }}</v-ons-button>
                </section>

                <section class="my-3">
                    <v-ons-button @click="resetpw" modifier="quiet large">{{ "forgot_password" | i18n }}</v-ons-button>
                </section>

                <Version></Version>
            </div>
        </div>
        <v-ons-modal :visible="loggingin" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
        </v-ons-modal>
    </v-ons-page>
</template>

<script>
import userManager from "../bl/user";
export default {
    data() {
        return { model: {}, error: null, working: false, loggingin: false };
    },
    computed: {
        valid() {
            return !this.working && this.model.email && this.model.email.length > 3;
        }
    },
    methods: {
        login() {
            this.error = null;
            this.working = true;
            this.loggingin = true;
            userManager.login(this.model).then(
                () => this.$nav.replace("Start"),
                err => {
                    this.working = false;
                    this.loggingin = false;
                    this.error = (err.response && err.response.data) || err.message || err;
                }
            );
        },
        register() {
            this.$nav.push("Register");
        },
        resetpw() {
            this.$nav.push("ResetPw");
        },
        language() {
            this.$nav.push("Language");
        }
    }
};
</script>

<style scoped>
.logo {
    margin: 5vh 0;
}
</style>