const names = [
    "Antone",
    "Vivian",
    "Octavio",
    "Zachary",
    "Donn",
    "Noreen",
    "Cecil",
    "Lamont",
    "Drew",
    "Alana",
    "Duane",
    "Lidia",
    "Joanne",
    "Jefferey",
    "Dwayne",
    "Rico",
    "Graig",
    "Gavin",
    "Clark",
    "Abel",
    "Vaughn",
    "Ira",
    "Ali",
    "Edward",
    "Ismael",
    "Basil",
    "Melissa",
    "Garfield",
    "Charlene",
    "Chance",
    "Sandy",
    "Sadie",
    "Toby",
    "Laurel",
    "Georgia",
    "Leonor",
    "Winford",
    "Norberto",
    "Kieth",
    "Julianne",
    "Rosario",
    "Carmella",
    "Brandie",
    "Marcelino",
    "Lyman",
    "Minerva",
    "Israel",
    "Rolland",
    "Emanuel",
    "Betty"
];

const adjectives = [
    "torpid",
    "yellow",
    "entertaining",
    "accurate",
    "milky",
    "famous",
    "grumpy",
    "melted",
    "momentous",
    "knotty",
    "steady",
    "phobic",
    "bumpy",
    "former",
    "rude",
    "military",
    "healthy",
    "paltry",
    "possible",
    "imported",
    "subsequent",
    "heartbreaking",
    "mute",
    "sneaky",
    "unequal",
    "basic",
    "funny",
    "mighty",
    "dependent",
    "incompetent",
    "bloody",
    "salty",
    "burly",
    "cooing",
    "decent",
    "hard",
    "brainy",
    "beneficial",
    "tested",
    "great",
    "female",
    "spectacular",
    "alike",
    "amusing",
    "wasteful",
    "mundane",
    "fluffy",
    "robust",
    "sparkling",
    "ahead"
];

function randa(data) {
    return data[Math.floor(Math.random() * data.length)];
}

class FakerManager {
    name() {
        return [randa(adjectives), randa(names)].join(" ");
    }
}

export default new FakerManager();
