<template>
    <v-ons-input :value="model" @input="update" type="number" :placeholder="placeholder | i18n"></v-ons-input>
</template>

<script>
import { unitTo, unitFrom } from "../../util";
export default {
    name: "UnitInput",
    props: ["value", "unit", "placeholder"],
    data() {
        return { model: Number(unitTo(this.value, this.unit)).toFixed(2) };
    },
    methods: {
        update(e) {
            let value = parseFloat(e.target.value.replace(",", "."));
            if (!Number.isFinite(value)) {
                value = 0;
            }

            value = unitFrom(value, this.unit);
            this.$emit("input", value);
        }
    }
};
</script>
