<template>
    <div></div>
</template>

<script>
import leaflet from "leaflet";
import { dist, unit } from "../../util";
export default {
    props: ["points"],
    inject: ["map"],
    watch: {
        points: {
            deep: true,
            handler() {
                this.updateSourceData();
            }
        }
    },
    mounted() {
        // Create source data
        this.updateSourceData();
    },
    beforeDestroy() {
        // Remove elements
        this.removeElements();
    },
    methods: {
        updateSourceData() {
            // Remove elements
            this.removeElements();

            // Create distance line
            const points = this.points.map(point => point.map(coords => [coords.lat, coords.lng]));
            this.lines = [leaflet.polyline(points, { color: "#000", weight: 2.5, opacity: 0.5 }).addTo(this.map), leaflet.polyline(points, { color: "#fff", dashArray: [5, 5], weight: 2, opacity: 0.5 }).addTo(this.map)];

            // Create distance label
            this.markers = this.points.map(point => {
                const to = point[1];
                const from = point[0];
                const len = dist(from, to);
                const mid = [(to.lat + from.lat) / 2.0, (to.lng + from.lng) / 2.0];
                const icon = L.divIcon({ html: `<span class="dist">${unit(len, "m", { digits: 0 })}</span>`, iconSize: [0, 0] });
                return leaflet.marker(mid, { icon }).addTo(this.map);
            });
        },
        removeElements() {
            if (this.lines) {
                this.lines.forEach(line => line.remove());
            }

            if (this.markers) {
                this.markers.forEach(marker => marker.remove());
            }
        }
    }
};
</script>