<template>
    <div>
        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <div class="my-5"><canvas ref="canvas"></canvas></div>

        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item @click="showCatches" v-tappable modifier="chevron">{{ periodTitle }}</v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import CatchesByTime, { createDataSet } from "./CatchesByTime";
import { daytime } from "./util";
import { toNumber } from "../util";

const MIN = 0;
const MAX = 24;
const STEPS = 12;
const STEP_SIZE = (MAX - MIN) / STEPS;
const CHUNKS = Array.from(Array(STEPS)).map((i, index) => MIN + STEP_SIZE * index);
const LABELS = CHUNKS.map(start => `${start}-${start + STEP_SIZE - 1}°C`);

export default {
    report: {
        index: 400,
        name: "report_catches_by_water_temperature",
        description: "report_catches_by_water_temperature_desc",
        premium: true
    },
    extends: CatchesByTime,
    methods: {
        updateData(chart) {
            chart.config.data.labels = LABELS;
            chart.data.datasets = [
                createDataSet(
                    this.$i18n.get("catches"),
                    CHUNKS.map(hpa => this.dataSet(this.params.year, hpa)),
                    this.getColor("text-primary"),
                    this.getColor("text-primary", 0.25)
                ),
                createDataSet(
                    this.$i18n.get("blanks"),
                    CHUNKS.map(hpa => this.dataSet(this.params.year, hpa, { blank: true })),
                    this.getColor("text-danger"),
                    this.getColor("text-danger", 0.25)
                )
            ];
        },
        dataSet(year, hpa, options) {
            const periodStart = daytime(year, null, null);
            const periodEnd = daytime(year, null, null, true);
            return this.$store.getters["catch/all"].filter(item => item.date >= periodStart && item.date <= periodEnd && this.catchFilter(item, options) && toNumber(item.waterTemperature) > 0 && toNumber(item.waterTemperature) >= hpa && toNumber(item.waterTemperature) < hpa + STEP_SIZE).length;
        }
    }
};
</script>