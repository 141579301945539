// @ts-check
import moment from "moment";

export function daysInMonth(year, month) {
    return moment()
        .year(year)
        .month(month)
        .daysInMonth();
}

export function daytime(year, month, day, end = false) {
    month = Number.isFinite(month) ? month : end ? 11 : 0;
    day = Number.isFinite(day) ? day : end ? daysInMonth(year, month) - 1 : 0;
    return moment()
        .year(year)
        .month(month)
        .date(1 + day)
        .hour(end ? 23 : 0)
        .minute(end ? 59 : 0)
        .second(end ? 59 : 0)
        .millisecond(end ? 999 : 0)
        .valueOf();
}
