<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center text-truncate">{{ title | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save">{{ "save" | i18n }}</v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <div class="content">
            <SpotMap style="height: 300px" :spot.sync="model" :zoom="zoom"></SpotMap>

            <v-ons-list>
                <v-ons-list-item>
                    <ListItemIcon icon="clone"></ListItemIcon>
                    <v-ons-input v-model="model.name" type="text" :placeholder="'name' | i18n"></v-ons-input>
                    <div class="right">
                        <v-ons-icon v-if="nameLoading" fixed-width spin icon="spinner" class="list-item__icon text-muted"></v-ons-icon>
                    </div>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="pencil-square-o"></ListItemIcon>
                    <v-ons-input v-model="model.watersName" type="text" :placeholder="'watersname' | i18n"></v-ons-input>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="align-left"></ListItemIcon>
                    <v-ons-input v-model="model.description" type="text" :placeholder="'description' | i18n"></v-ons-input>
                </v-ons-list-item>
                <v-ons-list-item v-tappable @click="chooseIcon" modifier="chevron">
                    <ListItemIcon :icon="icon"></ListItemIcon>
                    <div class="center">
                        <span v-if="model.icon">{{ model.icon | i18n }}</span>
                        <span v-if="!model.icon">{{ "icon_choose" | i18n }}</span>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <div :is="spotComponent" :spot="model"></div>

            <v-ons-list v-if="model.id" class="my-5">
                <v-ons-list-item v-tappable @click="remove" class="text-danger">{{ "delete" | i18n }}</v-ons-list-item>
            </v-ons-list>
        </div>

        <v-ons-modal :visible="modal" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
            <p>{{ "saving" | i18n }}</p>
        </v-ons-modal>
    </v-ons-page>
</template>

<script>
import { clone, capitalize } from "../util";
import spotManager from "../bl/spot";
export default {
    props: ["spot", "zoom", "next"],
    data() {
        return {
            modal: false,
            model: clone(this.spot),
            nameLoading: false
        };
    },
    computed: {
        spotComponent() {
            return `PagesSpot${capitalize(this.model.spottype)}`;
        },
        title() {
            return ["spot", this.model.spottype, this.model.id ? "edit" : "add"].join("_");
        },
        icon() {
            return spotManager.icon(this.model);
        }
    },
    mounted() {
        if (!this.spot.id) {
            this.nameLoading = true;
            spotManager.estimateName(this.spot).then(
                name => {
                    this.nameLoading = false;
                    if (name && !this.model.name) {
                        this.model.name = name;
                    }
                },
                err => (this.nameLoading = false)
            );
        }
    },
    methods: {
        save() {
            this.modal = true;
            setTimeout(() => {
                this.$store.dispatch("spot/update", this.model);
                this.modal = false;
                this.$nav.pop();
                this.$ons.notification.toast(this.$i18n.get("successfully_saved"), { timeout: 1000 });
                if (this.next) {
                    this.next(this.model);
                }
            }, 64);
        },
        remove() {
            this.$ons.notification.confirm(this.$i18n.get("really_delete?")).then(result => {
                if (result) {
                    this.$store.dispatch("spot/delete", this.model);
                    this.$nav.pop();
                    this.$ons.notification.toast(this.$i18n.get("successfully_deleted"), { timeout: 1000 });
                    if (this.next) {
                        this.next(null);
                    }
                }
            });
        },
        chooseIcon() {
            this.$nav.push("ChooseIcon", {
                selected: this.model.icon,
                select: icon => {
                    this.model.icon = icon;
                    this.$nav.pop();
                }
            });
        }
    }
};
</script>