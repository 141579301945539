<template>
    <div>
        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <v-ons-list modifier="inset" class="mt-3">
            <v-ons-list-item v-for="item in foods" :key="item.key">
                <div class="center">{{ item.key }}</div>
                <div class="right">{{ item.value | unit("kg") }}</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">
                    <b>{{ "sum" | i18n }}</b>
                </div>
                <div class="right">
                    <b>{{ sum | unit("kg") }}</b>
                </div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">
                    <b>{{ "report_bait_per_catch" | i18n }}</b>
                </div>
                <div class="right">
                    <b>{{ baitPerCatch | unit("kg") }}</b>
                </div>
            </v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import moment from "moment";
import { daytime } from "./util";
import { toNumber } from "../util";

function isInYear(date, year) {
    const start = daytime(year);
    const end = daytime(year, null, null, true);
    return date >= start && date <= end;
}

export default {
    report: {
        index: 200,
        name: "report_food",
        description: "report_food_desc",
        premium: true
    },
    data() {
        return { tabYear: 0 };
    },
    computed: {
        years() {
            return this.$store.getters["catch/all"]
                .map(item => moment(item.date).year())
                .uniq()
                .sort();
        },
        catches() {
            return this.$store.getters["catch/all"].filter(catcht => isInYear(catcht.date, this.years[this.tabYear]));
        },
        foods() {
            const raw = this.catches.reduce((data, catcht) => {
                catcht.baits.forEach(bait => {
                    if (bait.food && bait.weight > 0) {
                        data[bait.food] = data[bait.food] || 0;
                        data[bait.food] += toNumber(bait.weight);
                    }
                });

                return data;
            }, {});

            return Object.keys(raw)
                .map(key => ({ key, value: Number(raw[key]).toFixed(2) }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));
        },
        sum() {
            return Number(this.foods.reduce((sum, item) => sum + parseFloat(item.value), 0)).toFixed(2);
        },
        baitPerCatch() {
            return this.catches.length > 0 ? (this.sum / this.catches.length).toFixed(2) : "n/a";
        }
    }
};
</script>