<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "notebook" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button id="notebook_sort" @click="chooseSort"><v-ons-icon icon="sort-amount-down"></v-ons-icon></v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item @click="edit(note)" v-for="note in notes" :key="note.id">
                    <ListItemIcon icon="file-alt"></ListItemIcon>
                    <div class="content">
                        <div class="list-item__title">{{ note.title }}</div>
                        <div class="list-item__subtitle">{{ note.updated | date("sm") }}</div>
                    </div>
                </v-ons-list-item>
            </v-ons-list>
        </div>

        <v-ons-fab @click="add" position="bottom right">
            <v-ons-icon icon="plus"></v-ons-icon>
        </v-ons-fab>

        <v-ons-popover :visible.sync="sortVisible" :cancelable="true" direction="down" target="#notebook_sort">
            <v-ons-list>
                <v-ons-list-item @click="sortBy(field)" v-for="field in sorts" :key="field" v-tappable>
                    <div class="right text-primary">
                        <v-ons-icon fixed-width :icon="sortIcon(field)"></v-ons-icon>
                    </div>
                    <div class="center">{{ field | i18n }}</div>
                </v-ons-list-item>
            </v-ons-list>
        </v-ons-popover>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return { sortVisible: false, sorts: ["created", "updated", "title"] };
    },
    computed: {
        notes() {
            return [...this.$store.state.notebook.entities].sort((a, b) => {
                let comp = a[this.sort.field] > b[this.sort.field];
                if (typeof a[this.sort.field] == "string") {
                    comp = a[this.sort.field].toLowerCase() > b[this.sort.field].toLowerCase();
                }

                return this.sort.dir * (comp ? 1 : -1);
            });
        },
        sort() {
            return this.$store.state.notebook.sort;
        }
    },
    methods: {
        add() {
            this.edit();
        },
        edit(note) {
            this.$nav.push("Note", { note });
        },
        sortBy(field) {
            this.sortVisible = !this.sortVisible;
            this.$store.dispatch("notebook/sort", field);
        },
        sortIcon(field) {
            return field == this.sort.field ? (this.sort.dir === 1 ? "caret-down" : "caret-up") : "empty";
        },
        chooseSort() {
            this.sortVisible = !this.sortVisible;
        }
    }
};
</script>