<template>
    <div>
        <div v-if="useable">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import premiumManager from "../bl/premium";
export default {
    props: ["feature", "model"],
    data() {
        return { useable: true };
    },
    mounted() {
        this.$event.on("premium", this.reload);
        this.reload();
    },
    beforeDestroy() {
        this.$event.off("premium", this.reload);
    },
    methods: {
        reload() {
            return premiumManager.useable(this.feature, this.model).then(
                useable => this.setUseable(useable),
                err => this.setUseable(false)
            );
        },
        setUseable(useable) {
            this.useable = useable;
        }
    }
};
</script>
