<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "profile" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save">{{ "save" | i18n }}</v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <div class="container-xs">
            <section class="text-center my-5">
                <div @click="chooseAvatar" class="avatar">
                    <i v-if="!user.photoUrl" class="fa fa-fw fa-user-circle text-primary" style="font-size: 80pt"></i>
                    <div v-if="user.photoUrl" class="avatar-image" :style="{ backgroundImage: 'url(' + avatar + ')' }"></div>
                    <input v-if="browser" type="file" @click.stop @change="chooseAvatarFile" accept="image/*" capture="environment" class="invisible" />
                </div>
            </section>

            <section>
                <v-ons-list>
                    <v-ons-list-item>
                        <v-ons-input v-model="user.email" :placeholder="'email' | i18n"></v-ons-input>
                    </v-ons-list-item>
                    <v-ons-list-item>
                        <v-ons-input v-model="user.displayName" :placeholder="'display_name' | i18n"></v-ons-input>
                    </v-ons-list-item>
                </v-ons-list>
            </section>
        </div>
        <v-ons-modal :visible="working !== false" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
            <p>{{ working | i18n }}</p>
        </v-ons-modal>
    </v-ons-page>
</template>

<script>
import userManager from "../bl/user";
import photoManager from "../bl/photo";
export default {
    data() {
        return {
            user: { ...this.$store.state.user },
            working: false
        };
    },
    methods: {
        save(close = true) {
            this.working = "saving";
            return userManager.update(this.user).then(() => {
                this.working = false;
                if (close) {
                    this.$nav.pop();
                }
            });
        },
        chooseAvatarFile(e) {
            return this.saveAvatar(e.target.files[0]).then(() => (e.target.value = null));
        },
        chooseAvatar() {
            this.working = "photo_taking";
            return photoManager.take({ targetWidth: 1024, targetHeight: 1024, sourceType: 0 }).then(
                file => this.saveAvatar(file),
                () => (this.working = false)
            );
        },
        saveAvatar(file) {
            this.working = "photo_uploading";
            return photoManager.uploadSync(file).then(
                id => {
                    this.working = false;
                    this.user.photoUrl = photoManager.url(id, "sm");
                    return this.save(false);
                },
                () => (this.working = false)
            );
        }
    },
    computed: {
        browser() {
            return !photoManager.native();
        },
        avatar() {
            return photoManager.url(this.user.photoUrl, "sm");
        }
    }
};
</script>