<template>
    <div class="pb-3 text-muted text-center">Version: {{ version }}</div>
</template>

<script>
import info from "../../../../package.json";
export default {
    name: "Version",
    computed: {
        version() {
            return info.version;
        }
    }
};
</script>
