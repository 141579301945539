<template>
    <v-ons-list-item @click="$emit('click')" class="fixed">
        <div class="left">
            <v-ons-icon v-if="!image || entity.blank" fixed-width icon="fish" class="list-item__icon text-muted"></v-ons-icon>
            <img v-if="image && !entity.blank" :src="image" class="list-item__thumbnail" />
        </div>
        <div class="center">
            <div v-if="!entity.blank" class="list-item__title">{{ entity.fish }}</div>
            <div v-if="entity.blank" class="list-item__title text-muted">{{ "blank" | i18n }}</div>
            <div class="list-item__subtitle">{{ entity.date | date("sm") }}</div>
        </div>
        <div class="right">
            <div v-if="!entity.blank" class="list-item__label nowrap">{{ entity.weight | unit("kg") }} <i>&middot;</i> {{ entity.length | unit("cm") }}</div>
            <div v-if="entity.blank" class="list-item__label text-danger">{{ "blank" | i18n }}</div>
        </div>
    </v-ons-list-item>
</template>

<script>
import photo from "../bl/photo";
export default {
    props: ["entity"],
    computed: {
        image() {
            return this.entity.images && this.entity.images.length > 0 && photo.url(this.entity.images[0], "thumb");
        }
    }
};
</script>