<script>
import Color from "rgbcolor";
import Chart from "chart.js";
export default {
    mounted() {
        const ctx = this.$refs.canvas.getContext("2d");
        this.chart = new Chart(ctx, this.createData());
        this.update();
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    },
    methods: {
        update() {
            this.updateData(this.chart);
            this.chart.update();
        },
        getColor(classname, alpha = 1) {
            const span = document.createElement("span");
            span.classList.add(classname);
            this.$el.append(span);
            const style = window.getComputedStyle(span);
            const color = new Color(style.color);
            color.alpha = alpha;
            span.remove();
            return color.toRGBA();
        }
    },
    watch: {
        params: {
            handler() {
                this.update();
            },
            deep: true
        }
    }
};
</script>