<template>
    <v-ons-list>
        <!--
        <v-ons-list-header>{{ "baits" | i18n }}</v-ons-list-header>
        <BaitItem :entity="bait" @click="editBait(bait)" v-for="bait in spot.baits" :key="bait.id" v-tappable></BaitItem>
        <v-ons-list-item v-tappable modifier="chevron" @click="addBait">
            <ListItemIcon icon="empty"></ListItemIcon>
            <div class="center">{{ "bait_add" | i18n }}</div>
        </v-ons-list-item> -->
        <v-ons-list-header>{{ "waters" | i18n }}</v-ons-list-header>
        <v-ons-list-item>
            <ListItemIcon icon="angle-double-down"></ListItemIcon>
            <UnitInput v-model="spot.depth" placeholder="depth" unit="m"></UnitInput>
            <div class="right">
                <div class="list-item__label">{{ "m" | unit }}</div>
            </div>
        </v-ons-list-item>

        <v-ons-list-header v-show="spot.id">{{ "catches" | i18n }}</v-ons-list-header>
        <CatchItem :entity="entity" @click="editCatch(entity)" v-tappable v-for="entity in catches" :key="entity.id"></CatchItem>
        <v-ons-list-item v-if="spot.id" v-tappable modifier="chevron" @click="addCatch">
            <ListItemIcon icon="empty"></ListItemIcon>
            <div class="center">{{ "catch_add" | i18n }}</div>
        </v-ons-list-item>
    </v-ons-list>
</template>

<script>
import baitManager from "../../bl/bait";
import catchManager from "../../bl/catch";
export default {
    props: ["spot"],
    methods: {
        /*
        addBait() {
            baitManager.create().then(bait => this.$nav.push("Bait", { bait, next: this.appendBait }));
        },
        editBait(bait) {
            this.$nav.push("Bait", { bait, next: result => Object.assign(bait, result) });
        },
        appendBait(bait) {
            this.spot.baits.push(bait);
        },*/
        addCatch() {
            catchManager.create(this.spot).then(catchT => this.$nav.push("Catch", { catch: catchT }));
        },
        editCatch(catchT) {
            this.$nav.push("Catch", { catch: catchT });
        }
    },
    computed: {
        catches() {
            return this.$store.getters["catch/byspot"](this.spot);
        }
    }
};
</script>