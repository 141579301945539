<template>
    <div>
        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item>
                <ListItemIcon icon="link"></ListItemIcon>
                <v-ons-select v-model="params.assembly">
                    <option value=""></option>
                    <option v-for="assembly in assemblies" :key="assembly" :value="assembly">{{ assembly }}</option>
                </v-ons-select>
            </v-ons-list-item>
        </v-ons-list>

        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <div class="my-5"><canvas ref="canvas"></canvas></div>

        <div class="text-center overflow-x-auto">
            <v-ons-segment :index.sync="tabMonth" class="pb-2" modifier="large">
                <button><v-ons-icon icon="asterisk"></v-ons-icon></button>
                <button v-for="month in months" :key="month">{{ ("month_short_" + month) | i18n }}</button>
            </v-ons-segment>
        </div>

        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item @click="showCatches" v-tappable modifier="chevron">{{ periodTitle }}</v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import CatchesByTime from "./CatchesByTime";

export default {
    report: {
        index: 300,
        name: "report_catches_by_assembly",
        description: "report_catches_by_assembly_desc",
        premium: true
    },
    extends: CatchesByTime,
    methods: {
        createParams() {
            return { assembly: "" };
        },
        catchFilter(item, options) {
            const blank = options && options.blank !== undefined ? options.blank : false;
            return item.blank === blank && item.assembly == this.params.assembly;
        }
    },
    computed: {
        assemblies() {
            return this.$store.getters["catch/all"]
                .map(item => item.assembly)
                .uniq()
                .filter(Boolean);
        }
    }
};
</script>