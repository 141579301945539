// @ts-check
import geo from "../bl/geo";
import weather from "../bl/weather";

const DIRS = "O,SO,S,SW,W,NW,N,NO".split(",");
function degToCompass(deg) {
    while (deg < 0) deg += 360;
    while (deg > 360) deg -= 360;
    let parts = 360.0 / DIRS.length;
    for (let i = 0; i < DIRS.length; i++) {
        if (deg >= i * parts - parts / 2 && deg < i * parts + parts / 2) {
            return DIRS[i];
        }
    }
}

class CatchManager {
    create(spot = null, position = null) {
        return Promise.resolve(spot ? spot : position || geo.getPosition()).then(location => {
            const now = new Date().getTime();
            return {
                spot: spot ? spot.id || spot : null,
                blank: false,
                images: [],
                weight: 1.0,
                length: 50.0,
                date: now,
                assembly: null,
                fish: "",
                baits: [],
                waterTemperature: 10.0,
                moonphase: "",
                temperature: 0,
                humidity: 0,
                pressure: 0,
                windDirection: 0,
                weatherConditionId: 0,
                weatherCondition: ""
            };
        });
    }

    loadWeatherModel(catchT) {
        // Estimate location
        let location = this.$store.state.center;
        if (catchT && catchT.spot) {
            const spot = this.$store.getters["spot/byid"](catchT.spot);
            if (spot) {
                location = { lat: spot.lat, lng: spot.lng };
            }
        }

        // Load weather data
        return weather.getWeather(location).then(weatherResult => {
            const now = new Date().getTime();

            // Create weather model
            return {
                moonphase: weather.getMoonPhase(now),
                temperature: Math.round(weatherResult.main.temp),
                humidity: Math.round(weatherResult.main.humidity),
                pressure: Math.round(weatherResult.main.pressure),
                windDirection: degToCompass(weatherResult.wind.deg),
                weatherConditionId: weatherResult.weather.map(item => item.id).pop(),
                weatherCondition: weatherResult.weather.map(item => item.main).join(", ")
            };
        });
    }
}

export default new CatchManager();
