<template>
    <v-ons-page>
        <div ref="root" class="content">
            <div class="gallery">
                <Observable :observer="observer" v-for="entity in gallery" :key="entity.key" class="gallery-item">
                    <div @click="edit(entity)" :data-img="imageUrl(entity)" class="image"></div>
                    <div class="caption">
                        <div>{{ entity.fish }}</div>
                        <div class="text-muted">{{ entity.weight }}kg <i>&middot;</i> {{ entity.length }}cm</div>
                    </div>
                </Observable>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
import photo from "../bl/photo";
export default {
    data() {
        return { observer: null };
    },
    methods: {
        edit(entity) {
            this.$nav.push("Catch", { catch: this.$store.getters["catch/byid"](entity), imageIndex: entity.index });
        },
        imageUrl(entity) {
            return photo.url(entity.image, "thumb");
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(
            entries => {
                entries.map(({ target, isIntersecting }) => {
                    if (isIntersecting) {
                        const el = target.querySelector(".image");
                        el.style.backgroundImage = `url(${el.dataset.img})`;
                        this.observer.unobserve(target);
                    }
                });
            },
            { root: this.$refs.root }
        );
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    computed: {
        gallery() {
            const { dir, field } = this.$store.state.catch.sort;
            return this.$store.getters["catch/gallery"].sort((a, b) => (field ? (a[field] > b[field] ? 1 : -1) * dir : undefined));
        }
    }
};
</script>