<template>
    <div>
        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <div class="my-5"><canvas ref="canvas"></canvas></div>

        <v-ons-list class="mt-3" modifier="rounded">
            <v-ons-list-header>{{ "report_summary_hpa_catch" | i18n }}</v-ons-list-header>
            <v-ons-list-item>
                <div class="center">{{ "report_min_hpa_catch" | i18n }}</div>
                <div class="right">{{ (summary.minHpaCatch || 0) | number }} hPa</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">{{ "report_med_hpa_catch" | i18n }}</div>
                <div class="right">{{ (summary.medHpaCatch || 0) | number }} hPa</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">{{ "report_max_hpa_catch" | i18n }}</div>
                <div class="right">{{ (summary.maxHpaCatch || 0) | number }} hPa</div>
            </v-ons-list-item>
            <v-ons-list-header>{{ "report_summary_hpa_blank" | i18n }}</v-ons-list-header>
            <v-ons-list-item>
                <div class="center">{{ "report_min_hpa_blank" | i18n }}</div>
                <div class="right">{{ (summary.minHpaBlank || 0) | number }} hPa</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">{{ "report_med_hpa_blank" | i18n }}</div>
                <div class="right">{{ (summary.medHpaBlank || 0) | number }} hPa</div>
            </v-ons-list-item>
            <v-ons-list-item>
                <div class="center">{{ "report_max_hpa_blank" | i18n }}</div>
                <div class="right">{{ (summary.maxHpaBlank || 0) | number }} hPa</div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item @click="showCatches" v-tappable modifier="chevron">{{ periodTitle }}</v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import CatchesByTime, { createDataSet } from "./CatchesByTime";
import { daytime } from "./util";
import { toNumber } from "../util";

const MIN = 900;
const MAX = 1060;
const STEPS = 10;
const STEP_SIZE = (MAX - MIN) / STEPS;
const CHUNKS = Array.from(Array(STEPS)).map((i, index) => MIN + STEP_SIZE * index);
const LABELS = CHUNKS.map(start => `${start}-${start + STEP_SIZE - 1}`);

export default {
    report: {
        index: 400,
        name: "report_catches_by_pressure",
        description: "report_catches_by_pressure_desc",
        premium: true
    },
    extends: CatchesByTime,
    methods: {
        updateData(chart) {
            const summary = {};
            chart.config.data.labels = LABELS;
            chart.data.datasets = [
                createDataSet(
                    this.$i18n.get("catches"),
                    CHUNKS.map(hpa => this.dataSet(this.params.year, hpa, null, summary)),
                    this.getColor("text-primary"),
                    this.getColor("text-primary", 0.25)
                ),
                createDataSet(
                    this.$i18n.get("blanks"),
                    CHUNKS.map(hpa => this.dataSet(this.params.year, hpa, { blank: true }, summary)),
                    this.getColor("text-danger"),
                    this.getColor("text-danger", 0.25)
                )
            ];

            this.summary = summary;
        },
        dataSet(year, hpa, options, summary) {
            const periodStart = daytime(year, null, null);
            const periodEnd = daytime(year, null, null, true);
            return this.$store.getters["catch/all"].filter(item => item.date >= periodStart && item.date <= periodEnd && this.catchFilter(item, options) && toNumber(item.pressure) >= hpa && toNumber(item.pressure) < hpa + STEP_SIZE && this.summarize(item, options, summary))
                .length;
        },
        summarize(item, options, summary) {
            summary.minHpaCatch = summary.minHpaCatch || 9999;
            summary.maxHpaCatch = summary.maxHpaCatch || 0;
            summary.hpaCatch = summary.hpaCatch || [];
            summary.minHpaBlank = summary.minHpaBlank || 9999;
            summary.maxHpaBlank = summary.maxHpaBlank || 0;
            summary.hpaBlank = summary.hpaBlank || [];

            const pressure = toNumber(item.pressure);
            if (item.blank) {
                if (pressure > summary.maxHpaBlank) {
                    summary.maxHpaBlank = pressure;
                }

                if (pressure < summary.minHpaBlank) {
                    summary.minHpaBlank = pressure;
                }

                summary.hpaBlank.push(pressure);
                summary.medHpaBlank = summary.hpaBlank.reduce((sum, curr) => sum + curr, 0) / summary.hpaBlank.length;
            } else {
                if (pressure > summary.maxHpaCatch) {
                    summary.maxHpaCatch = pressure;
                }

                if (pressure < summary.minHpaCatch) {
                    summary.minHpaCatch = pressure;
                }

                summary.hpaCatch.push(pressure);
                summary.medHpaCatch = summary.hpaCatch.reduce((sum, curr) => sum + curr, 0) / summary.hpaCatch.length;
            }

            return true;
        }
    }
};
</script>