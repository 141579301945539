<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "privacy" | i18n }}</div>
        </v-ons-toolbar>

        <div class="content">
            <div class="container-xs">
                <h1>Datenschutzbestimmungen</h1>
                <p>Vielen Dank, dass Sie Fishy verwenden (im Folgenden "Anwendung").</p>
                <p>
                    Ihre Privatsphäre ist uns sehr wichtig und wir nehmen sie ernst. Bitte lesen Sie diese Datenschutzrichtlinie sorgfältig durch, bevor Sie der Anwendung zustimmen, sie herunterladen oder verwenden, und teilen Sie uns mit, wenn Sie Fragen
                    oder sonstiges Feedback dazu haben. Um die Anwendung nutzen zu können, müssen Sie zuerst diese Datenschutzrichtlinie akzeptieren. Durch Klicken auf die Schaltfläche "Akzeptieren" oder Herunterladen oder Verwenden der Anwendung erklären
                    Sie sich mit dieser Vereinbarung einverstanden.
                </p>
                <p>Wenn Sie diese Datenschutzrichtlinie nicht akzeptieren oder nicht akzeptieren können, dürfen Sie die Anwendung nicht verwenden. Laden Sie in diesem Fall die Anwendung nicht herunter oder verwenden Sie sie nicht.</p>

                <h2>1. Analytics</h2>
                <p>
                    Wir arbeiten mit bestimmten Dritten zusammen, um zu verstehen, wie Benutzer mit unserer Anwendung umgehen und wie sie verbessert werden kann. Wenn Sie unsere Anwendung verwenden, sendet Ihr Gerät automatisch bestimmte Informationen an
                    unsere Analysepartner. Zu den gesammelten Informationen gehören beispielsweise die App-Nutzung, der Gerätetyp, die Sitzungslänge, Absturzprotokolle und Interaktionen mit verschiedenen Elementen der Benutzeroberfläche (Schaltflächen) in
                    der Anwendung.
                </p>
                <p>
                    Die gesammelten Analysedaten sind anonym und enthalten keine personenbezogenen Daten. Es werden keine privaten Informationen über Sie oder Ihren Geräteinhalt gesammelt, gespeichert oder verwendet, um einen einzelnen Benutzer zu
                    identifizieren. Die Verwendung von Analysediensten ist wichtig, um die App-Qualität und den Workflow zu verbessern.
                </p>
                <p>Beim ersten Start der App generieren Analytics-Partner möglicherweise eine zufällige eindeutige Kennung, die keine persönlichen Informationen enthält und nicht zum Aufdecken solcher Informationen verwendet werden kann.</p>
                <p>Unsere Analysepartner können Daten in Übereinstimmung mit ihren unten angegebenen Datenschutzrichtlinien sammeln:</p>
                <ul>
                    <li>Google Analytics, Google Firebase und Google Crashlytics (https://policies.google.com/privacy)</li>
                    <li>Amplitude (https://amplitude.com/privacy)</li>
                    <li>Facebook Analytics (https://www.facebook.com/policy.php)</li>
                </ul>
                <p>Wir empfehlen, die obigen Links auf Informationen zu deren Datenschutzrichtlinien zu überprüfen.</p>

                <h2>2. Mobile Identifikatoren</h2>
                <p>
                    Wir nutzen die Dienste von Facebook Analytics, mit denen wir verfolgen und analysieren können, welche Marketingkanäle oder -quellen die besten Ergebnisse erzielen, um Benutzer zum Herunterladen der Anwendung zu verweisen und um zu
                    verstehen, wie unsere Benutzer unsere App verwenden. Zu diesem Zweck verarbeiten Appsflyer und Facebook Analytics mobile Kennungen wie IDFA, GAID oder ähnliche mobile Kennungen. Jede mobile Kennung enthält keine persönlichen
                    Identifikationsinformationen.
                </p>
                <p>Unsere Partner können Daten in Übereinstimmung mit ihren unten angegebenen Datenschutzrichtlinien sammeln:</p>
                <ul>
                    <li>Google Firebase, Admob (https://policies.google.com/privacy)</li>
                    <li>Appsflyer (https://www.appsflyer.com/services-privacy-policy)</li>
                    <li>Facebook Analytics (https://www.facebook.com/policy.php)</li>
                </ul>
                <p>Wir empfehlen, die obigen Links auf Informationen zu deren Datenschutzrichtlinien zu überprüfen.</p>

                <h2>3. Zuverlässigkeit</h2>
                <p>
                    Sie sind dafür verantwortlich, alle wichtigen Daten, die über die Anwendung gespeichert sind oder auf die über die Anwendung zugegriffen wird, auf Ihrem eigenen Computer oder einem anderen Gerät zu sichern (z. B. Standorte, Trolling,
                    Trotlines, Fänge, Fotos, Diagramme). Wir übernehmen keine Garantie oder Gewährleistung dafür, dass Inhalte, die Sie über die Anwendung speichern oder auf die Sie zugreifen, nicht versehentlich beschädigt, beschädigt oder verloren
                    werden.
                </p>

                <h2>4. Backup und persönliche Daten</h2>
                <p>
                    Wenn Sie sich entscheiden, eine Sicherungskopie Ihrer gespeicherten Daten zu erstellen und sich bei Fishy anzumelden, erfassen wir möglicherweise einige personenbezogene Daten von Ihnen, z. B. Ihre E-Mail-Adresse oder UUID. Wenn Sie die
                    Anwendung weiterhin verwenden, laden Sie Ihre Daten regelmäßig auf Ihr Sicherungskonto hoch, z. B. Angelorte (z. B. Standortkoordinaten, Notizen) und Fänge (z. B. Fangfotos, Fangkoordinaten, Gewicht, Länge). Diese Daten hängen von Ihrer
                    Nutzung der App ab.
                </p>
                <p>
                    Sie haben das Recht, den Zugriff auf und die Berichtigung oder Löschung Ihrer persönlichen Daten zu beantragen. Wenn Sie dieses Recht ausüben möchten, kontaktieren Sie uns bitte unter hello@fishyapp.com. Ein Antrag muss schriftlich
                    gestellt und von Ihnen unterschrieben werden. Wir können Ihr gespeichertes Backup mithilfe der gesammelten E-Mail-Adresse oder UUID finden und löschen.
                </p>
                <p>
                    Von Ihnen gesammelte personenbezogene Daten können an Drittanbieter weitergegeben werden, die personenbezogene Daten in unserem Namen verarbeiten. wie Server-Hosting-Anbieter und Datenspeicheranbieter. Manchmal werden einige Ihrer
                    persönlichen Daten von an Verarbeiter übertragen und verarbeitet, wie oben beschrieben, die in den USA oder anderen Ländern (von denen einige außerhalb des EWR liegen) ansässig sind. Wir können Ihre personenbezogenen Daten auch
                    übertragen, falls unser Geschäft (oder ein Teil davon) verkauft wird, beispielsweise im Rahmen einer Fusion oder Akquisition. Wir werden immer Schutzmaßnahmen anwenden, um Ihre persönlichen Daten vor unbefugter Offenlegung zu schützen.
                    Im Falle der Übermittlung von Daten an Dritte außerhalb der EU oder des EWR, die kein angemessenes Datenschutzniveau bieten, wenden wir die Musterklauseln und / oder verbindlichen Unternehmensregeln der EU-Kommission oder ähnliche
                    Instrumente / Mechanismen an. zur Wahrung von Integrität und Sicherheit. Durch die Nutzung unserer Anwendung erkennen Sie an und stimmen zu, dass Ihre persönlichen Daten sowohl innerhalb als auch außerhalb des Europäischen
                    Wirtschaftsraums gehostet und / oder an solche Empfänger übertragen werden dürfen. In keinem Fall werden wir personenbezogene Daten an Dritte weitergeben, die das Recht haben, diese Daten nur in unserem Namen zu verwenden.
                </p>
                <p>
                    Wir können Ihre personenbezogenen Daten weiter offenlegen, um einer gesetzlichen oder behördlichen Verpflichtung nachzukommen, wenn wir der Ansicht sind, dass dies gesetzlich, behördlich oder in anderen Gesetzen vorgeschrieben ist, oder
                    um die Rechte und Interessen unserer Geschäftspartner oder Benutzer zu schützen und zu verteidigen , vorbehaltlich der jeweils geltenden geltenden Vorschriften für personenbezogene Daten.
                </p>

                <h2>5. Sicherheit</h2>
                <p>
                    Wir werden wirtschaftlich angemessene Anstrengungen unternehmen, um die Vertraulichkeit Ihrer Daten zu gewährleisten. Wir können jedoch nicht für den Verlust oder die Offenlegung von Daten oder für Verluste oder Schäden haftbar gemacht
                    werden, die aufgrund von Übertragungsfehlern oder unbefugten oder rechtswidrigen Handlungen Dritter oder Ihrer Entscheidung zur Offenlegung Ihrer persönlichen Daten entstehen.
                </p>
                <p>Kein System kann 100% sicher sein, und trotz unserer Bemühungen besteht immer das Risiko eines unbefugten Zugriffs auf Ihre Daten. Durch die Nutzung unserer Anwendung übernehmen Sie dieses Risiko.</p>

                <h2>6. Änderung oder Ergänzung</h2>
                <p>
                    Das Unternehmen kann die Bestimmungen dieser Datenschutzrichtlinie jederzeit nach eigenem Ermessen ohne vorherige Ankündigung ändern oder ergänzen, indem es eine Kopie der geänderten oder geänderten Datenschutzrichtlinie auf der Website
                    des Unternehmens https://fishyapp.com veröffentlicht. Es wird davon ausgegangen, dass Sie einer solchen Änderung oder Ergänzung durch Ihre Entscheidung, die Anwendung nach dem Datum, an dem die geänderte oder geänderte Vereinbarung auf
                    der Website des Unternehmens veröffentlicht wird, weiter zu verwenden, zugestimmt haben. Sie sind dafür verantwortlich, die Datenschutzrichtlinie regelmäßig zu überprüfen.
                </p>

                <h2>7. Kontaktinformationen</h2>
                <p>Wenn Sie Fragen oder Bedenken zu dieser Datenschutzrichtlinie haben, können Sie uns jederzeit unter unserer E-Mail-Adresse info@fishyapp.com kontaktieren.</p>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {};
</script>