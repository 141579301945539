import moment from "moment";
import { functions } from "../../firebase";

// Constants
const CACHE_RESOLUTION = 50.0;
const CACHE_TIME = 1000 * 60 * 10;
const MOON_PHASES = ["New Moon", "Waxing Crescent Moon", "Quarter Moon", "Waxing Gibbous Moon", "Full Moon", "Waning Gibbous Moon", "Last Quarter Moon", "Waning Crescent Moon"].map(t => t.toLowerCase().replace(/ /g, "_"));

// Cache
const cache = {};

class WeatherManager {
    constructor() {
        this.moonPhases = MOON_PHASES;
    }

    init() {
        // Load first center weather to be more responsive
        this.$store.subscribe(mutation => {
            if (!this.initial && mutation.type == "center/CENTER" && mutation.payload.lat !== 0 && mutation.payload.lng !== 0) {
                this.initial = true;
                this.getWeather(mutation.payload);
            }
        });
    }

    getWeather(location) {
        // Init
        const now = new Date().getTime();
        const lon = Math.round((parseFloat(location.lng) * CACHE_RESOLUTION) / 2);
        const lat = Math.round(parseFloat(location.lat) * CACHE_RESOLUTION);

        // Estimate cache key
        const key = `weather_${lon}_${lat}`;
        const cached = cache[key];

        // Load or use cached version?
        if (!cached || cached.timestamp + CACHE_TIME < now) {
            return functions.weather({ query: { lat: location.lat, lon: location.lng } }).then(
                res => {
                    cache[key] = res.data;
                    cache[key].timestamp = now;
                    return res.data;
                },
                () => ({ name: "N/A" })
            );
        } else {
            return new Promise(resolve => resolve(cached));
        }
    }

    getMoonPhase(date) {
        let c, e, jd, b;
        let m = moment(date);
        let month = m.month();
        let year = m.year();
        let day = m.day();

        if (month < 3) {
            year--;
            month += 12;
        }

        ++month;

        c = 365.25 * year;
        e = 30.6 * month;
        jd = c + e + day - 694039.09; //jd is total days elapsed
        jd /= 29.5305882; //divide by the moon cycle
        b = parseInt(jd); //int(jd) -> b, take integer part of jd
        jd -= b; //subtract integer part to leave fractional part of original jd
        b = Math.round(jd * 8); //scale fraction from 0-8 and round
        if (b >= 8) {
            b = 0; //0 and 8 are the same so turn 8 into 0
        }

        // See: MOON_PHASES
        return b;
    }
}

export default new WeatherManager();
