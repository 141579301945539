import Vue from "vue";

// Constants
const COLORS = {
    default: "rgba(255, 255, 255, 0.05)",
    "theme-dark.css": "rgba(255, 255, 255, 0.05)",
    "theme-light.css": "rgba(0, 0, 0, 0.05)"
};

// Query theme css link
const theme = document.querySelector("#theme");

Vue.directive("tappable", {
    bind(el) {
        el.setAttribute("tappable", "");
        // THEME el.setAttribute("tap-background-color", COLORS[theme.getAttribute("href")] || COLORS.default);
        el.setAttribute("tap-background-color", COLORS.default);
    }
});
