<template>
    <div class="map" @drag.stop>
        <div ref="map" style="height:100%"></div>
        <slot v-if="ready"></slot>
    </div>
</template>

<script>
import leaflet from "leaflet";
import { clone } from "../../util";
import { mapmodeUrls, maxZoom } from "../../constants";

function bounds(bounds) {
    return clone({ min: bounds.getSouthWest(), max: bounds.getNorthEast() });
}

function location(loc) {
    return { lng: loc.lng, lat: loc.lat };
}

export default {
    name: "Map",
    props: {
        zoom: Number,
        center: {
            lng: Number,
            lat: Number
        }
    },
    provide() {
        const that = this;
        return {
            get map() {
                return that.map;
            }
        };
    },
    data() {
        return { ready: false };
    },
    created() {
        this.map = null;
    },
    beforeDestroy() {
        this.$event.off("page:pop", this.resize);
        if (this.map) {
            this.map.remove();
        }
    },
    mounted() {
        // Create map
        this.map = leaflet.map(this.$refs.map, { attributionControl: false, zoomControl: false, maxZoom });

        // Add event listeners
        this.map.on("load", () => (this.ready = true));
        this.map.on("zoomend", () => this.$emit("update:zoom", this.map.getZoom()));
        this.map.on("zoomend", () => this.$emit("update:bounds", bounds(this.map.getBounds())));
        this.map.on("moveend", () => this.$emit("update:bounds", bounds(this.map.getBounds())));
        this.map.on("moveend", () => this.$emit("update:center", location(this.map.getCenter())));
        Object.keys(this.$listeners).forEach(eventName => this.map.on(eventName, this.delegateEvent));

        // Add map
        this.map.setView({ lon: this.center.lng, lat: this.center.lat }, this.zoom || 15);

        // Emit initial bounds event
        this.$emit("update:bounds", bounds(this.map.getBounds()));
        this.$emit("update:center", location(this.map.getCenter()));

        // Add tile layer
        this.initTilemap();

        // Redraw on page pop
        this.$event.on("page:pop", this.resize);
    },
    methods: {
        delegateEvent(e) {
            this.$emit(e.type, e);
        },
        resize() {
            this.map && this.map.invalidateSize();
        },
        flyTo(center, zoom, options) {
            this.map.flyTo([center.lat, center.lng], zoom || this.map.getMaxZoom(), options);
        },
        goTo(center, zoom, options) {
            this.map.setView([center.lat, center.lng], zoom || this.map.getMaxZoom(), options);
        },
        initTilemap() {
            if (this.map) {
                if (this.tilemap) {
                    this.tilemap.remove();
                }

                // this.tilemap = leaflet.tileLayer(mapmodeUrls[this.mapmode || "dark"]).addTo(this.map);
                this.tilemap = leaflet.tileLayer(mapmodeUrls.dark, { maxZoom }).addTo(this.map);
            }
        }
    },
    computed: {
        mapmode() {
            return this.$store.state.ui.mapmode;
        }
    },
    watch: {
        mapmode() {
            this.initTilemap();
        }
    }
};
</script>
