const FIELDS = ["type", "title", "state", "price", "owned", "valid", "canPurchase", "description"];
const VALID_STATES = ["valid", "owned", "registered"];
const MAX_LOG_ENTRIES = 100;

export default {
    namespaced: true,
    info: {
        sync: "premium"
    },
    state: {
        logs: [],
        entities: []
    },
    getters: {
        logs: state => state.logs,
        all: state => state.entities,
        ready: state => state.entities.length > 0 && state.entities.filter(entity => entity.valid && entity.price && VALID_STATES.includes(entity.state)).length == state.entities.length,
        subscribed: state => !!state.entities.find(entity => entity.owned === true),
        byid(state) {
            return spec => state.entities.find(entity => entity.id == (spec.id || spec));
        }
    },
    actions: {
        update: ({ commit }, entity) => {
            commit("MERGE", entity);
            commit("STORE");
        },
        restore: ({ commit }, data) => {
            commit("SET", (data && data.entities) || []);
        },
        log: ({ commit }, entry) => {
            commit("LOG", entry);
        }
    },
    mutations: {
        LOG(state, entry) {
            const logs = ((state && state.logs) || []).filter((item, index, list) => index >= list.length - MAX_LOG_ENTRIES);
            state.logs = [...logs, { ...(entry || {}) }];
        },
        SET(state, entities) {
            state.entities = entities;
        },
        MERGE(state, entity) {
            const exists = !!state.entities.find(current => current.id == entity.id);
            if (exists) {
                state.entities = state.entities.map(current => {
                    if (current.id == entity.id) {
                        current = { ...current };
                        FIELDS.forEach(field => {
                            if (entity[field] !== null && entity[field] !== undefined && entity[field] !== "") {
                                current[field] = entity[field];
                            }
                        });
                    }

                    return current;
                });
            } else {
                state.entities.push(entity);
            }
        },
        REMOVE(state, entity) {
            state.entities = state.entities.filter(current => current.id !== (entity.id || entity));
        },
        ADD(state, entity) {
            state.entities.push(entity);
        },
        STORE(state) {}
    }
};
