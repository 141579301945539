// @ts-check
import short from "short-uuid";

class BaitManager {
    create() {
        return Promise.resolve({
            id: short.generate(),
            name: "",
            size: "",
            food: "",
            description: "",
            weight: 1.0,
            date: new Date().getTime()
        });
    }
}

export default new BaitManager();
