<template>
    <v-ons-splitter>
        <v-ons-splitter-side :open="open" animation="reveal" collapse @postclose="postclose" @postopen="postopen" width="260px">
            <PagesSideNav></PagesSideNav>
        </v-ons-splitter-side>
        <v-ons-splitter-content>
            <v-ons-navigator :page-stack="stack" @postpop="postpop" swipeable swipe-target-width="100"></v-ons-navigator>
        </v-ons-splitter-content>
    </v-ons-splitter>
</template>

<script>
import analytics from "./bl/analytics";
export default {
    data() {
        return {
            open: false,
            stack: this.$nav.stack
        };
    },
    mounted() {
        this.$event.on("menu:toggle", () => (this.open = !this.open));
    },
    methods: {
        postclose() {
            this.open = false;
        },
        postopen() {
            this.open = true;
        },
        postpop() {
            this.$event.emit("page:pop");

            // React to nav pop
            if (this.$nav.stack && this.$nav.stack.length > 0) {
                const next = this.$nav.stack[this.$nav.stack.length - 1];

                // Analytics
                analytics.screen(next && next.extends && next.extends.name);
            }
        }
    }
};
</script>