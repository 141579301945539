// @ts-check
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueOnsen from "vue-onsenui";
import App from "./App";
import "./ext/*.js";
import "./directives/*.js";
import $magic from "./magic";
import { capitalize } from "./util";
import packageJson from "../../package.json";
import componentSpec from "./**/*/*.vue";
import storeSpecs from "./store/*.js";
import plugins from "./plugins/*.js";
import logics from "./bl/*.js";
import i18n from "./bl/i18n";
import geoManager from "./bl/geo";
import userManager from "./bl/user";
import storeManager from "./bl/store";
import premiumManager from "./bl/premium";
import analyticsManager from "./bl/analytics";
import magnetometer from "./bl/magnetometer";
import { appBaseUrl } from "./constants";
import { auth } from "../firebase";

// Inject style
let stylesheetsLoaded = 0;
const stylesheets = ["/onsenui-core.css", "/theme-dark.css", "/index.css"];
stylesheets.forEach(href => {
    const style = document.createElement("link");
    style.setAttribute("rel", "stylesheet");
    style.setAttribute("href", appBaseUrl + href);
    style.addEventListener("load", e => stylesheetsLoaded++);
    document.head.append(style);
});

// Load and register Vue components
const components = {};
$magic(componentSpec, (item, name, path) => {
    if (item.render || (item.default && item.default.render)) {
        item = item.default || item;
        if (path.includes("components")) {
            path = path.filter(item => item !== "components");
        }

        name = item.name || path.map(entry => capitalize(entry)).join("");
        Vue.component(name, item);
        components[name] = item;
        return false;
    }
});

// Load plugins
Vue.use(VueOnsen);
$magic(plugins, plugin => plugin.install && Vue.use(plugin, { components }));

// Init store using modules
const modules = $magic(
    storeSpecs,
    (item, name, path, result) => {
        if (item.default) {
            result[name] = item.default;
        }
    },
    {}
);

Vue.use(Vuex);
const store = new Vuex.Store({ modules });

// Init modules
Object.entries(modules).forEach(([name, item]) => {
    if (item.actions && item.actions.init) {
        store.dispatch(`${name}/init`);
    }
});

// Inject store into business logics
Object.values(logics).forEach(bl => (bl.default.$store = store));

// Init business logic
Object.values(logics).forEach(bl => {
    if (bl.default.init) {
        bl.default.init();
    }
});

// Init
function init() {
    // Log version
    console.log("Version:", packageJson.version);

    // Init onsen
    const ons = VueOnsen._ons;
    ons.disableAutoStyling();
    if (ons.platform.isIPhoneX() || ons.platform.isIPad()) {
        ons.orientation.on("change", e => applyOrtientationFlags(e.isPortrait));
        applyOrtientationFlags(window.innerHeight > window.innerWidth);
    }

    // Init locale
    Vue.prototype.$i18n.setLocale(new URL(window.location.href).searchParams.get("locale") || store.state.ui.locale);

    // Start locating
    geoManager.start();

    // Start magnetometer
    magnetometer.start();

    // Create Vue
    let app = null;
    auth.onAuthStateChanged(async user => {
        // Apply state to user store
        if (user && user.uid) {
            // Update user
            await store.dispatch("user/set", { id: user.uid, email: user.email, displayName: user.displayName, photoUrl: user.photoURL });

            // Restore
            await storeManager.restoreAll();

            // Start premium manager
            await premiumManager.start();

            // Analytics
            analyticsManager.login();
        } else {
            // Stop premium manager
            await premiumManager.stop();

            // Unset user
            await store.dispatch("user/unset");
        }

        // App already created
        if (app) {
            return;
        }

        // Start
        Vue.prototype.$nav.replace(user ? "Start" : "Login");

        // Start app
        app = new Vue({
            store,
            el: "#app",
            render: h => h(App)
        });
    });
}

// Check version
async function checkVersion() {
    try {
        // Check version
        const versionRes = await axios.get(appBaseUrl + "/version.json?t=" + new Date().getTime());
        if (versionRes && versionRes.data && versionRes.data.version && versionRes.data.version != packageJson.version && confirm(i18n.get("software_update_available"))) {
            window.location.reload();
            return false;
        }
    } catch (err) {
        // Nothing
    }

    return true;
}

// Resume
async function resume() {
    // Start locating
    geoManager.start();

    // Start magnetometer
    magnetometer.start();

    // First, check version
    const ok = await checkVersion();

    // Restore changes
    if (ok && userManager.isLoggedIn()) {
        // Restore
        await storeManager.restoreAll();

        // Start premium manager
        await premiumManager.start();
    }
}

// Pause
async function pause() {
    // Stop locating
    geoManager.stop();

    // Stop magnetometer
    magnetometer.stop();

    // Commit changes
    if (userManager.isLoggedIn()) {
        // Start premium manager
        await premiumManager.stop();

        // Commit changes
        await storeManager.commit();
    }
}

// Postulate to window
window.__pause = pause;
window.__resume = resume;

// Apply orientation flags
function applyOrtientationFlags(portrait) {
    if (portrait) {
        document.documentElement.setAttribute("onsflag-iphonex-portrait", "");
        document.documentElement.removeAttribute("onsflag-iphonex-landscape");
    } else {
        document.documentElement.setAttribute("onsflag-iphonex-landscape", "");
        document.documentElement.removeAttribute("onsflag-iphonex-portrait");
    }
}

// Bootup, make sure all stylesheets are fully loaded
function bootup() {
    if (stylesheetsLoaded != stylesheets.length) {
        setTimeout(bootup, 100);
    } else {
        init();
    }
}

// Start
if (window.cordova) {
    document.addEventListener("deviceready", bootup, false);
    document.addEventListener("pause", pause, false);
    document.addEventListener("resume", resume, false);
} else {
    setTimeout(bootup, 250);
}
