import { analytics } from "../../firebase";

class AnalyticsManager {
    login() {
        analytics.logEvent("login");
    }

    screen(page, props) {
        // Build path
        let path = "/" + page.toLowerCase();
        if (props && props.report) {
            path += "?report=" + props.report.name;
        }

        // Log event
        analytics.logEvent("page_view", {
            page_path: path,
            page_title: page
        });
    }
}

export default new AnalyticsManager();
