export function capitalize(str) {
    if (typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function match(obj, check) {
    let count = 0;
    const num = Object.keys(check).length;
    for (let [key, val] of Object.entries(check)) {
        if (val == obj[key] && ++count == num) {
            return true;
        }
    }

    return false;
}

export function throttle(callback, delay) {
    let isThrottled = false,
        args,
        context;

    function wrapper() {
        if (isThrottled) {
            args = arguments;
            context = this;
            return;
        }

        isThrottled = true;
        callback.apply(this, arguments);

        setTimeout(() => {
            isThrottled = false;
            if (args) {
                wrapper.apply(context, args);
                args = context = null;
            }
        }, delay);
    }

    return wrapper;
}

export function debounce(func, wait, immediate) {
    var timeout, args, context, timestamp, result;
    if (null == wait) wait = 100;

    function later() {
        var last = Date.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                context = args = null;
            }
        }
    }

    var debounced = function() {
        context = this;
        args = arguments;
        timestamp = Date.now();
        var callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };

    debounced.clear = function() {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    debounced.flush = function() {
        if (timeout) {
            result = func.apply(context, args);
            context = args = null;

            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debounced;
}

export function degToRad(deg) {
    return (deg * Math.PI) / 180.0;
}

export function dist(loc1, loc2) {
    let lng1 = loc1.lng;
    let lat1 = loc1.lat;
    let lng2 = loc2.lng;
    let lat2 = loc2.lat;
    const earth = 6371000.0;
    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lng2 - lng1);
    lat1 = degToRad(lat1);
    lat2 = degToRad(lat2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earth * c;
}

export function clone(obj) {
    if (obj === null) {
        return null;
    } else if (obj === undefined) {
        return undefined;
    }

    return JSON.parse(JSON.stringify(obj));
}

export function toNumber(val) {
    val = parseFloat(val);
    return Number.isFinite(val) ? val : 0.0;
}

/** PRICING */
const VALID_CHARS = "1234567890.";
export function parsePrice(string) {
    let result = "";
    for (let i = 0; i < string.length; i++) {
        const chr = string.charAt(i);
        if (VALID_CHARS.indexOf(chr) >= 0) {
            result = result + chr;
        } else if (chr == ",") {
            result = result + ".";
        }
    }

    return parseFloat(result);
}

/** UNIT */
const unitMap = {
    m: "ft",
    cm: "inch",
    kg: "lbs"
};

const unitConvert = {
    m: 3.28984,
    cm: 0.393701,
    kg: 2.20462
};

function formatNumber(
    value,
    options = {
        digits: 2
    }
) {
    return parseFloat(Number(value).toFixed(options.digits));
}

export function unit(value, unit, options) {
    // Simply convert given unit (from value)
    if (unit === undefined) {
        return window.$ui.som == "imperial" ? unitMap[value] : value;
    }

    // Convert to imperial?
    let ext = null;
    if (!options || options.ext !== false) {
        ext = window.$ui.som == "imperial" ? unitMap[unit] : unit;
    }

    // Calc result
    let result = unitTo(value, unit);
    if (ext) {
        if (window.$ui.som == "imperial") {
            if (unit == "m" && result > 3) {
                result = result / 3.0;
                ext = "yd";
                if (result > 1760) {
                    result = result / 1760;
                    ext = "mi";
                }
            }
        } else {
            if (unit == "m" && result > 1000) {
                result = result / 1000.0;
                ext = "km";
            }
        }
    }

    return [formatNumber(result, options), ext].filter(Boolean).join(" ");
}

export function unitTo(value, unit) {
    return window.$ui.som == "imperial" ? value * unitConvert[unit] : value;
}

export function unitFrom(value, unit) {
    return window.$ui.som == "imperial" ? value / unitConvert[unit] : value;
}
