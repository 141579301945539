<template>
    <v-ons-page>
        <v-ons-toolbar modifier="spread">
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">
                <v-ons-search-input v-model="q" class="mt-2"></v-ons-search-input>
            </div>
        </v-ons-toolbar>
        <div class="content">
            <p v-if="working" class="text-center text-muted"><v-ons-icon icon="spinner" spin></v-ons-icon></p>
            <v-ons-list v-for="item in items" :key="item.name">
                <v-ons-list-item @click="select(item)" v-tappable>
                    <ListItemIcon icon="map-marker"></ListItemIcon>
                    <div class="center">
                        <span class="list-item__title">{{ item.name }}</span>
                        <span class="list-item__subtitle">{{ item.lng }}, {{ item.lat }}</span>
                    </div>
                </v-ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>
import geo from "../bl/geo";
import { debounce } from "../util";
export default {
    props: ["select"],
    data() {
        return { q: "", items: [], working: false };
    },
    mounted() {
        setTimeout(() => this.$el.querySelector("input").focus(), 500);

        // Debounce search query
        this.queryGeocoding = debounce(function() {
            this.items = [];
            if (this.q) {
                this.working = true;
                geo.forward(this.q).then(
                    res => {
                        this.items = res;
                        this.working = false;
                    },
                    err => {
                        this.working = false;
                    }
                );
            }
        }, 1000);
    },
    watch: {
        q() {
            this.queryGeocoding();
        }
    }
};
</script>