import stores from "../store/*.js";
import { collections, auth } from "../../firebase";

// Constants
const syncedStores = Object.values(stores)
    .map(store => store && store.default && store.default.info && store.default.info.sync)
    .filter(Boolean);
const syncTriggers = syncedStores.map(item => item + "/STORE");

class StoreManager {
    constructor() {
        this.dirty = false;
        setInterval(() => this.commit(), 2000);
    }

    init() {
        this.$store.subscribe(mutation => {
            if (mutation.type == "user/STORE") {
                this.dirty = false;
            } else if (syncTriggers.includes(mutation.type)) {
                this.dirty = true;
                console.log("DIRTY");
            }
        });
    }

    async save(id, data) {
        const item = { data: JSON.stringify(data), updated: new Date().getTime() };
        await collections[id].doc(auth.currentUser.uid).set(item);
    }

    async load(id) {
        const doc = await collections[id].doc(auth.currentUser.uid).get();
        const data = await doc.data();
        return { data: data && data.data ? JSON.parse(data.data) : null };
    }

    store(store) {
        return this.save(store, this.$store.state[store]);
    }

    restore(store) {
        return this.load(store).then(
            res => this.$store.dispatch(store + "/restore", res.data),
            err => console.log(err)
        );
    }

    storeAll() {
        this.dirty = false;
        console.log("STORE", this.$store.state.user.email, `(${syncedStores.join(", ")})`);
        return Promise.all(syncedStores.map(store => this.store(store)));
    }

    restoreAll() {
        this.dirty = false;
        console.log("RESTORE", this.$store.state.user.email, `(${syncedStores.join(", ")})`);
        return Promise.all(syncedStores.map(store => this.restore(store)));
    }

    commit() {
        if (this.dirty) {
            return this.storeAll();
        }
    }
}

export default new StoreManager();
