Array.prototype.chunk = function(size = 20) {
    return Array(Math.ceil(this.length / size))
        .fill()
        .map((_, index) => index * size)
        .map(begin => this.slice(begin, begin + size));
};

Array.prototype.toggle = function(item, value) {
    if (item !== undefined) {
        if (value !== undefined) {
            const contains = this.includes(item);
            if (!value && contains) {
                this.delete(item);
            } else if (value && !contains) {
                this.push(item);
            }
        } else {
            if (this.includes(item)) {
                this.delete(item);
            } else {
                this.push(item);
            }
        }
    }

    return this;
};

Array.prototype.delete = function(item) {
    if (item !== undefined) {
        const index = this.indexOf(item);
        if (index >= 0) {
            this.splice(index, 1);
        }
    }

    return this;
};

function uniq(value, index, self) {
    return self.indexOf(value) === index;
}

Array.prototype.uniq = function() {
    return this.filter(uniq);
}