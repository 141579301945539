<template>
    <div class="compass-bar">
        <div ref="bar" class="bar">
            <CompassBar class="l"></CompassBar>
            <CompassBar class="m"></CompassBar>
            <CompassBar class="r"></CompassBar>
        </div>
        <svg class="north" width="10" height="10">
            <path d="M0,10h10l-5,-10" style="fill:#ff3d71;" />
        </svg>
    </div>
</template>

<script>
export default {
    props: ["angle"],
    mounted() {
        this.listener = angle => this.setAngle(angle);
        this.$event.on("magnetometer:angle", this.listener);
    },
    beforeDestroy() {
        this.$event.off("magnetometer:angle", this.listener);
    },
    methods: {
        setAngle(angle) {
            this.$emit("update:angle", angle);
            this.$refs.bar.style.transform = `translateX(${(400 * angle) / 180.0}px)`;
        }
    }
};
</script>

<style>
.compass-bar {
    top: 0;
    right: 0;
    left: 50%;
    width: 300px;
    margin: 5px 0 0 -150px;
    height: 50px;
    z-index: 1000;
    overflow: hidden;
    position: absolute;
    border-radius: 5px;
    backdrop-filter: blur(10px);
    background: rgba(33, 37, 41, 0.65);
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.compass-bar .bar {
    top: 12px;
    left: 50%;
    position: absolute;
}

.compass-bar svg {
    width: 800px;
    position: absolute;
}

.compass-bar svg.m {
    left: -400px;
}

.compass-bar svg.l {
    left: -1200px;
}

.compass-bar svg.r {
    left: 400px;
}

.compass-bar .north {
    top: 35px;
    left: 50%;
    margin-left: -5px;
}
</style>
