<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "icon_choose" | i18n }}</div>
        </v-ons-toolbar>
        <div class="content">
            <div class="icon-gallery">
                <a @click="select(icon)" v-for="icon in icons" :key="icon" :class="{ selected: selected == icon }" class="icon-gallery-item">
                    <i class="fa fa-fw fa-2x" :class="'fa-' + icon"></i>
                </a>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
const icons = [
    "map-marker",
    "fire-alt",
    "crosshairs",
    "anchor",
    "arrow-down",
    "bell",
    "beer",
    "bullhorn",
    "bullseye",
    "child",
    "cloud",
    "compass",
    "cutlery",
    "dot-circle-o",
    "exclamation-circle",
    "exclamation-triangle",
    "eye",
    "flag",
    "flag-o",
    "fire",
    "flash",
    "group",
    "info",
    "info-circle",
    "map",
    "map-o",
    "map-pin",
    "map-signs",
    "photo",
    "paw",
    "tint",
    "warning",
    "wifi",
    "umbrella"
];
export default {
    props: ["selected", "select"],
    data() {
        return { icons };
    }
};
</script>