<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "language_choose" | i18n }}</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item v-for="locale in locales" :key="locale" v-tappable @click="select(locale)">
                <div class="left">
                    <LocaleFlag :locale="locale" width="16"></LocaleFlag>
                </div>
                <div class="center">{{ ("locale_" + locale) | i18n }}</div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return {
            locales: ["de", "en", "es", "pl" /* "nl", */]
        };
    },
    methods: {
        select(locale) {
            this.$store.dispatch("ui/locale", locale);
            window.location.reload();
        }
    }
};
</script>