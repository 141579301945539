import short from "short-uuid";
export default {
    namespaced: true,
    info: {
        sync: "notebook"
    },
    state: {
        entities: [],
        sort: {
            field: "updated",
            dir: -1
        }
    },
    getters: {},
    actions: {
        update({ commit }, entity) {
            commit("REMOVE", entity);
            if (!entity.id) {
                entity.id = short.generate();
                entity.created = new Date().getTime();
            }

            entity.updated = new Date().getTime();
            commit("ADD", entity);
            commit("STORE");
        },
        delete: ({ commit }, entity) => {
            commit("REMOVE", entity);
            commit("STORE");
        },
        sort: ({ commit }, field) => {
            commit("SORT", field);
        },
        restore: ({ commit }, data) => {
            commit("SET", (data && data.entities) || []);
        }
    },
    mutations: {
        SET(state, entities) {
            state.entities = entities;
        },
        REMOVE(state, entity) {
            state.entities = state.entities.filter(current => current.id !== (entity.id || entity));
        },
        ADD(state, entity) {
            state.entities = [...state.entities, entity];
        },
        SORT(state, field) {
            if (state.sort.field == field) {
                state.sort.dir *= -1;
            } else {
                state.sort.field = field;
                state.sort.dir = 1;
            }
        },
        STORE(state) {}
    }
};
