// @ts-check
import geo from "../bl/geo";
import weather from "../bl/weather";

class SpotManager {
    create(spottype = "default", position = null) {
        return Promise.resolve(position || geo.getPosition()).then(location => {
            return {
                name: "",
                spottype,
                watersName: "",
                // baits: [],
                icon: null,
                depth: 1.0,
                ...location,
                created: new Date().getTime()
            };
        });
    }

    estimateName(spot) {
        // Estimage nearby spots
        const nearby = this.$store.getters["spot/nearest"](spot);

        // FIXME: Implement own 'location name' logic, instead using weather api
        return weather.getWeather(spot).then(weatherResult => {
            // Init spot name from weather location
            let name = weatherResult.name;

            // Calculate spot name
            if (spot.spottype == "default") {
                const base = nearby.find(item => item.spottype == "base");
                const spots = nearby.filter(item => item.spottype != "base");
                const baseName = (base && base.name) || name;

                // Estimate name
                let i = 0;
                do {
                    name = `${baseName} #${++i}`;
                } while (spots.find(spot => spot.name == name));
            } else {
                const bases = nearby.filter(item => item.spottype == "base");
                if (bases.map(item => item.name).includes(name)) {
                    // Estimate name
                    let i = 0;
                    const baseName = name;
                    do {
                        name = `${baseName} (${++i})`;
                    } while (bases.find(base => base.name == name));
                }
            }

            return name;
        });
    }

    icon(spot) {
        if (spot && spot.icon) {
            return "fa-" + spot.icon;
        }

        return spot && spot.spottype == "base" ? "fa-fire-alt" : "fa-map-marker-alt";
    }
}

export default new SpotManager();
