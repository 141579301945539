<template>
    <v-ons-page>
        <div class="content">
            <div class="container-xs">
                <h3 class="text-center my-5">{{ "reset_password" | i18n }}</h3>

                <div v-if="error" class="alert mb-3">
                    {{ error.message || error }}
                </div>

                <v-ons-list modifier="noborder rounded">
                    <v-ons-list-item>
                        <v-ons-input v-model="model.email" :placeholder="'email' | i18n"></v-ons-input>
                    </v-ons-list-item>
                </v-ons-list>

                <section class="mt-5">
                    <v-ons-button @click="resetpw" :disabled="!valid" modifier="large">{{ "reset_password" | i18n }}</v-ons-button>
                </section>

                <section class="mt-3">
                    <v-ons-button @click="cancel" modifier="quiet large">{{ "cancel" | i18n }}</v-ons-button>
                </section>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
import userManager from "../bl/user";
export default {
    data() {
        return { model: {}, error: null, working: false };
    },
    computed: {
        valid() {
            return !this.working && this.model.email && this.model.email.length > 3;
        }
    },
    methods: {
        resetpw() {
            this.error = null;
            this.working = true;
            userManager.sendresetpw(this.model).then(
                res => {
                    this.$ons.notification.toast(this.$i18n.get("reset_password_success"), { timeout: 1000 });
                    this.$nav.replace("Login");
                },
                err => {
                    this.working = false;
                    this.error = err.message;
                }
            );
        },
        cancel() {
            this.$nav.pop();
        }
    }
};
</script>
