const foods = ["Boilies", "Mais", "Hanf", "Pellets", "Tigernüsse", "Liquid"];
const baits = ["PopUp", "Schneemann", "Wrafter", "Mais", "Tigernuss", "Fakecorn", "SuperSlimy", "Dipp", "Flavour"];
const assemblies = ["Chod Rig", "360 ° Rig", "Line-Aligner Rig", "Stiff Rig (D-Rig)", "Withy Pool Rig", "Ronny Rig", "Combi Rig", "Multi Rig", "No-Knot Rig", "Maggot Rig", "Anti Blow Out Rig", "Adjustable Rig", "PopUp Rig"];

export default {
    namespaced: true,
    state: {
        baits,
        foods,
        assemblies
    },
    actions: {},
    mutations: {}
};
