<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center text-center">
                <v-ons-segment :index="model.blank ? 1 : 0">
                    <button @click="model.blank = false">{{ "catch" | i18n }}</button>
                    <button @click="model.blank = true">{{ "blank" | i18n }}</button>
                </v-ons-segment>
            </div>
            <PremiumPartial feature="catch" :model="model" class="right">
                <v-ons-toolbar-button @click="save">{{ "save" | i18n }}</v-ons-toolbar-button>
            </PremiumPartial>
        </v-ons-toolbar>

        <div class="content">
            <div v-if="!model.blank" :class="{ empty: !model.images.length }" class="photo-carousel">
                <v-ons-carousel :index.sync="galleryIndex" swipeable overscrollable auto-scroll auto-refresh>
                    <v-ons-carousel-item @click="showPhoto(image)" v-for="image in model.images" :key="image" class="photo-carousel-item">
                        <div class="photo-carousel-item-bg" :style="photoCarouselStyle(image)"></div>
                        <div class="photo-carousel-item-photo" :style="photoCarouselStyle(image)"></div>
                    </v-ons-carousel-item>
                </v-ons-carousel>
                <v-ons-fab @click="togglePhotoSource" class="photo-carousel-button">
                    <input v-if="browser" type="file" @click.stop @change="addPhotoFile" accept="image/*" capture="environment" class="invisible" />
                    <v-ons-icon icon="camera"></v-ons-icon>
                </v-ons-fab>
                <div class="photo-carousel-indicator">
                    <div v-for="(image, index) in model.images" :key="index" :class="{ active: index === galleryIndex }" class="photo-carousel-indicator-item"></div>
                </div>
            </div>

            <v-ons-list>
                <v-ons-list-item v-tappable modifier="chevron" @click="chooseSpot">
                    <ListItemIcon icon="map-marker-alt"></ListItemIcon>
                    <div class="center">{{ spotName }}</div>
                </v-ons-list-item>

                <v-ons-list-header v-if="!model.blank">{{ "fish" | i18n }}</v-ons-list-header>
                <v-ons-list-item v-if="!model.blank">
                    <ListItemIcon icon="fish"></ListItemIcon>
                    <v-ons-input v-model="model.fish" type="text" :placeholder="'fish_family' | i18n"></v-ons-input>
                </v-ons-list-item>
                <v-ons-list-item v-if="!model.blank">
                    <ListItemIcon icon="weight"></ListItemIcon>
                    <UnitInput v-model="model.weight" placeholder="weight" unit="kg"></UnitInput>
                    <div class="right">
                        <div class="list-item__label">{{ "kg" | unit }}</div>
                    </div>
                </v-ons-list-item>
                <v-ons-list-item v-if="!model.blank">
                    <ListItemIcon icon="ruler"></ListItemIcon>
                    <UnitInput v-model="model.length" placeholder="length" unit="cm"></UnitInput>
                    <div class="right">
                        <div class="list-item__label">{{ "cm" | unit }}</div>
                    </div>
                </v-ons-list-item>

                <v-ons-list-header>{{ "baits" | i18n }}</v-ons-list-header>
                <BaitItem :entity="bait" @click="editBait(bait)" v-for="bait in model.baits" :key="bait.id" v-tappable></BaitItem>
                <v-ons-list-item v-tappable modifier="chevron" @click="addBait">
                    <ListItemIcon icon="empty"></ListItemIcon>
                    <div class="center">{{ "bait_add" | i18n }}</div>
                </v-ons-list-item>

                <v-ons-list-header>{{ "assembly" | i18n }}</v-ons-list-header>
                <v-ons-list-item>
                    <ListItemIcon icon="sourcetree"></ListItemIcon>
                    <v-ons-input v-model="model.assembly" type="text" :placeholder="'assembly' | i18n"></v-ons-input>
                    <div class="right">
                        <v-ons-button @click="chooseAssembly" modifier="quiet"><v-ons-icon icon="ellipsis-h"></v-ons-icon></v-ons-button>
                    </div>
                </v-ons-list-item>

                <v-ons-list-header>{{ "waters" | i18n }}</v-ons-list-header>
                <v-ons-list-item>
                    <ListItemIcon icon="thermometer-half"></ListItemIcon>
                    <v-ons-input v-model="model.waterTemperature" type="number" :placeholder="'watertemperature' | i18n"></v-ons-input>
                    <div class="right"><div class="list-item__label">°C</div></div>
                </v-ons-list-item>

                <v-ons-list-header>{{ "weather" | i18n }}</v-ons-list-header>
                <v-ons-list-item>
                    <ListItemIcon icon="thermometer-half"></ListItemIcon>
                    <v-ons-input v-model="model.temperature" type="number" :placeholder="'temperature' | i18n"></v-ons-input>
                    <div class="right"><div class="list-item__label">°C</div></div>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="location-arrow"></ListItemIcon>
                    <v-ons-select v-model="model.windDirection">
                        <option></option>
                        <option v-for="dir in windDirections" :key="dir" :value="dir">{{ dir }}</option>
                    </v-ons-select>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="cloud-sun"></ListItemIcon>
                    <v-ons-input v-model="model.weatherCondition" type="text" :placeholder="'weather_condition' | i18n"></v-ons-input>
                    <div class="right">
                        <v-ons-button @click="chooseWeatherCondition" modifier="quiet"><v-ons-icon icon="ellipsis-h"></v-ons-icon></v-ons-button>
                    </div>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="tint"></ListItemIcon>
                    <v-ons-input v-model="model.humidity" type="number" :placeholder="'humidity' | i18n"></v-ons-input>
                    <div class="right"><div class="list-item__label">%</div></div>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="compress"></ListItemIcon>
                    <v-ons-input v-model="model.pressure" type="number" :placeholder="'pressure' | i18n"></v-ons-input>
                    <div class="right"><div class="list-item__label">hPa</div></div>
                </v-ons-list-item>

                <v-ons-list-header>{{ "date" | i18n }}</v-ons-list-header>
                <v-ons-list-item>
                    <ListItemIcon icon="calendar-day"></ListItemIcon>
                    <v-ons-input v-model="date" type="date"></v-ons-input>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="clock"></ListItemIcon>
                    <v-ons-input v-model="time" type="time"></v-ons-input>
                </v-ons-list-item>
                <v-ons-list-item>
                    <ListItemIcon icon="moon"></ListItemIcon>
                    <v-ons-select v-model="model.moonphase">
                        <option></option>
                        <option v-for="(phase, index) in moonphases" :key="index" :value="index">{{ ("moon_" + phase) | i18n }}</option>
                    </v-ons-select>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list v-if="model.id" class="my-5">
                <v-ons-list-item v-tappable @click="remove" class="text-danger">{{ "delete" | i18n }}</v-ons-list-item>
            </v-ons-list>

            <PremiumCheck feature="catch" :model="model"></PremiumCheck>
        </div>
        <v-ons-modal :visible="photoModal !== false" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
            <p>{{ photoModal | i18n }}</p>
        </v-ons-modal>
        <v-ons-action-sheet :visible.sync="choosePhotoSource" cancelable="true">
            <v-ons-action-sheet-button @click="addPhoto()">{{ "photo_take" | i18n }}</v-ons-action-sheet-button>
            <v-ons-action-sheet-button @click="addPhoto('select')">{{ "photo_select" | i18n }}</v-ons-action-sheet-button>
            <v-ons-action-sheet-button @click="togglePhotoSource">{{ "cancel" | i18n }}</v-ons-action-sheet-button>
        </v-ons-action-sheet>
    </v-ons-page>
</template>

<script>
import moment from "moment";
import photo from "../bl/photo";
import weather from "../bl/weather";
import baitManager from "../bl/bait";
import catchManager from "../bl/catch";
import { clone, capitalize } from "../util";
export default {
    props: ["catch", "weather", "imageIndex"],
    data() {
        return {
            galleryIndex: this.imageIndex || 0,
            photoModal: false,
            choosePhotoSource: false,
            model: clone(this.catch),
            date: moment(this.catch.date).format("YYYY-MM-DD"),
            time: moment(this.catch.date).format("HH:mm"),
            windDirections: "N,NO,O,SO,S,SW,W,NW".split(","),
            moonphases: weather.moonPhases
        };
    },
    mounted() {
        if (!this.model.id) {
            // Load weather model for newly created catch
            catchManager.loadWeatherModel(this.model).then(weatherModel => Object.assign(this.model, weatherModel));
        }
    },
    methods: {
        save() {
            this.$nav.pop();
            this.model.date = moment([this.date, this.time].join(" ")).valueOf();
            this.$store.dispatch("catch/update", this.model);
            this.$ons.notification.toast(this.$i18n.get("successfully_saved"), { timeout: 1000 });
        },
        remove() {
            this.$ons.notification.confirm(this.$i18n.get("really_delete?")).then(result => {
                if (result) {
                    this.$nav.pop();
                    this.$store.dispatch("catch/delete", this.model);
                    this.$ons.notification.toast(this.$i18n.get("successfully_deleted"), { timeout: 1000 });
                }
            });
        },
        chooseSpot() {
            this.$nav.push("ChooseSpot", {
                select: spot => {
                    this.model.spot = spot.id;
                    this.$nav.pop();
                }
            });
        },
        chooseAssembly() {
            this.$nav.push("ChooseAssembly", {
                select: assembly => {
                    this.model.assembly = assembly;
                    this.$nav.pop();
                }
            });
        },
        chooseWeatherCondition() {
            this.$nav.push("ChooseWeatherCondition", {
                select: weatherCondition => {
                    this.model.weatherCondition = weatherCondition;
                    this.$nav.pop();
                }
            });
        },
        addPhoto(type) {
            this.choosePhotoSource = false;
            this.photoModal = "photo_taking";
            photo
                .take({ targetWidth: 1024, targetHeight: 1024, sourceType: type == "select" ? 0 : 1 })
                .then(file => {
                    this.photoModal = "photo_uploading";
                    return photo
                        .upload(file)
                        .then(url => this.model.images.push(url))
                        .then(() => this.$nextTick(() => (this.galleryIndex = this.model.images.length - 1)));
                })
                .then(
                    () => (this.photoModal = false),
                    () => (this.photoModal = false)
                );
        },
        addPhotoFile(e) {
            this.photoModal = "photo_uploading";
            return photo.upload(e.target.files[0]).then(
                url => {
                    e.target.value = null;
                    this.photoModal = false;
                    this.model.images.push(url);
                    this.$nextTick(() => (this.galleryIndex = this.model.images.length - 1));
                },
                () => (this.photoModal = false)
            );
        },
        showPhoto(image) {
            this.$nav.push("ShowPhoto", {
                image,
                remove: () => {
                    this.$ons.notification.confirm(this.$i18n.get("really_delete?")).then(result => {
                        if (result) {
                            this.model.images.delete(image);
                            this.$nav.pop();
                        }
                    });
                }
            });
        },
        photoCarouselStyle(image) {
            return {
                backgroundImage: `url(${photo.url(image, "sm")})`
            };
        },
        addBait() {
            baitManager.create().then(bait => this.$nav.push("Bait", { bait, next: this.appendBait }));
        },
        editBait(bait) {
            this.$nav.push("Bait", {
                bait,
                next: result => {
                    if (result) {
                        Object.assign(bait, result);
                    } else {
                        this.model.baits = this.model.baits.filter(item => item.id != bait.id);
                    }
                }
            });
        },
        appendBait(bait) {
            if (bait) {
                this.model.baits.push(bait);
            }
        },
        togglePhotoSource() {
            this.choosePhotoSource = !this.choosePhotoSource;
        }
    },
    computed: {
        spotName() {
            const spot = this.model.spot && this.$store.getters["spot/byid"](this.model.spot);
            return spot ? spot.name : this.$i18n.get("spot_select");
        },
        browser() {
            return !photo.native();
        }
    }
};
</script>