import short from "short-uuid";
export default {
    namespaced: true,
    info: {
        sync: "catch"
    },
    state: {
        entities: [],
        sort: {
            field: "date",
            dir: -1
        }
    },
    getters: {
        all: state => state.entities,
        byid(state) {
            return spec => state.entities.find(entity => entity.id == (spec.id || spec));
        },
        byspot(state) {
            return spot => state.entities.filter(entity => entity.spot == (spot.id || spot));
        },
        gallery(state) {
            return state.entities
                .map(entity => {
                    if (entity.images && entity.images.length > 0) {
                        return entity.images.map((image, index) => {
                            return {
                                image,
                                index,
                                id: entity.id,
                                fish: entity.fish,
                                weight: entity.weight,
                                length: entity.length,
                                key: `${entity.id}-${index}`
                            };
                        });
                    }
                })
                .flat()
                .filter(Boolean);
        }
    },
    actions: {
        update: ({ commit }, entity) => {
            commit("REMOVE", entity);
            if (!entity.id) {
                entity.id = short.generate();
            }

            commit("ADD", entity);
            commit("STORE");
        },
        delete: ({ commit }, entity) => {
            commit("REMOVE", entity);
            commit("STORE");
        },
        reset: ({ commit }) => {
            commit("SET", []);
            commit("STORE");
        },
        sort: ({ commit }, field) => {
            commit("SORT", field);
        },
        restore: ({ commit }, data) => {
            commit("SET", (data && data.entities) || []);
        }
    },
    mutations: {
        SET(state, entites) {
            state.entities = entites;
        },
        REMOVE(state, entity) {
            state.entities = state.entities.filter(current => current.id !== (entity.id || entity));
        },
        ADD(state, entity) {
            state.entities.push(entity);
        },
        SORT(state, field) {
            if (state.sort.field == field) {
                state.sort.dir *= -1;
            } else {
                state.sort.field = field;
                state.sort.dir = 1;
            }
        },
        STORE(state) {}
    }
};
