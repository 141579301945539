<template>
    <img :src="image" class="rounded-circle"/>
</template>

<script>
export default {
    props: ["locale"],
    computed: {
        image() {
            return `img/flags/${this.locale}.png`;
        }
    }
};
</script>
