<template>
    <v-ons-list-item @click="$emit('click')">
        <ListItemIcon icon="link"></ListItemIcon>
        <div class="center">
            <div class="list-item__title">{{ entity.name }}</div>
            <!--
            <div class="list-item__subtitle">{{ entity.date | date("sm") }}</div>-->
        </div>
        <div class="right">
            <div class="list-item__label nowrap">
                <span v-if="entity.food">{{ entity.food }} - </span>{{ entity.weight | unit("kg") }}
            </div>
        </div>
    </v-ons-list-item>
</template>

<script>
export default {
    props: ["entity"]
};
</script>