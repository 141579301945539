<template>
    <div class="left">
        <v-ons-icon fixed-width :icon="icon" class="list-item__icon text-muted"></v-ons-icon>
    </div>
</template>

<script>
export default {
    name: "ListItemIcon",
    props: ["icon"]
};
</script>