// @ts-check
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import { firebaseConfig } from "../config/system";

// Init firebase
firebase.initializeApp(firebaseConfig);

// Init services
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();

// Store collections
const collections = {
    spot: db.collection("spots"),
    task: db.collection("tasks"),
    catch: db.collection("catches"),
    premium: db.collection("premiums"),
    notebook: db.collection("notebooks")
};

// Init functions
const functions = {
    weather: firebase.functions().httpsCallable("weather"),
    geocoding: firebase.functions().httpsCallable("geocoding")
};

// Export services
export { db, auth, storage, functions, collections, firebaseConfig, analytics };
