// @ts-check
import { functions } from "../../firebase";

class GeoManager {
    constructor() {
        this.watch = null;
    }

    start() {
        if (!!this.watch) {
            this.stop();
        }

        // Overwrite tracking?
        const url = new window.URL(location.href);
        const positionOverwrite = { lat: +url.searchParams.get("lat"), lng: +url.searchParams.get("lng") };
        if (positionOverwrite.lat !== 0 && positionOverwrite.lng !== 0) {
            this.$store.dispatch("location/update", positionOverwrite);
            return;
        }

        this.watch = window.navigator.geolocation.watchPosition(
            pos => {
                const position = { lng: pos.coords.longitude, lat: pos.coords.latitude };
                this.$store.dispatch("location/update", position);
            },
            err => console.log(err),
            { enableHighAccuracy: true }
        );
    }

    stop() {
        if (this.watch) {
            window.navigator.geolocation.clearWatch(this.watch);
            this.watch = null;
        }
    }

    getPosition() {
        // return Promise.resolve({ lat: 51.323818942864364, lng: 7.328192982805945 });
        /*
        return new Promise((resolve, reject) => {
            window.navigator.geolocation.getCurrentPosition(
                result => resolve({ lng: result.coords.longitude, lat: result.coords.latitude }),
                err => reject(err)
            );
        }); */
        return Promise.resolve(this.$store.state.location);
    }

    forward(q) {
        return functions.geocoding({ query: q }).then(res => res.data);
    }
}

export default new GeoManager();
