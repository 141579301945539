// @ts-check
import { auth } from "../../firebase";

class LoginManager {
    isLoggedIn() {
        return this.me() != null;
    }

    async login(model) {
        await auth.setPersistence("local");
        return auth.signInWithEmailAndPassword(model.email, model.password);
    }

    me() {
        return auth.currentUser;
    }

    async logout() {
        await auth.setPersistence("local");
        return auth.signOut();
    }

    async update(model) {
        await auth.currentUser.updateProfile({ photoURL: model.photoUrl, displayName: model.displayName });
        return this.$store.dispatch("user/set", model);
    }

    async register(model) {
        const email = model.email.trim();
        await auth.setPersistence("local");
        return auth.createUserWithEmailAndPassword(email, model.password);
    }

    sendresetpw(model) {
        return auth.sendPasswordResetEmail(model.email);
    }
}

export default new LoginManager();
