import moment from "moment";
import short from "short-uuid";
import faker from "../app/bl/faker";
import weather from "../app/bl/weather";
import lib from "../app/store/lib";

const windDirections = "N,NO,O,SO,S,SW,W,NW".split(",");
const weatcherConditions = "Rain,Clear,Snow,Storm,Hail,Graupel".split(",");

function randid() {
    return short.generate();
}

function randf(min, max, decimals = 0) {
    const f = Math.random() * (max - min) + min;
    if (decimals > 0) {
        return parseFloat(f.toFixed(decimals));
    }

    return f;
}

function randi(min, max) {
    return Math.floor(randf(min, max));
}

function randa(array) {
    return array[randi(0, array.length - 1)];
}

function range(num = 10) {
    return Array.from(Array(num).keys());
}

function randt() {
    return moment()
        .year(randi(2016, 2020))
        .month(randi(0, 12))
        .date(randi(0, 28))
        .valueOf();
}

function randp() {
    return `https://randomuser.me/api/portraits/men/${randi(0, 100)}.jpg`;
}

function randl(lng = 7.3292597, lat = 51.292425, variance = 1) {
    return {
        lng: randf(lng - variance, lng + variance),
        lat: randf(lat - variance, lat + variance)
    };
}

function genSpot(base) {
    return {
        id: randid(),
        name: `${base.name} #${randi(1, 1000)}`,
        watersName: `${base.name} Waters`,
        depth: randf(1, 20),
        spottype: "default",
        created: randt(),
        // baits: range(randi(0, 3)).map(genBait),
        ...randl(base.lng, base.lat, 0.001)
    };
}

function genBait() {
    return {
        id: randid(),
        name: randa(lib.state.baits),
        food: randa(lib.state.foods),
        date: randt(),
        size: "",
        weight: randf(1, 10, 1)
    };
}

function genBase() {
    return {
        id: randid(),
        name: faker.name(),
        spottype: "base",
        created: randt(),
        ...randl()
    };
}

function genCatch(spot) {
    return {
        id: randid(),
        images: range(randi(0, 3)).map(() => randp()),
        blank: randi(0, 10) > 7,
        spot: spot.id,
        date: randt(),
        fish: faker.name().split(" ").pop(),
        weight: randf(0.1, 15, 1),
        length: randi(5, 300),
        baits: range(randi(0, 3)).map(genBait),
        assembly: randa(lib.state.assemblies),
        temperature: randi(-30, 50),
        windDirection: randa(windDirections),
        weatherCondition: randa(weatcherConditions),
        waterTemperature: randi(-10, 20),
        humidity: randi(0, 100),
        pressure: randf(900, 1060),
        moonphase: randi(0, weather.moonPhases.length)
    };
}

function genSpots(numBases = 10) {
    return range(numBases)
        .map(() => genBase())
        .map(base => [base, range(randi(3, 7)).map(() => genSpot(base))].flat())
        .flat();
}

export function gen() {
    const spots = genSpots(10);
    const catches = spots
        .filter(spot => spot.spottype == "default")
        .map(spot => range(randi(0, 5)).map(() => genCatch(spot)))
        .flat();

    return { spots, catches };
}
