import label from "../i18n/*.json";

class I18nManager {
    constructor() {
        this.locale = "de";
    }

    get(key, args) {
        let value = (label[this.locale] && label[this.locale][key]) || label.en[key] || key;
        if (args && args.length > 0) {
            for (let i = 0; i < args.length; i++) {
                value = value.replace(new RegExp(`\\{${i}\\}`, "g"), args[i]);
            }
        }

        return value;
    }

    setLocale(locale) {
        this.locale = locale;
    }
}

export default new I18nManager();
