<template>
    <div>
        <div ref="marker" :class="anchor" @touchmove.prevent class="marker">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import leaflet from "leaflet";
export default {
    props: ["coords", "anchor", "draggable"],
    inject: ["map"],
    created() {
        this.marker = null;
    },
    mounted() {
        // Init
        const draggable = this.draggable !== undefined;

        // Create marker
        const icon = L.divIcon({ html: this.$refs.marker, iconSize: [0, 0] });
        this.marker = leaflet.marker([this.coords.lat, this.coords.lng], { icon, draggable }).addTo(this.map);

        // Init drag
        if (draggable) {
            this.marker.on("moveend", e => {
                const current = e.target.getLatLng();
                this.coords.lng = current.lng;
                this.coords.lat = current.lat;
                this.$emit("update:coords", this.coords);
            });
        }

        // Map events
        Object.keys(this.$listeners).forEach(eventName => this.marker.addEventListener(eventName, this.delegateEvent));
    },
    beforeDestroy() {
        // Remove event listeners
        Object.keys(this.$listeners).forEach(eventName => this.marker.removeEventListener(eventName, this.delegateEvent));

        if (this.marker) {
            this.marker.remove();
        }
    },
    watch: {
        coords: {
            handler(coords) {
                if (this.marker) {
                    this.marker.setLatLng([coords.lat, coords.lng]);
                }
            },
            deep: true
        }
    },
    methods: {
        delegateEvent(e) {
            this.$emit(e.type, this.marker);
        }
    }
};
</script>