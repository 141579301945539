<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "weather_condition_select" | i18n }}</div>
        </v-ons-toolbar>

        <v-ons-list>
            <v-ons-list-item @click="select(item)" :item="item" v-for="item in items" :key="item" v-tappable modifier="chevron">
                <ListItemIcon icon="cloud-sun"></ListItemIcon>
                <div class="center">{{ item }}</div>
            </v-ons-list-item>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
export default {
    props: ["select"],
    data() {
        const items = this.$store.state.catch.entities
            .map(catchT => catchT.weatherCondition)
            .filter(Boolean)
            .uniq()
            .sort();
        return { items };
    }
};
</script>