<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 120 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
    >
        <g>
            <path
                d="M113.11,49.109C107.896,22.286 84.404,2.921 57.079,2.921C25.576,2.921 0,28.497 0,60C0,91.524 25.555,117.079 57.079,117.079L57.079,114.329C27.075,114.329 2.751,90.005 2.751,60C2.751,30.015 27.095,5.671 57.079,5.671C83.087,5.671 105.447,24.103 110.41,49.634L113.11,49.109Z"
                style="fill:#00ffae;"
            />
            <g>
                <path
                    d="M95.193,50.02C90.626,54.586 90.626,61.991 95.193,66.558C99.757,71.121 107.167,71.121 111.731,66.558C116.298,61.991 116.298,54.586 111.731,50.02L109.787,51.963C113.281,55.457 113.281,61.121 109.787,64.614C106.296,68.105 100.628,68.105 97.137,64.614C93.643,61.121 93.643,55.457 97.137,51.963L95.193,50.02Z"
                    style="fill:#00ffae;"
                />
                <path d="M99.467,46.567C97.823,53.387 98.019,57.496 99.132,60.254C97.335,58.779 95.876,55.854 95.193,50.02C96.592,48.734 98.023,47.614 99.467,46.567Z" style="fill:#00ffae;" />
            </g>
            <path d="M28.177,78.301C39.837,82.046 51.039,79.269 57.59,75.9C62.553,84.002 70.318,90.208 79.534,93.165C58.974,106.348 36.452,99.063 24.618,83.475C25.461,81.969 27.154,79.677 28.177,78.301Z" style="fill:#00ffae;" />
            <path
                d="M33.043,27.966C59.509,9.186 85.855,27.168 91.768,39.973C84.415,53.048 70.53,59.486 58.998,59.981C58.23,60.014 47.482,47.229 53.361,32.707C51.289,35.898 45.706,46.845 54.237,60.1C40.621,61.056 28.873,68.831 22.391,80.027C9.875,57.142 23.199,38.638 23.199,38.638C21.023,35.319 20.049,35.427 17.783,33.617C21.038,29.235 26.313,27.111 33.043,27.966ZM70.975,31.729C73.081,31.729 74.79,33.438 74.79,35.544C74.79,37.649 73.081,39.358 70.975,39.358C68.87,39.358 67.161,37.649 67.161,35.544C67.161,33.438 68.87,31.729 70.975,31.729Z"
                style="fill:#00ffae;"
            />
            <circle cx="111.676" cy="49.939" r="3.372" style="fill:#00ffae;" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "Logo"
};
</script>