// @ts-check
import em from "./event";

class Magnetometer {
    constructor() {
        this.watchId = null;
        this.list = Array(20);
    }

    start() {
        if (window.cordova && window.cordova.plugins.magnetometer) {
            if (this.watchId) {
                this.stop();
            }

            this.watchId = window.cordova.plugins.magnetometer.watchReadings(reading => {
                // Init
                const v = { x: parseFloat(reading.x), y: parseFloat(reading.y), z: parseFloat(reading.z) };

                // Calculate angle
                const current = -(Math.round((Math.atan2(v.y, v.x) * 180.0) / Math.PI) - 90.0);

                // Calc cumulate angle
                this.list.shift();
                this.list.push(current);
                this.setAngle(this.list.reduce((result, v) => result + v, 0) / this.list.length);
            });
        } else {
            window.addEventListener("deviceorientation", orientation, true);
        }
    }

    stop() {
        if (this.watchId) {
            window.cordova.plugins.magnetometer.stop([this.watchId]);
            this.watchId = null;
        } else {
            window.removeEventListener("deviceorientation", orientation, true);
        }
    }

    setAngle(angle) {
        em.emit("magnetometer:angle", angle);
    }
}

function orientation(e) {
    magnetometer.setAngle(e.alpha);
}

const magnetometer = new Magnetometer();
export default magnetometer;
