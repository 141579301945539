window.$ui = {
    locale: "de",
    theme: "dark",
    mapmode: "dark",
    som: "metric"
};

export default {
    namespaced: true,
    state: window.$ui,
    actions: {
        init: ({ commit }) => {
            const persisted = window.localStorage.getItem("ui");
            if (persisted) {
                commit("SET", JSON.parse(persisted));
            }
        },
        locale: ({ commit }, locale) => {
            commit("LOCALE", locale);
            commit("STORE");
        },
        theme: ({ commit }, theme) => {
            commit("THEME", theme);
            commit("STORE");
        },
        mapmode: ({ commit }, mode) => {
            commit("MAPMODE", mode);
            commit("STORE");
        },
        som: ({ commit }, value) => {
            commit("SOM", value);
            commit("STORE");
        }
    },
    mutations: {
        SET(state, ui) {
            Object.assign(state, ui);
        },
        LOCALE(state, locale) {
            state.locale = locale;
        },
        THEME(state, theme) {
            state.theme = theme;
        },
        MAPMODE(state, mode) {
            state.mapmode = mode;
        },
        SOM(state, value) {
            state.som = value;
        },
        STORE(state) {
            window.localStorage.setItem("ui", JSON.stringify(state));
        }
    }
};
