<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">
                <v-ons-icon icon="star" class="text-primary mr-1"></v-ons-icon>
                {{ "premium" | i18n }}
            </div>
            <div class="right">
                <v-ons-toolbar-button @click="refresh">
                    <v-ons-icon icon="undo"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <div class="content">
            <div class="container-xs">
                <section @click="debugTick" class="text-center my-5">
                    <Logo width="140" class="text-primary"></Logo>
                </section>

                <v-ons-list v-if="web" modifier="inset" class="mx-0 my-5">
                    <v-ons-list-item>
                        <ListItemIcon icon="warning"></ListItemIcon>
                        <div class="content">{{ "premium_web" | i18n }}</div>
                    </v-ons-list-item>
                </v-ons-list>

                <div v-if="!web && !ready" class="text-center my-5 text-muted">
                    <v-ons-icon icon="spinner" spin class="mr-1"></v-ons-icon>
                    Loading...
                </div>

                <div v-if="!web && ready && !subscribed">
                    <section class="my-3">{{ "premium_intro" | i18n }}</section>

                    <v-ons-list modifier="inset" class="mx-0 my-3">
                        <v-ons-list-item v-for="feature in features" :key="feature.id">
                            <ListItemIcon :icon="feature.icon"></ListItemIcon>
                            <div class="content">
                                <div class="list-item__title">{{ ("premium_feature_" + feature.id) | i18n }}</div>
                                <div class="list-item__subtitle">{{ ("premium_feature_description_" + feature.id) | i18n }}</div>
                            </div>
                        </v-ons-list-item>
                    </v-ons-list>

                    <div class="my-4">
                        <v-ons-button @click="subscribe(productMonthly)" :disabled="!subscribable(productMonthly)" modifier="large">
                            <v-ons-icon v-if="working" icon="circle-o-notch" spin class="mr-1"></v-ons-icon>
                            <span>{{ "premium_payment_month_price" | i18n(priceMonth) }}</span>
                        </v-ons-button>
                    </div>

                    <div class="my-4">
                        <v-ons-button @click="subscribe(productYearly)" :disabled="!subscribable(productYearly)" modifier="large">
                            <v-ons-icon v-if="working" icon="circle-o-notch" spin class="mr-1"></v-ons-icon>
                            <span>{{ "premium_payment_year_price" | i18n(priceYear) }}</span>
                        </v-ons-button>
                        <p class="mt-2 text-center text-muted">{{ "premium_payment_year_hint" | i18n(priceMonthYearly, priceYearSavings) }}</p>
                    </div>
                </div>

                <div v-if="error" class="alert my-3">
                    {{ error }}
                </div>

                <section v-if="!web && ready && subscribed" class="my-3">
                    {{ "premium_intro_subscribed" | i18n }}
                </section>

                <!--
                <section class="mt-5 text-muted">{{ "premium_terms" | i18n }}</section> -->

                <v-ons-list modifier="inset" class="mx-0 my-3">
                    <v-ons-list-item v-tappable @click="terms" modifier="chevron">{{ "terms" | i18n }}</v-ons-list-item>
                    <v-ons-list-item v-tappable @click="privacy" modifier="chevron">{{ "privacy" | i18n }}</v-ons-list-item>
                </v-ons-list>

                <div v-if="!web && ready && subscribed" class="my-3">
                    <div v-if="error" class="alert my-3">
                        {{ error }}
                    </div>

                    <v-ons-button @click="manageSubscriptions" :disabled="working" modifier="large outline">
                        <v-ons-icon v-if="working" icon="circle-o-notch" spin class="mr-1"></v-ons-icon>
                        {{ "premium_manage_subscriptions" | i18n }}
                    </v-ons-button>
                </div>

                <section v-if="debug >= 5" class="mt-5 text-muted">Debug</section>
                <v-ons-list v-if="debug >= 5" modifier="inset" class="mx-0 my-3">
                    <v-ons-list-item @click="showLog(product.id)" v-for="product in products" :key="product.id">
                        <div class="center">
                            <div class="list-item__title">
                                {{ product.title }}
                            </div>
                            <div class="list-item__subtitle">{{ product.id }}</div>
                            <div class="list-item__subtitle">
                                <span>Valid: {{ product.valid }}</span> | <span>Can Purchase: {{ product.canPurchase }}</span>
                            </div>
                        </div>
                        <div class="right">
                            {{ product.state }}
                        </div>
                    </v-ons-list-item>
                </v-ons-list>
            </div>
        </div>
        <v-ons-modal :visible="loading" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
        </v-ons-modal>
    </v-ons-page>
</template>

<script>
import premiumManager, { PRODUCT_MONTHLY, PRODUCT_YEARLY } from "../bl/premium";
import { parsePrice } from "../util";

export default {
    data() {
        return {
            debug: 0,
            error: null,
            loading: false,
            working: false,
            features: [
                { id: "catches", icon: "fa-fish" },
                { id: "charts", icon: "fa-chart-bar" },
                { id: "web", icon: "fa-cloud" }
            ],
            productYearly: PRODUCT_YEARLY,
            productMonthly: PRODUCT_MONTHLY
        };
    },
    methods: {
        refresh() {
            this.loading = true;
            try {
                premiumManager.refresh();
            } catch (e) {}

            setTimeout(() => (this.loading = false), 1000);
        },
        subscribe(product) {
            this.error = null;
            this.working = true;
            premiumManager.subscribe(product).then(
                result => {
                    setTimeout(() => {
                        this.working = false;
                        this.$event.emit("premium");
                    }, 2000);
                },
                err => {
                    this.error = err;
                    this.working = false;
                }
            );
        },
        subscribable(id) {
            const prod = this.products.find(product => product.id == id);
            return !this.working && prod.canPurchase;
        },
        manageSubscriptions() {
            premiumManager.manageSubscriptions();
        },
        terms() {
            this.$nav.push("Terms", null);
        },
        privacy() {
            this.$nav.push("Privacy", null);
        },
        debugTick() {
            this.debug = this.debug + 1;
        },
        showLog(productId) {
            this.$nav.push("Logs", { entries: this.$store.getters["premium/logs"].filter(entry => entry.id == productId) });
        }
    },
    computed: {
        products() {
            return this.$store.getters["premium/all"];
        },
        ready() {
            return this.$store.getters["premium/ready"];
        },
        subscribed() {
            return this.$store.getters["premium/subscribed"];
        },
        priceMonth() {
            const prod = this.products.find(product => product.id == "fishy_premium_subscription");
            return (prod && prod.price) || "0";
        },
        priceYear() {
            const prod = this.products.find(product => product.id == "fishy_premium_subscription_yearly");
            return (prod && prod.price) || "0";
        },
        priceMonthYearly() {
            const y = parsePrice(this.priceYear);
            const m = parsePrice(this.priceMonth);
            return Number(y / 12.0).toFixed(2);
        },
        priceYearSavings() {
            const m = parsePrice(this.priceMonth);
            const s = (1 - parseFloat(this.priceMonthYearly) / m) * 100;
            return Number(s).toFixed(0);
        },
        web() {
            return !window.cordova;
        }
    }
};
</script>