<template>
    <v-ons-page>
        <div class="content">
            <v-ons-list>
                <LargeList :items="entities" v-slot="chunk" item-height="62">
                    <CatchItem @click="edit(entity)" :entity="entity" v-tappable v-for="entity in chunk.items" :key="entity.id"></CatchItem>
                </LargeList>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    methods: {
        edit(entity) {
            this.$nav.push("Catch", { catch: entity });
        }
    },
    computed: {
        entities() {
            const { dir, field } = this.$store.state.catch.sort;
            return this.$store.getters["catch/all"].sort((a, b) => (field ? (a[field] > b[field] ? 1 : -1) * dir : undefined));
        }
    }
};
</script>