<template>
    <v-ons-page>
        <v-ons-tabbar :tabs="tabs" :index.sync="index" @prechange="showSplash" @postchange="tabChanged" swipeable></v-ons-tabbar>
        <Splash ref="splash"></Splash>
    </v-ons-page>
</template>

<script>
import analytics from "../bl/analytics";
export default {
    data() {
        return {
            index: 0,
            tabs: [
                {
                    key: "home",
                    splash: "map",
                    icon: "map",
                    label: this.$i18n.get("map"),
                    page: this.$nav.get("Overview")
                },
                {
                    key: "catches",
                    splash: "catchbook",
                    icon: "fish",
                    label: this.$i18n.get("catchbook"),
                    page: this.$nav.get("Catchbook")
                },
                {
                    key: "spots",
                    splash: "spot",
                    icon: "map-marker-alt",
                    label: this.$i18n.get("spots"),
                    page: this.$nav.get("Spots")
                },
                {
                    key: "charts",
                    splash: "chart",
                    icon: "chart-bar",
                    label: this.$i18n.get("charts"),
                    page: this.$nav.get("Charts")
                }
            ]
        };
    },
    methods: {
        showSplash() {
            this.$nextTick(() => this.$refs.splash.run(this.tabs[this.index].splash));
        },
        tabChanged() {
            // Init
            const next = this.tabs[this.index];

            // Analytics
            analytics.screen(next && next.page && next.page.name);
        }
    },
    mounted() {
        this.showSplash();
    }
};
</script>