<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center text-truncate">{{ report.name | i18n }}</div>
        </v-ons-toolbar>
        <div class="content">
            <div class="p-2">
                <div :is="reportComponent" style="max-width: 800px; margin: 0 auto;"></div>
            </div>
            <!--
            <v-ons-list class="my-5">
                <v-ons-list-item v-tappable>{{ "print" | i18n }}</v-ons-list-item>
                <v-ons-list-item v-tappable>{{ "share" | i18n }}</v-ons-list-item>
            </v-ons-list>-->
            <PremiumCheck feature="charts" :model="report"></PremiumCheck>
        </div>
    </v-ons-page>
</template>

<script>
import { clone } from "../util";
export default {
    props: ["report"],
    computed: {
        reportComponent() {
            return this.report.component;
        }
    }
};
</script>