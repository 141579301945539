import analytics from "../bl/analytics";

class Navigator {
    constructor(pages = {}) {
        this.stack = [];
        this.pages = pages;
    }

    push(page, props = null, options = null) {
        if (typeof page == "string") {
            page = this.get(page);
        }

        //
        const next = {
            extends: page,
            onsNavigatorProps: props
        };

        if (options && options.animation) {
            next.onsNavigatorOptions = { animation: options.animation };
        }

        this.stack.push(next);

        // Analytics
        analytics.screen(page.name, props);
    }

    replace(page, props, options) {
        while (this.stack.length > 0) {
            this.stack.pop();
        }

        this.push(page, props, options);
    }

    pop() {
        this.stack.pop();
    }

    get(name) {
        return this.pages["Pages" + name];
    }
}

export default {
    install(Vue, options) {
        // Inject navigator
        Vue.prototype.$nav = new Navigator(options.components);
    }
};
