<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ title || "catches" || i18n }}</div>
        </v-ons-toolbar>
        <v-ons-list>
            <LargeList :items="entities" v-slot="chunk" item-height="62">
                <CatchItem @click="edit(entity)" :entity="entity" v-tappable v-for="entity in chunk.items" :key="entity.id"></CatchItem>
            </LargeList>
        </v-ons-list>
    </v-ons-page>
</template>

<script>
export default {
    props: ["filter", "sort", "title"],
    methods: {
        edit(entity) {
            this.$nav.push("Catch", { catch: entity });
        }
    },
    computed: {
        entities() {
            const { dir, field } = this.sort;
            return this.$store.getters["catch/all"].filter(this.filter).sort((a, b) => (field ? (a[field] > b[field] ? 1 : -1) * dir : undefined));
        }
    }
};
</script>