<template>
    <v-ons-page>
        <div class="content">
            <div class="container-xs">
                <h3 class="text-center my-5">{{ "create_account" | i18n }}</h3>

                <div v-if="error" class="alert mb-3">
                    {{ error.message || error }}
                </div>

                <v-ons-list modifier="noborder rounded">
                    <v-ons-list-item>
                        <v-ons-input v-model="model.email" :placeholder="'email' | i18n"></v-ons-input>
                    </v-ons-list-item>
                    <v-ons-list-item>
                        <v-ons-input v-model="model.password" type="password" :placeholder="'password' | i18n"></v-ons-input>
                    </v-ons-list-item>
                    <v-ons-list-item>
                        <v-ons-input v-model="model.password2" type="password" :placeholder="'password2' | i18n"></v-ons-input>
                    </v-ons-list-item>
                    <div v-if="validation && validation != 'validation_agreement'" class="alert">
                        {{ validation | i18n }}
                    </div>
                </v-ons-list>

                <v-ons-list modifier="noborder rounded" class="mt-3">
                    <v-ons-list-item v-tappable @click="terms" modifier="chevron">
                        <div class="left" style="width: 36px"></div>
                        <div class="center">{{ "terms" | i18n }}</div>
                    </v-ons-list-item>
                    <v-ons-list-item v-tappable>
                        <label class="left">
                            <v-ons-checkbox input-id="agree" v-model="model.agree"></v-ons-checkbox>
                        </label>
                        <label class="center" for="agree">{{ "create_account_agreement" | i18n }}</label>
                    </v-ons-list-item>
                    <div v-if="validation && validation == 'validation_agreement'" class="alert">
                        {{ validation | i18n }}
                    </div>
                </v-ons-list>

                <section class="mt-5">
                    <v-ons-button @click="register" :disabled="!valid" modifier="large">{{ "create_account" | i18n }}</v-ons-button>
                </section>

                <section class="mt-3">
                    <v-ons-button @click="cancel" modifier="quiet large">{{ "cancel" | i18n }}</v-ons-button>
                </section>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
import userManager from "../bl/user";
export default {
    data() {
        return { model: {}, error: null, working: false };
    },
    computed: {
        valid() {
            return !this.working && this.model.agree && this.model.email && this.model.email.length > 3 && this.model.password && this.model.password2 && this.model.password.length >= 6 && this.model.password == this.model.password2;
        },
        validation() {
            const m = this.model;
            if (!m.email && !m.password && !m.password2) {
                return null;
            } else if (m.email && m.email.length <= 3) {
                return "validation_email";
            } else if (!m.password || m.password.length < 6) {
                return "validation_password";
            } else if (m.password != m.password2) {
                return "validation_password_match";
            } else if (!m.agree) {
                return "validation_agreement";
            }
        }
    },
    methods: {
        register() {
            this.error = null;
            this.working = true;
            userManager.register(this.model).then(
                res => this.$nav.replace("Start"),
                err => {
                    this.working = false;
                    this.error = err.message;
                }
            );
        },
        cancel() {
            this.$nav.pop();
        },
        terms() {
            this.$nav.push("Terms", null);
        }
    }
};
</script>
