<template>
    <v-ons-page>
        <section class="text-center my-5">
            <Logo width="140" class="text-primary"></Logo>
        </section>
        <!--
        <v-ons-list>
            <v-ons-list-item v-tappable>{{ "website" | i18n }}</v-ons-list-item>
            <v-ons-list-item v-tappable>{{ "feedback" | i18n }}</v-ons-list-item>
            <v-ons-list-item v-tappable>{{ "backup_recovery" | i18n }}</v-ons-list-item>
        </v-ons-list>-->

        <v-ons-list class="my-3">
            <v-ons-list-item v-tappable @click="premium">
                <div class="center">{{ "premium" | i18n }}</div>
                <div class="right"><v-ons-icon icon="star" class="text-primary mr-2"></v-ons-icon></div>
            </v-ons-list-item>
            <v-ons-list-item v-tappable @click="notebook">{{ "notebook" | i18n }}</v-ons-list-item>
        </v-ons-list>

        <v-ons-list class="my-3">
            <v-ons-list-item v-if="loggedin" v-tappable @click="profile">{{ "profile" | i18n }}</v-ons-list-item>
            <v-ons-list-item v-tappable @click="settings">{{ "settings" | i18n }}</v-ons-list-item>
            <v-ons-list-item v-tappable @click="language">
                <div class="center">{{ "language" | i18n }}</div>
                <div class="right">
                    <LocaleFlag :locale="$store.state.ui.locale" width="16"></LocaleFlag>
                </div>
            </v-ons-list-item>
        </v-ons-list>

        <v-ons-list v-if="debug" class="my-3">
            <v-ons-list-item v-tappable @click="reset"><span class="text-muted mr-1">DEV</span> Reset data</v-ons-list-item>
            <v-ons-list-item v-tappable @click="randomize"><span class="text-muted mr-1">DEV</span> Randomize</v-ons-list-item>
        </v-ons-list>

        <v-ons-list class="my-3">
            <v-ons-list-item v-tappable @click="checkUpdate">{{ "software_update_check" | i18n }}</v-ons-list-item>
        </v-ons-list>

        <v-ons-list class="my-3">
            <v-ons-list-item v-if="!loggedin" v-tappable @click="login">{{ "login" | i18n }}</v-ons-list-item>
            <v-ons-list-item v-if="loggedin" v-tappable @click="logout">{{ "logout" | i18n }}</v-ons-list-item>
        </v-ons-list>

        <Version class="py-3 text-muted text-center"></Version>
    </v-ons-page>
</template>

<script>
import axios from "axios";
import userManager from "../bl/user";
import { gen } from "../../test/gen";
import { appBaseUrl, debug } from "../constants";
import packageJson from "../../../package.json";
export default {
    data() {
        return { debug };
    },
    computed: {
        loggedin() {
            return userManager.isLoggedIn();
        }
    },
    methods: {
        notebook() {
            this.$event.emit("menu:toggle");
            this.$nav.push("Notebook");
        },
        profile() {
            this.$event.emit("menu:toggle");
            this.$nav.push("Profile");
        },
        settings() {
            this.$event.emit("menu:toggle");
            this.$nav.push("Settings");
        },
        language() {
            this.$event.emit("menu:toggle");
            this.$nav.push("Language");
        },
        reset() {
            this.$store.dispatch("spot/reset");
            this.$store.dispatch("catch/reset");
        },
        login() {
            this.$event.emit("menu:toggle");
            this.$nav.push("Login");
        },
        premium() {
            this.$event.emit("menu:toggle");
            this.$nav.push("Premium");
        },
        logout() {
            userManager.logout();
            this.$event.emit("menu:toggle");
            this.$nav.replace("Login");
        },
        randomize() {
            const data = gen();
            this.$store.commit("spot/SET", data.spots);
            this.$store.commit("spot/STORE");
            this.$store.commit("catch/SET", data.catches);
            this.$store.commit("catch/STORE");
            // window.location.reload();
        },
        checkUpdate() {
            return axios.get(appBaseUrl + "/version.json?t=" + new Date().getTime()).then(versionRes => {
                if (versionRes && versionRes.data && versionRes.data.version && versionRes.data.version != packageJson.version) {
                    this.$ons.notification.confirm(this.$i18n.get("software_update_available"), { title: this.$i18n.get("software_update") }).then(result => {
                        if (result) {
                            window.location.reload();
                        }
                    });
                } else {
                    this.$ons.notification.alert(this.$i18n.get("software_update_ok"), { title: this.$i18n.get("software_update") });
                }
            });
        }
    }
};
</script>