<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "note_edit" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="save">{{ "save" | i18n }}</v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>

        <div class="content">
            <v-ons-list>
                <v-ons-list-item>
                    <v-ons-input v-model="model.title" :placeholder="'note_title_placeholder' | i18n"></v-ons-input>
                </v-ons-list-item>
            </v-ons-list>

            <textarea v-model="model.text" :placeholder="'note_text_placeholder' | i18n" class="textarea p-3 border-none h-50 w-100"></textarea>

            <v-ons-list v-if="model.id" class="mt-5">
                <v-ons-list-item v-tappable @click="remove" class="text-danger">{{ "delete" | i18n }}</v-ons-list-item>
            </v-ons-list>
        </div>
    </v-ons-page>
</template>

<script>
import { clone } from "../util";
export default {
    props: ["note"],
    data() {
        return { model: clone(this.note) || {} };
    },
    methods: {
        save() {
            this.$nav.pop();
            this.$store.dispatch("notebook/update", this.model);
            this.$ons.notification.toast(this.$i18n.get("successfully_saved"), { timeout: 1000 });
        },
        remove() {
            this.$ons.notification.confirm(this.$i18n.get("really_delete?")).then(result => {
                if (result) {
                    this.$nav.pop();
                    this.$store.dispatch("notebook/delete", this.model);
                    this.$ons.notification.toast(this.$i18n.get("successfully_deleted"), { timeout: 1000 });
                }
            });
        }
    }
};
</script>