import short from "short-uuid";
import { dist } from "../util";
export default {
    namespaced: true,
    info: {
        sync: "spot"
    },
    state: {
        entities: []
    },
    getters: {
        all: state => state.entities,
        byid(state) {
            return spec => state.entities.find(entity => entity.id == (spec.id || spec));
        },
        contained(state) {
            return bounds => {
                if (bounds && bounds.min) {
                    const px = 0.1 * (bounds.max.lng - bounds.min.lng);
                    const py = 0.1 * (bounds.max.lat - bounds.min.lat);
                    return state.entities.filter(entity => entity.lng >= bounds.min.lng - px && entity.lng <= bounds.max.lng + px && entity.lat >= bounds.min.lat - py && entity.lat <= bounds.max.lat + py);
                }

                return [];
            };
        },
        nearest(state) {
            return (location, radius = 1000) => {
                return state.entities
                    .map(entity => {
                        let distT = dist(location, entity);
                        if (radius === false || distT < radius) {
                            return { ...entity, metadata: { dist: distT } };
                        }
                    })
                    .filter(Boolean)
                    .sort((a, b) => (a.metadata.dist > b.metadata.dist ? 1 : -1));
            };
        }
    },
    actions: {
        update: ({ commit }, entity) => {
            commit("REMOVE", entity);
            if (!entity.id) {
                entity.id = short.generate();
            }

            commit("ADD", entity);
            commit("STORE");
        },
        delete: ({ commit }, entity) => {
            commit("REMOVE", entity);
            commit("STORE");
        },
        reset: ({ commit }) => {
            commit("SET", []);
            commit("STORE");
        },
        restore: ({ commit }, data) => {
            commit("SET", (data && data.entities) || []);
        }
    },
    mutations: {
        SET(state, entities) {
            state.entities = entities;
        },
        REMOVE(state, entity) {
            state.entities = state.entities.filter(current => current.id !== (entity.id || entity));
        },
        ADD(state, entity) {
            state.entities.push(entity);
        },
        STORE(state) {}
    }
};
