<template>
    <div>
        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item>
                <ListItemIcon icon="link"></ListItemIcon>
                <v-ons-select v-model="params.waters">
                    <option value=""></option>
                    <option v-for="water in waters" :key="water" :value="water">{{ water }}</option>
                </v-ons-select>
            </v-ons-list-item>
        </v-ons-list>

        <div class="text-center mt-3 overflow-x-auto">
            <v-ons-segment :index.sync="tabYear" class="pb-2" modifier="large">
                <button v-for="year in years" :key="year">{{ year }}</button>
            </v-ons-segment>
        </div>

        <div class="my-5"><canvas ref="canvas"></canvas></div>

        <div class="text-center overflow-x-auto">
            <v-ons-segment :index.sync="tabMonth" class="pb-2" modifier="large">
                <button><v-ons-icon icon="asterisk"></v-ons-icon></button>
                <button v-for="month in months" :key="month">{{ ("month_short_" + month) | i18n }}</button>
            </v-ons-segment>
        </div>

        <v-ons-list class="mt-3" modifier="noborder rounded">
            <v-ons-list-item @click="showCatches" v-tappable modifier="chevron">{{ periodTitle }}</v-ons-list-item>
        </v-ons-list>
    </div>
</template>

<script>
import CatchesByTime from "./CatchesByTime";

export default {
    report: {
        index: 300,
        name: "report_catches_by_waters",
        description: "report_catches_by_waters_desc",
        premium: true
    },
    extends: CatchesByTime,
    methods: {
        createParams() {
            return { waters: "" };
        },
        catchFilter(item, options) {
            if (item && item.spot) {
                const spot = this.$store.getters["spot/byid"](item.spot);
                const blank = options && options.blank !== undefined ? options.blank : false;
                return item.blank === blank && spot && spot.watersName == this.params.waters;
            }

            return false;
        }
    },
    computed: {
        waters() {
            return this.$store.getters["spot/all"]
                .map(item => item.watersName)
                .uniq()
                .filter(Boolean);
        }
    }
};
</script>