<template>
    <v-ons-toolbar-button @click="toggle">
        <v-ons-icon :icon="icon || 'grip-lines'"></v-ons-icon>
    </v-ons-toolbar-button>
</template>

<script>
export default {
    name: "MenuButton",
    props: ["icon"],
    methods: {
        toggle() {
            this.$event.emit("menu:toggle");
        }
    }
};
</script>