// @ts-check
import short from "short-uuid";
import taskManager from "./task";
import browserphoto from "./browserphoto";
import { storageUrl } from "../constants";
import { storage, auth, firebaseConfig } from "../../firebase";

// Local photo cache
const cache = {};

// Photo manager
class PhotoManager {
    take(options) {
        if (this.native()) {
            return new Promise((resolve, reject) => window.navigator.camera.getPicture(resolve, reject, Object.assign({ destinationType: Camera.DestinationType.FILE_URI }, options)));
        }

        // return axios.get("https://images.pexels.com/photos/3608618/pexels-photo-3608618.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260", { responseType: "blob" }).then(res => new File([res.data], "photo"));
        return browserphoto();
    }

    async uploadSync(file) {
        // Init
        const id = short.generate();
        const filename = ["photos", auth.currentUser.uid, id].join("/");

        // Create task
        const task = taskManager.create(id);

        // Upload photo
        const ref = storage.ref().child(filename);
        if (file instanceof Blob) {
            await ref.put(file);
        } else {
            await ref.put(await fileUriToBlob(file));
        }

        // Wait for processing
        await task;

        // Return id
        return id;
    }

    async upload(file) {
        // Init
        const id = short.generate();
        const filename = ["photos", auth.currentUser.uid, id].join("/");

        // Create task
        taskManager.create(id).then(() => delete cache[id]);

        // Upload photo (async), after photo was uploaded, the task from above will resolve
        const ref = storage.ref().child(filename);
        if (file instanceof Blob) {
            ref.put(file);
        } else {
            ref.put(await fileUriToBlob(file));
        }

        // Created sized image from file and cache it
        if (file instanceof Blob) {
            cache[id] = await fileToSizeImageDataUrl(file, 200);
        } else {
            cache[id] = file;
        }

        // Return id
        return id;
    }

    url(spec, variant) {
        // Validate?
        if (spec && spec.length > 256) {
            console.log(spec);
            throw new Error("Invalid spec");
        }

        // Spec is image?
        if (spec.indexOf("http") === 0) {
            return spec;
        }

        // Return cached version?
        if (cache[spec]) {
            return cache[spec];
        }

        // Calculate path
        const path = encodeURIComponent(["photos", auth.currentUser.uid, spec + (variant ? "_" + variant : "")].join("/"));

        // Return url
        return [storageUrl, firebaseConfig.storageBucket, path].join("/");
    }

    native() {
        return !!window.navigator.camera;
    }
}

export default new PhotoManager();

// Helper
function fileUriToBlob(uri) {
    return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(
            uri,
            fileEntry => {
                fileEntry.file(file => {
                    // Init file reader
                    const reader = new FileReader();

                    // Create blob
                    reader.onerror = reject;
                    reader.onloadend = result => resolve(new Blob([new Uint8Array(result.target.result)], { type: file.type }));

                    // Convert to array buffer
                    reader.readAsArrayBuffer(file);
                }, reject);
            },
            reject
        );
    });
}

// Helper
function fileToSizeImageDataUrl(file, size) {
    // Read photo to local cache
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            const img = document.createElement("img");
            img.onerror = reject;
            img.onload = function() {
                const canvas = document.createElement("canvas");
                canvas.width = 200;
                canvas.height = 200;
                const g2d = canvas.getContext("2d");
                g2d.drawImage(img, 0, 0, size, size);

                const data = canvas.toDataURL("image/jpeg");

                img.remove();
                canvas.remove();
                resolve(data);
            };

            img.setAttribute("src", reader.result);
        };

        reader.readAsDataURL(file);
    });
}
