<template>
    <v-ons-page>
        <v-ons-toolbar modifier="spread">
            <div class="left"><MenuButton></MenuButton></div>
            <div class="center">{{ "catchbook" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button id="catchbook_sort" @click="chooseSort"><v-ons-icon icon="sort-amount-down"></v-ons-icon></v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <v-ons-tabbar :tabs="tabs" :index.sync="index" position="top" @postchange="tabChanged" swipeable></v-ons-tabbar>
        <CreateButton></CreateButton>
        <v-ons-popover :visible.sync="sortVisible" :cancelable="true" direction="down" target="#catchbook_sort">
            <v-ons-list>
                <v-ons-list-item @click="sortBy(field)" v-for="field in sorts" :key="field" v-tappable>
                    <div class="right text-primary">
                        <v-ons-icon fixed-width :icon="sortIcon(field)"></v-ons-icon>
                    </div>
                    <div class="center">{{ field | i18n }}</div>
                </v-ons-list-item>
            </v-ons-list>
        </v-ons-popover>
    </v-ons-page>
</template>

<script>
import analytics from "../bl/analytics";
export default {
    data() {
        return {
            sortVisible: false,
            index: 0,
            tabs: [
                {
                    key: "catches",
                    label: this.$i18n.get("catches"),
                    page: this.$nav.get("Catches")
                },
                {
                    key: "gallery",
                    label: this.$i18n.get("gallery"),
                    page: this.$nav.get("Gallery")
                }
            ],
            sorts: ["date", "length", "weight", "fish"]
        };
    },
    computed: {
        sort() {
            return this.$store.state.catch.sort;
        }
    },
    methods: {
        sortBy(field) {
            this.sortVisible = !this.sortVisible;
            this.$store.dispatch("catch/sort", field);
        },
        sortIcon(field) {
            return field == this.sort.field ? (this.sort.dir === 1 ? "caret-down" : "caret-up") : "empty";
        },
        chooseSort() {
            this.sortVisible = !this.sortVisible;
        },
        tabChanged() {
            // Init
            const next = this.tabs[this.index];

            // Analytics
            analytics.screen(next && next.page && next.page.name);
        }
    }
};
</script>