<template>
    <v-ons-modal :visible="running" class="splash" :class="{ running }" @animationend="animationend">
        <div v-if="splash" class="svg-container">
            <div :is="splashComponent"></div>
        </div>
    </v-ons-modal>
</template>

<script>
import { capitalize } from "../util";
export default {
    name: "Splash",
    data() {
        return { running: false, splash: null, ran: [] };
    },
    computed: {
        splashComponent() {
            return `Splash${capitalize(this.splash)}`;
        }
    },
    methods: {
        run(splash) {
            if (!this.ran.includes(splash) && window.location.host != "localhost:8000") {
                this.splash = splash;
                this.ran.push(splash);
                this.$nextTick(() => {
                    this.running = true;
                });
            }
        },
        animationend() {
            this.running = false;
        }
    }
};
</script>