<template>
    <v-ons-page @hide="hide">
        <v-ons-toolbar modifier="spread">
            <div class="left"><MenuButton></MenuButton></div>
            <div class="center">
                {{ "spots" | i18n }}
                <div v-if="search" ref="searchForm" class="searchbar">
                    <v-ons-search-input :placeholder="'search_placeholder' | i18n" v-model="query" class="d-flex px-2"></v-ons-search-input>
                    <v-ons-toolbar-button @click="toggleSearch" modifier="quiet">{{ "cancel" | i18n }}</v-ons-toolbar-button>
                </div>
            </div>
            <div class="right">
                <v-ons-toolbar-button @click="toggleSearch">
                    <v-ons-icon icon="search"></v-ons-icon>
                </v-ons-toolbar-button>
            </div>
        </v-ons-toolbar>
        <div class="content">
            <v-ons-list>
                <LargeList :items="entities" v-slot="chunk" item-height="62">
                    <SpotItem @click="edit(entity)" :entity="entity" v-tappable v-for="entity in chunk.items" :key="entity.id"></SpotItem>
                </LargeList>
            </v-ons-list>
        </div>
        <CreateButton></CreateButton>
    </v-ons-page>
</template>

<script>
export default {
    data() {
        return { search: false, query: "" };
    },
    methods: {
        hide() {
            this.query = "";
            this.search = false;
        },
        edit(spot) {
            this.$nav.push("Spot", { spot, zoom: 17 });
        },
        toggleSearch() {
            this.search = !this.search;
            if (this.search) {
                this.query = "";
                setTimeout(() => this.$refs.searchForm.querySelector("input").focus(), 250);
            }
        }
    },
    computed: {
        entities() {
            const q = this.search && this.query.trim().toLowerCase();
            return this.$store.getters["spot/nearest"](this.$store.state.location, false).filter(spot => (q ? spot.name.toLowerCase().indexOf(q) >= 0 : true));
        }
    }
};
</script>