<template>
    <v-ons-page>
        <v-ons-toolbar modifier="spread">
            <div class="left"><MenuButton></MenuButton></div>
            <div class="center">{{ "map" | i18n }}</div>
            <div class="right">
                <v-ons-toolbar-button @click="search"><v-ons-icon icon="search"></v-ons-icon></v-ons-toolbar-button>
                <!--
                <v-ons-toolbar-button @click="compass"><v-ons-icon icon="compass"></v-ons-icon></v-ons-toolbar-button> -->
            </div>
        </v-ons-toolbar>

        <div class="content">
            <Compass :angle.sync="angle"></Compass>
            <SpotMap ref="map" v-if="ready" :zoom.sync="zoom" :center.sync="center" :angle="angle" @spot:click="edit" class="layout"></SpotMap>
            <CreateButton :zoom="zoom"></CreateButton>
            <v-ons-fab @click="home" position="bottom left">
                <v-ons-icon icon="crosshairs"></v-ons-icon>
            </v-ons-fab>
        </div>

        <v-ons-modal :visible="!ready" class="text-center">
            <p><v-ons-icon icon="spinner" spin></v-ons-icon></p>
            <p>{{ "locating" | i18n }}</p>
        </v-ons-modal>
    </v-ons-page>
</template>

<script>
import geo from "../bl/geo";

// Init zoom from url params
const zoom = parseFloat(new URL(window.location.href).searchParams.get("zoom")) || 15;

export default {
    data() {
        return { zoom, angle: 0, center: { lng: null, lat: null } };
    },
    methods: {
        home() {
            this.$refs.map.home();
        },
        compass() {
            this.$nav.push("Compass", null);
        },
        search() {
            this.$nav.push("Search", {
                select: item => {
                    this.$nav.pop();
                    this.$refs.map.go(item);
                }
            });
        },
        edit(spot) {
            this.$nav.push("Spot", { spot, zoom: this.zoom });
        }
    },
    computed: {
        ready() {
            return Number.isFinite(this.center && this.center.lng);
        }
    },
    mounted() {
        geo.getPosition().then(position => (this.center = { ...position }));
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == "location/LOCATION") {
                // Is initial location (0, 0)?
                const initial = this.center.lng === 0 && this.center.lat === 0;

                // Set center
                this.center.lat = mutation.payload.lat;
                this.center.lng = mutation.payload.lng;

                // Fly to first queried location?
                if (initial) {
                    this.$refs.map.go(this.center, null, { animate: false });
                }
            }
        });
    },
    watch: {
        center(center) {
            this.$store.dispatch("center/update", center);
        }
    }
};
</script>
