<template>
    <div><canvas ref="canvas"></canvas></div>
</template>

<script>
import Base from "./Base";

const windDirections = "N,NO,O,SO,S,SW,W,NW".split(",");

export default {
    extends: Base,
    methods: {
        createData() {
            return {
                type: "line",
                data: {
                    labels: windDirections,
                    datasets: []
                },
                options: {}
            };
        },
        updateData(chart) {
            chart.data.datasets = [
                {
                    label: "# Catches",
                    borderColor: "rgb(0, 255, 174)",
                    backgroundColor: "rgba(0, 255, 174, 0.3)",
                    data: windDirections.map(dir => this.dataSet(dir))
                }
            ];
        },
        dataSet(dir) {
            return this.$store.getters["catch/all"].filter(item => item.windDirection == dir).length;
        }
    }
};
</script>