<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left"><v-ons-back-button></v-ons-back-button></div>
            <div class="center">{{ "terms" | i18n }}</div>
        </v-ons-toolbar>

        <div class="content">
            <div class="container-xs">
                <h1>Nutzungsbedingungen</h1>
                <p>Vielen Dank, dass Sie die Fishy App (im Folgenden „Anwendung“) verwenden.</p>
                <p>
                    Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig durch, bevor Sie der Anwendung zustimmen, sie herunterladen oder verwenden. Um die Anwendung nutzen zu können, müssen Sie zunächst diese Allgemeinen Geschäftsbedingungen
                    akzeptieren. Durch Klicken auf die Schaltfläche "Akzeptieren" oder Herunterladen oder Verwenden der Anwendung erklären Sie sich mit den Bedingungen dieser Vereinbarung einverstanden. Diese Allgemeinen Geschäftsbedingungen stellen eine
                    rechtliche Vereinbarung zwischen Ihnen und dem Inhaber der Anwendung dar (im Folgenden "wir", "wir", "unser" oder das "Unternehmen").
                </p>
                <p>Wenn Sie diese Allgemeinen Geschäftsbedingungen nicht akzeptieren oder nicht akzeptieren können, dürfen Sie die Anwendung nicht verwenden. Laden Sie in diesem Fall die Anwendung nicht herunter oder verwenden Sie sie nicht.</p>

                <h2>1. Allgemeines</h2>
                <p>
                    Die Anwendung wird Ihnen vom Unternehmen zur Verwendung unter strikter Einhaltung der Allgemeinen Geschäftsbedingungen dieser Lizenz und aller durch andere Nutzungsregeln oder Nutzungsbedingungen Dritter festgelegten Lizenzen
                    lizenziert. Der Begriff bezieht sich auf Folgendes und umfasst Folgendes: (i) die dieser Lizenz beigefügte mobile Softwareanwendung, einschließlich, jedoch nicht beschränkt auf Softwarecode, Skripte, Schnittstellen, Grafiken, Anzeigen,
                    Text, Dokumentation und andere Komponenten; (ii) Aktualisierungen, Modifikationen oder Verbesserungen der in Unterabschnitt (i) aufgeführten Elemente; und (iii) eine bestimmte Website, zu der die Anwendung Sie über einen beliebigen
                    Browser auf einem mobilen Gerät (im Folgenden „Mobiles Gerät“) weiterleitet.
                </p>

                <h2>2. Lizenzgewährung und Nutzungsbeschränkungen</h2>
                <h3>2.1 Lizenzgewährung</h3>
                <p>
                    Das Unternehmen gewährt Ihnen ein widerrufliches, nicht exklusives, nicht übertragbares, persönliches, nicht unterlizenzierbares, eingeschränktes und nicht übertragbares Recht zum Herunterladen, Installieren und Verwenden der Anwendung
                    auf einem einzelnen mobilen Gerät, das Ihnen gehört und von Ihnen kontrolliert wird, sowie zum Zugriff und zur Verwendung Die Anwendung auf einem solchen mobilen Gerät entspricht ausschließlich den Allgemeinen Geschäftsbedingungen
                    dieser Lizenz, den Nutzungsregeln und allen mit Ihrem mobilen Gerät verbundenen Servicevereinbarungen.
                </p>

                <h3>2.2 Nutzungsbeschränkungen</h3>
                <p>
                    Sie dürfen die Anwendung ausschließlich in Übereinstimmung mit den Allgemeinen Geschäftsbedingungen verwenden und dürfen nicht: (a) den Quellcode der Anwendung dekompilieren, zurückentwickeln, disassemblieren, versuchen, ihn abzuleiten
                    oder zu entschlüsseln; (b) Änderungen, Anpassungen, Verbesserungen, Verbesserungen, Übersetzungen oder abgeleitete Arbeiten aus dem Antrag vornehmen; (c) gegen geltende Gesetze, Regeln oder Vorschriften im Zusammenhang mit Ihrem Zugriff
                    oder Ihrer Nutzung der Anwendung verstoßen; (d) Entfernen, Ändern oder Verschleiern von Eigentumsvermerken (einschließlich Urheberrechts- oder Markenvermerken) des Unternehmens oder seiner verbundenen Unternehmen, Partner, Lieferanten
                    oder Lizenzgeber der Anwendung; (e) die Anwendung für umsatzgenerierende Zwecke, Handelsunternehmen oder andere Zwecke zu verwenden, für die sie nicht konzipiert oder vorgesehen ist; (f) Bereitstellung der Anwendung über ein Netzwerk
                    oder eine andere Umgebung, die den Zugriff oder die Verwendung durch mehrere mobile Geräte oder Benutzer gleichzeitig ermöglicht; (i) die Anwendung zur Erstellung eines Produkts, einer Dienstleistung oder einer Software verwenden, die
                    direkt oder indirekt mit den vom Unternehmen angebotenen Dienstleistungen, Produkten oder Software konkurriert oder diese in irgendeiner Weise ersetzt; (g) proprietäre Informationen oder Schnittstellen des Unternehmens oder anderes
                    geistiges Eigentum des Unternehmens für das Design, die Entwicklung, Herstellung, Lizenzierung oder den Vertrieb von Anwendungen, Zubehör oder Geräten zur Verwendung mit der Anwendung verwenden; (h) sich in einem Land befinden, das
                    einem Embargo der US-Regierung unterliegt oder das von der US-Regierung als Land zur Unterstützung von Terroristen ausgewiesen wurde; und Sie sind nicht auf einer Liste verbotener oder eingeschränkter Parteien der US-Regierung
                    aufgeführt.
                </p>

                <h2>3. Rechte an geistigem Eigentum</h2>
                <h3>3.1 Rechte zur Anwendung</h3>
                <p>
                    Sie erkennen an und stimmen zu, dass die Anmeldung und alle damit verbundenen Urheberrechte, Patente, Marken, Geschäftsgeheimnisse und sonstigen Rechte an geistigem Eigentum Eigentum des Unternehmens sind und bleiben. Darüber hinaus
                    erkennen Sie an und stimmen zu, dass der Quell- und Objektcode der Anwendungen sowie das Format, die Verzeichnisse, Abfragen, Algorithmen, die Struktur und die Organisation der Anwendung geistiges Eigentum sowie geschützte und
                    vertrauliche Informationen des Unternehmens und seiner verbundenen Unternehmen, Lizenzgeber und Lieferanten sind. Sofern in dieser Lizenz nicht ausdrücklich anders angegeben, werden Ihnen keine Rechte an geistigem Eigentum an oder für
                    die Anwendung durch Implikation, Entkopplung oder andere Rechtstheorie gewährt, und alle Rechte an und für die Anwendung, die nicht ausdrücklich in dieser Lizenz gewährt werden, werden hiermit vorbehalten und behalten Unternehmen.
                </p>

                <h3>3.2 Software von Drittanbietern</h3>
                <p>
                    Die Anwendung kann Software von Drittanbietern verwenden oder enthalten, die Open Source- und Lizenzbestimmungen von Drittanbietern unterliegt. Sie erkennen an und stimmen zu, dass Ihr Recht zur Nutzung dieser Software von
                    Drittanbietern als Teil der Anwendung den Bestimmungen und Bedingungen der Open Source- oder Drittanbieterlizenz unterliegt, die für diese Software von Drittanbietern gilt, einschließlich, jedoch nicht beschränkt auf, geltender
                    Bestimmungen darin enthaltene Anerkennungen, Lizenzbedingungen und Haftungsausschlüsse. Im Falle eines Konflikts zwischen den Bestimmungen dieser Lizenz und den Bestimmungen dieser Open Source- oder Drittanbieterlizenzen gelten die
                    Bestimmungen der Open Source- oder Drittanbieterlizenzen in Bezug auf Ihre Nutzung der entsprechenden Drittanbieter-Software. In keinem Fall wird die Anwendung oder deren Komponenten als öffentlich verfügbare Software angesehen.
                </p>

                <h3>3.3 Marken des Unternehmens</h3>
                <p>
                    Sie sind nicht berechtigt, die Marken des Unternehmens ohne vorherige schriftliche Zustimmung des Unternehmens für Werbung, Reklame oder auf andere kommerzielle Weise zu verwenden, die ohne Angabe von Gründen zurückgehalten werden kann.
                </p>

                <h3>3.4 Bestätigung von Verstößen</h3>
                <p>
                    Sie und das Unternehmen erkennen an und erklären sich damit einverstanden, dass Sie (und nicht das Unternehmen) für die Untersuchung, Verteidigung, Beilegung und Entlastung eines solchen Anspruchs auf Verletzung des geistigen Eigentums.
                    Sie werden das Unternehmen jedoch unverzüglich schriftlich über einen solchen Anspruch informieren.
                </p>

                <h2>4. Übertragungsbeschränkung</h2>
                <p>
                    Sie dürfen die Anwendung, diese Lizenz oder die hiermit gewährten Rechte nicht vermieten, leasen, verleihen, unterlizenzieren oder übertragen. Jeder Versuch einer Übertragung, der gegen diese Bestimmung verstößt, ist nichtig und ohne
                    Kraft oder Wirkung.
                </p>

                <h2>5. Inhalte und Dienste Dritter</h2>
                <h3>5.1 Allgemeines</h3>
                <p>
                    Sie erkennen an, dass die Anwendung den Zugriff auf Produkte, Dienstleistungen, Websites, Anzeigen, Werbeaktionen, Empfehlungen, Ratschläge, Informationen und Materialien ermöglicht, die von Werbetreibenden, Publishern,
                    Content-Partnern, Marketingagenten, Anbietern und anderen Dritten erstellt und bereitgestellt werden.
                </p>

                <h3>5.2 Haftungsausschluss</h3>
                <p>
                    Sie erkennen an, dass das Unternehmen die Inhalte und Dienste Dritter (einschließlich der über die Anwendung verfügbaren Websites Dritter) nicht untersucht, überwacht, repräsentiert oder unterstützt. Darüber hinaus liegt Ihr Zugriff auf
                    und Ihre Nutzung der Inhalte und Dienste Dritter in Ihrem alleinigen Ermessen und Risiko, und das Unternehmen und seine verbundenen Unternehmen, Partner, Lieferanten und Lizenzgeber haften Ihnen gegenüber nicht aus oder im Zusammenhang
                    mit Ihrem Zugriff auf und Nutzung der Inhalte und Dienste Dritter. Das Unternehmen lehnt hiermit jegliche ausdrückliche, stillschweigende oder gesetzliche Zusicherung, Garantie oder Gewährleistung in Bezug auf Inhalte und Dienste
                    Dritter ab, einschließlich, ohne Einschränkung, der stillschweigenden Gewährleistung der Marktgängigkeit oder Eignung für einen bestimmten Zweck sowie jeglicher Zusicherung, Gewährleistung oder Gewährleistung in Bezug auf die
                    Verfügbarkeit, Qualität, Zuverlässigkeit, Merkmale, Angemessenheit, Genauigkeit, Vollständigkeit oder Rechtmäßigkeit der Inhalte und Dienste Dritter.
                </p>

                <h3>5.3 Nutzungsbedingungen Dritter</h3>
                <p>
                    Sie erkennen an und erklären sich damit einverstanden, dass Ihr Zugriff auf und Ihre Nutzung der Inhalte und Dienste Dritter sowie jeglicher Korrespondenz oder Geschäftsbeziehungen zwischen Ihnen und Dritten, die die Anwendung nutzen,
                    von den Nutzungsbedingungen dieser Dritten geregelt werden und Ihre Zustimmung dazu erfordern. einschließlich, ohne Einschränkung, der darin enthaltenen Bestimmungen, Datenschutzrichtlinien, Bedingungen, Zusicherungen, Gewährleistungen
                    oder Haftungsausschlüsse. Darüber hinaus erkennen Sie an und stimmen zu, dass die Inhalte und Dienste Dritter und alle damit verbundenen Nutzungsbedingungen Dritter nach eigenem Ermessen und ohne vorherige Ankündigung vom jeweiligen
                    Dritten geändert werden können. Sie übernehmen alle Risiken, die sich aus oder aus Ihrer Geschäftstransaktion über das Internet und mit Dritten ergeben, und Sie stimmen zu, dass das Unternehmen und seine verbundenen Unternehmen,
                    Partner, Lieferanten und Lizenzgeber nicht für Verluste oder Ergebnisse der Präsenz verantwortlich oder haftbar sind von Informationen über oder Links zu solchen Werbetreibenden oder Dienstleistern. Darüber hinaus erkennen Sie an und
                    stimmen zu, dass Ihnen keine Lizenz für (i) Inhalte und Dienste Dritter gewährt wird; (ii) Produkte, Dienstleistungen, Prozesse oder Technologien, die in den Inhalten und Diensten Dritter beschrieben sind oder von diesen angeboten
                    werden; oder (iii) Urheberrechte, Markenrechte, Patente oder andere geistige Eigentumsrechte an Inhalten oder Dienstleistungen Dritter oder an Produkten, Dienstleistungen, Prozessen oder Technologien, die darin beschrieben oder
                    angeboten werden.
                </p>

                <h3>5.4 Vermerke</h3>
                <p>
                    Sie erkennen an und stimmen zu, dass die Bereitstellung des Zugriffs auf Inhalte und Dienste Dritter keine Billigung solcher Inhalte und Dienste Dritter durch das Unternehmen oder seine verbundenen Unternehmen darstellt oder impliziert.
                    Das Unternehmen behält sich das Recht vor, den Zugriff auf Inhalte und Dienste Dritter, auf die über die Anwendung anderweitig zugegriffen werden kann, einzuschränken oder zu verweigern, obwohl das Unternehmen nicht verpflichtet ist,
                    den Zugriff zu beschränken oder zu verweigern, selbst wenn dies von Ihnen angefordert wird.
                </p>

                <h3>5.5 Nutzung von Inhalten und Diensten Dritter</h3>
                <p>
                    Sie erklären sich damit einverstanden, dass die Inhalte und Dienste Dritter geschützte Informationen und Materialien enthalten, die Eigentum des Unternehmens und seiner verbundenen Unternehmen, Partner, Lieferanten und Lizenzgeber sind
                    und durch geltendes geistiges Eigentum und andere Gesetze geschützt sind, einschließlich, jedoch nicht beschränkt auf das Urheberrecht Sie werden solche geschützten Informationen oder Materialien in keiner Weise verwenden, außer für die
                    erlaubte Nutzung der Inhalte und Dienste Dritter. Kein Teil der Inhalte und Dienste Dritter darf in irgendeiner Form oder auf irgendeine Weise reproduziert werden. Sie erklären sich damit einverstanden, auf der Grundlage der Inhalte und
                    Dienste Dritter in keiner Weise modifizierte Werke zu modifizieren, zu vermieten, zu leasen, zu verleihen, zu verkaufen, zu vertreiben oder zu erstellen, und Sie dürfen die Inhalte und Dienste Dritter in keiner unbefugten Weise nutzen.
                    einschließlich, ohne Einschränkung, durch Übertretung oder Belastung der Netzwerkkapazität. Sie erklären sich damit einverstanden, dass Sie Inhalte und Dienste Dritter nicht in einer Weise nutzen, die die Rechte einer anderen Partei
                    verletzen oder verletzen würde, und dass das Unternehmen in keiner Weise für eine solche Nutzung durch Sie verantwortlich ist.
                </p>

                <h2>6. Laufzeit und Kündigung</h2>
                <h3>6.1 Laufzeit</h3>
                <p>Diese Lizenz gilt bis zur Kündigung.</p>

                <h3>6.2 Kündigung</h3>
                <p>
                    Das Unternehmen kann nach eigenem Ermessen jederzeit und ohne Angabe von Gründen diese Lizenz und die Ihnen hiermit gewährten Rechte mit oder ohne vorherige Ankündigung aussetzen oder kündigen. Wenn Sie gegen die Bestimmungen und
                    Bedingungen dieser Lizenz verstoßen, werden diese Lizenz und alle Ihnen im Rahmen dieser Lizenz gewährten Rechte automatisch ohne vorherige Ankündigung oder sonstige Maßnahmen des Unternehmens beendet. Nach Beendigung dieser Lizenz
                    stellen Sie die Nutzung der Anwendung ein und deinstallieren die Anwendung. Sie können diese Vereinbarung auch kündigen, indem Sie die Anwendung und alle Kopien davon von Ihrem Mobilgerät löschen. Nach Beendigung dieser Vereinbarung
                    stellen Sie die Nutzung der Anwendung ein und löschen alle Kopien der Anwendung von Ihrem Mobilgerät. Wenn Sie das Abonnement mitten im Abrechnungszyklus kündigen, erhalten Sie für einen Zeitraum, den Sie in diesem Abrechnungszyklus
                    nicht verwendet haben, keine Rückerstattung (wir erstatten keine zuvor bezahlten Beträge). Sie sind für die Kündigung Ihres Kontos und dieser Vereinbarung verantwortlich. Wir sind weder für Ihr Versäumnis verantwortlich, Ihren Dienst
                    und diese Vereinbarung ordnungsgemäß zu kündigen, noch für Kreditkartengebühren und -gebühren, die Ihnen aufgrund der Nichtbeendigung Ihres Dienstes und dieser Vereinbarung entstehen.
                </p>

                <h2>7. Gewährleistungsausschluss</h2>
                <p>
                    SIE BESTÄTIGEN UND STIMMEN ZU, DASS DIE ANWENDUNG "WIE BESEHEN" UND "WIE VERFÜGBAR" MIT ALLEN FEHLERN UND OHNE JEGLICHE GEWÄHRLEISTUNG ZUR VERFÜGUNG GESTELLT WIRD. Ihre Nutzung oder Ihr Vertrauen in die Anwendung und die Inhalte und
                    Dienstleistungen Dritter, auf die Sie zugreifen, erfolgt auf Ihr alleiniges Risiko und Ihre Diskretion. UNTERNEHMEN UND SEINE MITARBEITER, DIREKTOREN, AKTIONÄRE, MITGLIEDER, PARTNER, LIEFERANTEN ODER LIZENZGEBER SCHLIESSEN JEGLICHE
                    JEGLICHE ZUSICHERUNGEN, GEWÄHRLEISTUNGEN UND GEWÄHRLEISTUNGEN IN BEZUG AUF DEN ANWENDUNGSBETRIEB STILLSCHWEIGENDE GEWÄHRLEISTUNGEN FÜR MARKTGÄNGIGKEIT, EIGNUNG FÜR EINEN BESTIMMTEN ZWECK, EIGENSCHAFTEN, QUALITÄT, SICHERHEIT UND
                    NICHTVERLETZUNG. Darüber hinaus übernehmen das Unternehmen und seine Mitarbeiter, Direktoren, Anteilseigner, verbundenen Unternehmen, Partner, Lieferanten oder Lizenzgeber keine Garantie dafür, dass (I) der Antrag oder die Inhalte und
                    Dienstleistungen Dritter Ihren Anforderungen entsprechen. (II) DIE ANWENDUNG ODER INHALTE UND DIENSTLEISTUNGEN VON DRITTANBIETERN SIND UNTERBROCHEN, GENAU, ZUVERLÄSSIG, PÜNKTLICH, SICHER ODER FEHLERFREI. (III) DIE QUALITÄT VON
                    PRODUKTEN, DIENSTLEISTUNGEN, INFORMATIONEN ODER ANDEREN MATERIALIEN, AUF DIE SIE ÜBER DIE ANWENDUNG ZUGREIFEN ODER ERHALTEN, WIRD DARGESTELLT ODER ERFÜLLT IHRE ERWARTUNGEN; ODER (IV) JEGLICHE FEHLER IN DER ANWENDUNG ODER INHALTEN UND
                    DIENSTLEISTUNGEN VON DRITTANBIETERN WERDEN BEHEBT. WEDER MÜNDLICHE ODER SCHRIFTLICHE HINWEISE ODER INFORMATIONEN, DIE SIE VON UNTERNEHMEN ODER AUS DER ANWENDUNG ERHALTEN, ERSTELLEN JEGLICHE ZUSICHERUNGEN, GARANTIEN ODER GARANTIEN.
                    Darüber hinaus bestätigen Sie, dass das Unternehmen nicht verpflichtet ist, Fehler oder sonstige Unterstützung oder Beibehaltung der Anwendung zu korrigieren. Das NAVIGATIONSSYSTEM BIETET KEINE VOLLSTÄNDIGE NAVIGATION IM ZUSAMMENHANG
                    MIT DER VERMEIDUNG VON FLACHEN WASSERN, KÜSTEN, INSELN ODER ANDEREN HINDERNISSEN. DAHER SOLLTE ES NICHT AUF NAVIGATION VERWENDET ODER VERWENDET WERDEN. KARTEN UND CHARTS KÖNNEN UNGENAU SEIN UND WERDEN NICHT REGELMÄSSIG AKTUALISIERT.
                    Daher sollten Sie sich nicht auf sie verlassen (nur offizielle Regierungsgebühren und Mitteilungen an Seeleute enthalten alle Informationen für eine sichere Navigation). Die Genauigkeit der App hängt vollständig von der
                    GPS-Signalqualität, Ihrem GPS-Gerät für mobile Geräte oder den Standortdiensten und dem Kompass-Chip ab. JEGLICHE STÖRUNGEN KÖNNEN FALSCHE DATEN VERURSACHEN. Es ist nicht beabsichtigt, sich auf Situationen zu verlassen, in denen ein
                    genauer Standort erforderlich ist oder in denen fehlerhafte, ungenaue oder unvollständige Daten zu Todesfällen, Personenschäden, Eigentum oder Umweltschäden führen können. STANDORT UND ANDERE DATEN KÖNNEN NICHT GENAU SEIN. Daher sollten
                    Sie sich nicht darauf verlassen. SOLLTEN DIE ANWENDUNG ODER DIENSTLEISTUNGEN FEHLERHAFT SEIN, ÜBERNEHMEN SIE DIE GESAMTEN KOSTEN FÜR ALLE ERFORDERLICHEN WARTUNGEN, REPARATUREN ODER KORREKTUREN.
                </p>

                <h2>8. Haftungsbeschränkung</h2>
                <p>
                    UNTER KEINEN UMSTÄNDEN HAFTET DAS UNTERNEHMEN ODER SEINE MITARBEITER, DIREKTOREN, AKTIONÄRE, MITGLIEDER, PARTNER, LIEFERANTEN ODER LIZENZGEBER FÜR DIREKTE, INDIREKTE, NEBEN-, FOLGESCHÄDEN VON ODER IM ZUSAMMENHANG MIT IHREM ZUGRIFF ODER
                    IHRER NUTZUNG ODER UNMÖGLICHKEIT, AUF DIE ANWENDUNG UND DIENSTLEISTUNGEN UND DIENSTLEISTUNGEN VON DRITTANBIETERN ZU ZUGRIFFEN ODER SIE ZU NUTZEN Ohne die Allgemeingültigkeit des Vorstehenden einzuschränken, übersteigt die Gesamthaftung
                    des Unternehmens Ihnen gegenüber (unabhängig davon, ob es sich um einen Vertrag, einen Vertrag, eine Satzung oder anderweitig handelt) nicht den Betrag, den Sie für den Service gezahlt haben. DIE VORANGEGANGENEN EINSCHRÄNKUNGEN GELTEN
                    AUCH, WENN DIE OBEN GENANNTE ABHILFE IHREN WESENTLICHEN ZWECK NICHT ERFOLGT.
                </p>

                <h2>9. Entschädigung</h2>
                <p>
                    Sie müssen das Unternehmen und seine verbundenen Unternehmen, Partner, Lieferanten und Lizenzgeber sowie die jeweiligen leitenden Angestellten, Direktoren, Vertreter und Mitarbeiter von jeglichen Ansprüchen, Verfahren, Verlusten,
                    Schäden, Geldstrafen, Strafen, Zinsen und Kosten freistellen, verteidigen und schadlos halten (einschließlich, ohne Einschränkung, Gebühren für Anwälte und andere professionelle Berater), die sich aus oder im Zusammenhang mit Folgendem
                    ergeben: (i) Ihr Zugang zu oder Ihre Nutzung der Anwendung oder der Inhalte und Dienste Dritter; (ii) Ihre Verletzung dieser Lizenz; (iii) Ihre Rechtsverletzung; (iv) Ihre Fahrlässigkeit oder Ihr vorsätzliches Fehlverhalten; oder (v)
                    Ihre Verletzung der Rechte Dritter, einschließlich der Verletzung von geistigem Eigentum oder der missbräuchlichen Verwendung von Eigentumsrechten oder Geschäftsgeheimnissen einer Person oder Organisation durch Sie. Diese
                    Verpflichtungen bleiben auch nach Beendigung der Lizenz bestehen.
                </p>

                <h2>10. Kompatibilität</h2>
                <p>
                    Das Unternehmen garantiert nicht, dass die Anwendung mit Ihrem mobilen Gerät oder einer anderen Hardware, Software, Ausrüstung oder einem anderen Gerät kompatibel oder interoperabel ist, die auf Ihrem mobilen Gerät installiert sind oder
                    in Verbindung mit diesem verwendet werden. Darüber hinaus erkennen Sie an, dass Kompatibilitäts- und Interoperabilitätsprobleme dazu führen können, dass die Leistung Ihres Mobilgeräts abnimmt oder vollständig ausfällt. Dies kann zu
                    dauerhaften Schäden an Ihrem Mobilgerät, zum Verlust der auf Ihrem Mobilgerät befindlichen Daten und zur Beschädigung der Software führen und Dateien auf Ihrem Mobilgerät. Sie erkennen an und stimmen zu, dass das Unternehmen und seine
                    Mitarbeiter, Direktoren, Aktionäre, verbundenen Unternehmen, Partner, Lieferanten oder Lizenzgeber und Lizenzgeber Ihnen gegenüber keine Haftung für Verluste übernehmen, die aus oder im Zusammenhang mit Kompatibilitäts- oder
                    Interoperabilitätsproblemen entstehen.
                </p>

                <h2>11. Produktansprüche</h2>
                <p>
                    Sie erkennen an, dass Sie (nicht das Unternehmen) für die Bearbeitung von Ansprüchen Dritter im Zusammenhang mit Ihrer Nutzung oder Ihrem Besitz der Anwendung verantwortlich sind, und erklären sich damit einverstanden, das Unternehmen
                    über Ansprüche Dritter im Zusammenhang mit der Anwendung zu informieren, von denen Sie Kenntnis erhalten. Darüber hinaus entbinden Sie das Unternehmen hiermit von jeglicher Haftung, die sich aus Ihrer Nutzung oder Ihrem Besitz der
                    Anwendung ergibt, einschließlich, ohne Einschränkung, der folgenden: (i) jeglicher Produkthaftungsansprüche; (ii) jede Behauptung, dass die Anwendung nicht den geltenden gesetzlichen oder behördlichen Anforderungen entspricht; und (iii)
                    Ansprüche aus dem Verbraucherschutz oder ähnlichen Rechtsvorschriften.
                </p>

                <h2>12. Verschiedenes</h2>
                <h3>12.1 Salvatorische Klausel</h3>
                <p>
                    Wenn eine Bestimmung dieser Vereinbarung in Bezug auf eine Partei als ungültig oder nicht durchsetzbar angesehen wird, bleiben der Rest dieser Lizenz oder die Anwendung dieser Bestimmung auf andere Personen als diejenigen, für die sie
                    ungültig oder nicht durchsetzbar ist, unberührt Die verbleibende Bestimmung dieser Lizenz ist gültig und durchsetzbar, soweit dies gesetzlich zulässig ist. Diese Bestimmung wird geändert und ausgelegt, um die Ziele dieser Bestimmung
                    nach geltendem Recht so weit wie möglich zu erreichen.
                </p>

                <h3>12.2 Änderung oder Ergänzung</h3>
                <p>
                    Das Unternehmen kann die Bestimmungen dieser Vereinbarung jederzeit nach eigenem Ermessen ohne vorherige Ankündigung ändern oder ergänzen, indem es eine Kopie der geänderten oder geänderten Vereinbarung auf der Website des Unternehmens
                    „https://fishyapp.com“ veröffentlicht. Es wird davon ausgegangen, dass Sie einer solchen Änderung oder Ergänzung durch Ihre Entscheidung, die Anwendung nach dem Datum, an dem die geänderte oder geänderte Vereinbarung auf der Website des
                    Unternehmens veröffentlicht wird, weiter zu verwenden, zugestimmt haben. Sie sind dafür verantwortlich, die Allgemeinen Geschäftsbedingungen regelmäßig zu überprüfen.
                </p>

                <h3>12.3 Mobilfunkgebühren</h3>
                <p>
                    Die für die Nutzung der Anwendung erforderliche Internetverbindung und die damit verbundenen Gebühren (z. B. Kosten für mobile Daten), die durch die Nutzung der Anwendung entstehen, liegen in Ihrer ausschließlichen Verantwortung und
                    werden ausschließlich auf eigene Kosten hergestellt.
                </p>

                <h3>12.4 Anzeigen</h3>
                <p>
                    In der kostenlosen Version der Anwendung werden möglicherweise Werbeinhalte von verschiedenen Anzeigenanbietern angezeigt. Der Anzeigeninhalt basiert möglicherweise auf den vom Anzeigenservice gesammelten Informationen. Anzeigen können
                    in der kostenlosen Version der Anwendung nicht entfernt werden.
                </p>

                <h3>12.5 Verbesserung der Anwendung</h3>
                <p>
                    Wir ändern und verbessern ständig unsere App. Wir können die Anwendung oder jeden Dienst, mit dem sie verbunden ist, jederzeit ohne vorherige Ankündigung und ohne Haftung Ihnen gegenüber hinzufügen, entfernen, ändern, ändern, begrenzen,
                    deaktivieren, aussetzen oder (vorübergehend oder dauerhaft) einstellen. Sie erkennen an, dass Apple / Google keinerlei Verpflichtung hat, Wartungs- und Supportdienste in Bezug auf die Anwendung bereitzustellen. Sie erkennen auch an,
                    dass das Unternehmen nicht ausdrücklich oder stillschweigend verpflichtet ist, Aktualisierungen der Anwendung in Zukunft bekannt zu geben oder anderen zur Verfügung zu stellen.
                </p>

                <h3>12.6 Google Play-Dienste</h3>
                <p>Die Anwendung verwendet möglicherweise Google Play Services, um ordnungsgemäß zu funktionieren. Dazu gehören Google Maps-Dienste, Google Analytics, Google Firebase, Google Admob, Google In-App-Abrechnung usw.</p>

                <h3>12.7 Apple-Dienste (nur iOS-Geräte)</h3>
                <p>Die iOS-Anwendung verwendet möglicherweise Apple Services, um ordnungsgemäß zu funktionieren. Dazu gehören der iCloud-Dienst und der Apple In-App-Kaufdienst.</p>

                <h3>12.8 Abonnementbedingungen</h3>
                <p>
                    Der Download und die Nutzung der Fishy App sind kostenlos. Das Upgrade auf die Premium-Version ist auf jährlicher oder / und monatlicher Abonnementbasis verfügbar (Preis kann zwischen den Standorten variieren). Wenn Sie sich für ein
                    Upgrade auf die Premium-Version entscheiden, wird die Zahlung bei Bestätigung des Kaufs Ihrem Google- oder iTunes-Konto belastet. Ihr Abonnement verlängert sich automatisch, es sei denn, die automatische Verlängerung wird mindestens 24
                    Stunden vor dem Ende des aktuellen Zeitraums deaktiviert. Ihrem Konto wird die Verlängerung innerhalb von 24 Stunden vor dem Ende des aktuellen Zeitraums in Rechnung gestellt, und Sie müssen die Kosten für die Verlängerung ermitteln.
                    Abonnements können verwaltet und die automatische Verlängerung in Ihren Kontoeinstellungen in iTunes (iOS-Geräte) oder Play Store (Android-Geräte) nach dem Kauf deaktiviert werden. Während des aktiven Abonnementzeitraums ist keine
                    Kündigung des aktuellen Abonnements zulässig.
                </p>

                <h3>12.9 Akkulaufzeit</h3>
                <p>Die fortgesetzte Verwendung von Ortungsdiensten im Hintergrund kann die Akkulaufzeit erheblich verkürzen.</p>

                <h3>12.10 Geltendes Recht und Streitbeilegung</h3>
                <p>
                    Diese Bedingungen und die Nutzung der Dienste unterliegen den Gesetzen Sloweniens. Alle Ansprüche aus oder im Zusammenhang mit diesen Bedingungen oder dem Dienst werden von den slowenischen öffentlichen Gerichten geklärt, wobei das
                    Bezirksgericht von Ljubljana das erstinstanzliche Gericht ist.
                </p>

                <h3>12.11 Kontaktinformationen</h3>
                <p>Wenn Sie Fragen zu diesen Allgemeinen Geschäftsbedingungen haben, können Sie uns jederzeit unter unserer E-Mail-Adresse info@fishyapp.com kontaktieren.</p>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {};
</script>